// components/Layout.js
import {
  Button,
  Flex,
  Heading,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { MenaComp } from './MenaComp';
import MenaData, { MenaDoc, MenaList } from './MenaData';
import Styla from './Styla';
import ThemeToggle from './ThemeToggle';

export function HeadaComp() {
  const MenuData = new MenaData();

  const [menaDoc, setMenaDoc] = useState<MenaDoc>(MenuData.getMenaDoc());

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <Styla>
      <Flex gap="0.2rem">
        <MenaComp menaList={MenaList.mainIds} />
      </Flex>
      {/* <Flex>  
        {route !== 'authenticated' ? (
          <Button variation="link" onClick={() => navigate('/login')}>
            Login
          </Button>
        ) : (
          <Button variation="link" onClick={() => logOut()}>
            Logout
          </Button>
        )}
        <ThemeToggle/>
      </Flex>
      <View>
        {route === 'authenticated' ? 'You are logged in!' : 'Please Login!'}
      </View> */}

      <Outlet />
    </Styla>
  );
}
