import React from 'react';

import { GenerateCharta } from '../../../services/charta/guts/interfaces/generate-chart';
import Chartauta from '../creation/Chartauta';

export default function ChartSurface() {

  const genCharta: GenerateCharta  = {
    chrtType: 'SURFACE',
    generateParams: {
      volatility: 0.002,
      oldPrice: 1,
      count: 1,
      originalPrice: 1,
      intervalTime: 1350000000,
    }
  }

  return (
    <Chartauta genCharta={genCharta}/>
  )
}
