/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://wtt5djtcm5bvxhf3vwd72x5wf4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7ajd6qm7hze6ppvlwc5jw3wxpy",
    "aws_cognito_identity_pool_id": "eu-west-2:58083416-d119-456f-99aa-bdc40cf08bb1",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_iEgFc2dOG",
    "aws_user_pools_web_client_id": "7unbs39dn9fficpjgunelhna7s",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "resho-storage-4c27041f185356-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "468f73db2cff47799494366c126fd8e2",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "468f73db2cff47799494366c126fd8e2",
            "region": "eu-west-2"
        }
    },
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "maps": {
                "items": {
                    "map028a1a34-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map028a1a34-staging"
            }
        }
    }
};


export default awsmobile;
