import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
// import NavBar from '../components/NavBar';
import { Card, Heading } from '@aws-amplify/ui-react';

const ErrorPage = () => {
  const error = useRouteError();

  return (
    <>
      {/* <NavBar /> */}
      <Card>
        <Heading>Oops</Heading>
        <div>
          {isRouteErrorResponse(error)
            ? 'This page does not exist.'
            : 'An unexpected error occurred.'}
        </div>
      </Card>
    </>
  );
};

export default ErrorPage;
