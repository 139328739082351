import { Canvas } from '@react-three/fiber'
import Polyhedron from '../widgets/Polyhedron'
import * as THREE from 'three'
import { useMemo, useRef, useState } from 'react'
import { Stats, OrbitControls } from '@react-three/drei'
import { useControls } from 'leva'

export default function ConfigPolyhedron() {
  const polyhedron = useMemo(
    () => [
      new THREE.BoxGeometry(),
      new THREE.SphereGeometry(0.785398),
      new THREE.DodecahedronGeometry(0.785398)
    ],
    []
  )

  const options = useMemo(() => {
    return {
      x: { value: 0, min: 0, max: Math.PI * 2, step: 0.01 },
      y: { value: 0, min: 0, max: Math.PI * 2, step: 0.01 },
      z: { value: 0, min: 0, max: Math.PI * 2, step: 0.01 },
      visible: true,
      opacity: { value: 0, min: 0, max: 1, step: 0.01 },
      color: { value: 'lime' }
    }
  }, [])

    const pA = useControls('Polyhedron A', options)
    const pB = useControls('Polyhedron B', options)


  return (
    <Canvas camera={{ position: [1, 2, 3] }}>
      <Polyhedron
      // @ts-ignore

        position={[-1, 1, 0]}
        rotation={[pA.x, pA.y, pA.z]}
        visible={pA.visible}
        color={pA.color}
        opacity={pA.opacity}
        polyhedron={polyhedron}
      />
      <Polyhedron
      // @ts-ignore

        position={[1, 1, 0]}
        rotation={[pB.x, pB.y, pB.z]}
        visible={pB.visible}
        color={pB.color}
        opacity={pB.opacity}
        polyhedron={polyhedron}
      />
      <OrbitControls target-y={1} />
      <axesHelper args={[5]} />
      <gridHelper />
      <Stats />
    </Canvas>
  )
}
