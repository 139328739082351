import React from 'react';

import { Ggltfa, XyzTuple } from '../../../interfaces/DbGltfa';
import { LazyGbox, LazyGtext3D } from '../../../models';
import { GboxDisplay, Gtext3DDisplay, GgltfDisplay } from '../widgets/TransformCtrla4';

interface Props {
  gboxs: LazyGbox[] | undefined;
  gtext3Ds: LazyGtext3D[] | undefined;
  gltfas: Ggltfa[];
}

export default function ThreeGutsDisplay({ gboxs, gtext3Ds, gltfas }: Props) {

  return (
    <>
      {gboxs?.map((gbox, iGbox) => (
        <React.Fragment key={iGbox}>
          <GboxDisplay
            gname={gbox.gname}
            // position={gbox.position as XyzTuple}
            // rotation={gbox.rotation as XyzTuple}
            // scale={gbox.scale as XyzTuple}
            // color={gbox.color || 'white'}
          />
        </React.Fragment>
      ))}
      {gtext3Ds?.map((text3D, iText3D) => (
        <React.Fragment key={iText3D}>
          <Gtext3DDisplay
            gname={text3D.gname}
            // text={text3D.text || '???'}
            // position={text3D.position as XyzTuple}
            // rotation={text3D.rotation as XyzTuple}
            // scale={text3D.scale as XyzTuple}
            // color={text3D?.color || 'hotpink'}
          />
        </React.Fragment>
      ))}
      {gltfas?.map((gltfa, iGltfa) => (
        <React.Fragment key={iGltfa}>
          {gltfa.nodes &&
            Object.keys(gltfa.nodes).map((nodeId, iNodeId) => (
              <GgltfDisplay
                key={iNodeId}
                gltfId={gltfa.id}
                name={nodeId}
                position={gltfa.nodes[nodeId].position}
                rotation={gltfa.nodes[nodeId].rotation}
                scale={gltfa.nodes[nodeId].scale}
                fileName={gltfa.fileName}
              />
            ))}
        </React.Fragment>
      ))}
      {/* <TextWithColorChanger /> */}
    </>
  );
}
