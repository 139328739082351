import {
  Card,
  Text,
  Grid,
  Heading,
  ThemeProvider,
  Theme,
  useTheme,
  View,
  Flex,
  Image,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { DataStore, SortDirection, Storage } from 'aws-amplify';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';

// import ItemCardCollection from '../ui-components/ItemCardCollection';
// import SocialPage from '../ui-components/SocialPage';
import { LazyComment, LazyPage, Page } from '../../models';
import {
  CommentCreateForm,
  PageCreateForm,
  PageListItemCollection,
  PageUpdateForm,
} from '../../ui-components';
import { PageUpdateFormInputValues } from '../../ui-components/PageUpdateForm';

interface s3Image {
  image_key: string;
  image_url: string;
}

interface s3Images {
  [id: string]: s3Image;
}

// interface s3Images {
//   s3Image[]
// }

const NoteDetailPageE = () => {
  const { tokens } = useTheme();

  const [selected, setSelected] = useState<Page>();
  const [fields, setFields] = useState<any>();
  // const [selectedPrev, setSelectedPrev] = useState<LazyPage>();
  // const [selected, setSelected] = useState<Page>({} as Page);
  // const [comments, setComments] = useState<LazyComment[]>(); // * required as selected.comments
  // const [comments, setComments] = useState<Comment[]>(); // * required as selected.comments
  // * is not correctly mark as an array e.g. comments[].
  const [imageKeys, setImageKeys] = useState<S3ProviderListOutputItem[]>([]);
  // const [images, setImages] = useState<string[]>([]);
  const [images, setImages] = useState<s3Image[]>();
  const [pages, setPages] = useState<LazyPage[]>();
  const [items, setItems] = React.useState(undefined);
  const [other, setOther] = useState<any>(undefined);
  const [formData, setFormData] = useState<PageUpdateFormInputValues>();

  useEffect(() => {
    const subscription = DataStore.observeQuery(
      Page,
      (p) =>
        p.and((p) => {
          return [
            // p.title.beginsWith('title'),
            // p.rating.gt(10)
          ];
        }),
      {
        sort: (s) => s.title(SortDirection.DESCENDING),
      }
    ).subscribe(async (data) => {
      const { items, isSynced } = data;

;
      const items2 = items;

      console.log('items2', items2);

      // setPages(data.items);
      setPages(items2);

      console.log(
        `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
      );
      console.log(`[Snapshot] Pages:`, data);
      console.log(`[Snapshot] Pages:`, data.items);
    });

    return () => subscription.unsubscribe();
  }, []);

  console.log(selected);
  // console.log(comments);

  const fetchImages = async () => {
    const { results } = await Storage.list('', {
      pageSize: 50,
      level: 'private',
    });
    console.log('await Storage.list( | results', results);

    setImageKeys(results);
    const s3Images = await Promise.all(
      results.map(async (image) => {
        return {
          image_key: image.key!,
          image_url: await Storage.get(image.key!, { level: 'private' }),
        };
      })
    );
    setImages(s3Images);
    console.log(
      s3Images.filter((image) => image.image_key === '')[0]?.image_key
    );
    console.log('s3Images', s3Images);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      <Heading level={3}>
        Advance styling, interactiving and Data analysis
      </Heading>
      <Grid
        columnGap="0.5rem"
        rowGap="0.5rem"
        // templateAreas={{base: 'aaa bbb ccc'}}
        // templateColumns="2fr 5fr 3fr"
        // templateRows="1fr"
        templateColumns={{
          base: '1fr',
          medium: '[start] 2fr [mainStart] 5fr',
          large: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xxl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
        }}
        // templateRows={{ base: 'repeat(4, 10rem)', large: 'repeat(3, 10rem)' }}
        gap={tokens.space.small}
      >
        <View
          columnSpan={{
            base: 'start/mainStart',
            medium: 'start/mainStart',
            large: 'start/mainStart',
          }}
        >
          {images === undefined || images[0] === undefined ? (
            ''
          ) : (
            <PageListItemCollection
              items={pages}
              overrides={{
                PageListItemCollection: {
                  // gap: '0',
                  gap: '0px',
                },
              }}
              overrideItems={({ item, index }) => ({
                // margin: '1rem',
                backgroundColor:
                  index % 2 === 0 ? 'brand.primary.10' : 'brand.primary.20',
                color: 'brand.primary.100',

                overrides: {
                  image: {
                    src: images.filter(
                      (image) => image.image_key === item?.image_url
                    )[0]?.image_url,
                  },
                },

                // onClick: () => {
                onMouseOver: () => {
                  const aaa = Page.copyOf(item, (updated) => {
                    updated.image_url =
                      item.image_url === null ? '' : item.image_url;
                  });

                  // const aaa = {...item, image_url: (item.image_url === null) ? '' : item.image_url};
                  setSelected(aaa);
                },
              })}
            />
          )}
        </View>

        {/* <hr /> */}
        {selected && images ? (
          <>
            {/* <CommentCreateForm /> */}

            <Card>
              <div>image: {selected.image_url}</div>
              <Image
                alt="Amplify logo"
                // src="/amplify-logo.svg"
                src={
                  images.filter(
                    (image) => image.image_key === selected?.image_url
                  )[0]?.image_url
                }
                // objectFit="initial"
                objectFit="cover"
                objectPosition="50% 50%"
                backgroundColor="initial"
                // height="35%"
                maxHeight="30em"
                // height="55%"
                // width="55%"
                opacity="100%"
                onClick={() => alert('📸 Say cheese!')}
              />

              <PageUpdateForm
                // id={selected.id} // @ form does refresh with new values, but only way to save
                page={selected as any} // @ can't update DB with this but it updates the form
                // page={new Page({...selected})}
                onError={(error) => {
                  console.log('onError={(error)', error);
                }}
                onChange={(fields) => {
;
                  setFormData(fields);
                  return fields;
                }}
              />
            </Card>
            <PageCreateForm />
          </>
        ) : (
          <div>No Record</div>
        )}
      </Grid>
    </>
  );
};

export default NoteDetailPageE;
