import { Card, View } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';

import { GenerateCharta } from '../../../services/charta/guts/interfaces/generate-chart';
import { ClassTickingStock } from '../../../services/charta/guts/stock/class-ticking-stock';
import { StockPriceGenerateService } from '../../../services/charta/guts/stock/stock-price-generate.service';
import Chartplota from './Chartplota';
import { VariablesService } from '../../../services/charta/guts/general/variables.service';
import { ChartaDataService } from '../../../services/charta/guts/mock/charta-data.service';
import { PlotlyaService } from '../../../services/charta/guts/plotlya/plotlya.service';
import { Plotlya } from '../../../services/charta/guts/interfaces/plotlya.model';
import DimensionGet from '../../examples/DimensionGet';
import { ChartResize } from './ChartResize';

interface Props {
  genCharta: GenerateCharta;
}

export default function Chartauta({ genCharta }: Props) {
  // const [chartData, setChartData] = useState<string[][]>();
  const [plotlya, setPlotlya] = useState<Plotlya.Inputs>();

  useEffect(() => {
    function surfaceChart(): void {
      const SVariables = new VariablesService();
      const SChartaData = new ChartaDataService();
      const SPlotlya = new PlotlyaService(SVariables, SChartaData);
      setPlotlya(SPlotlya.surfaceChart());
    }

    function redrawChart(row: string[][], interval: number) {
      const timeLast = Date.parse(row[row.length - 1][0]);
      const timeSecondLast = Date.parse(row[row.length - 2][0]);
      const diff = Number(timeLast) - Number(timeSecondLast);
      return diff > interval * 2 ? true : false; // * i.e. the ticking has been paused (e.g. the computer went into sleep mode).
    }

    function timeSeries(genCharta: GenerateCharta) {
      //& console.log(
      //&   '%c Chartauta |  function timeSeries(genCharta: GenerateCharta)',
      //&   'background-color: #447700; color: white',
      //&   genCharta
      //& );

      const { TickingStock2, tickerParams } = createTick(genCharta);

      const SVariables = new VariablesService();
      const SChartaData = new ChartaDataService();
      const SPlotlya = new PlotlyaService(SVariables, SChartaData);

      const subscribed = TickingStock2.getTickingStock2(tickerParams).subscribe(
        (row: string[][]) => {
          if (redrawChart(row, tickerParams.intervalTime)) {
            //&   console.log(
            //&     '%c Chartauta |  timeSeries() | subscribe((row: string[][]) | if (this.redrawChart(row, figuresRequired.intervalTime)) ',
            //&     'background-color: #447799; color: white',
            //&     'page: RELOAD'
            //&   );
            window.location.reload();
          }

          //& console.log(
          //&   '%c Chartauta |  timeSeries() | subscribe((row: string[][]) | row: ',
          //&   'background-color: #447700; color: white',
          //&   row
          //& );

          TickingStock2.lastHigh = Number(row[row.length - 1][2]);
          // setChartData(row);
          SPlotlya.tickerParams = tickerParams;
          const plotlya = SPlotlya.timeSeries(row);

          plotlya.layout.height = 400; // * stops chart bouncing, it is then saved over in <Charplota/>
          // plotlya.layout.width = 800;
          //& console.log(
          //&   '%c Chartauta |  timeSeries() | subscribe((row: string[][]) | plotlya: ',
          //&   'background-color: #449944; color: white',
          //&   plotlya
          //& );

          setPlotlya(plotlya);
        }
      );

      return () => subscribed.unsubscribe();
    }

    //& console.log(
    //&   '%c Chartauta |  useEffect(() | row ',
    //&   'background-color: #447700; color: white'
    //& );
    switch (genCharta.chrtType) {
      case 'Series30days':
      case 'Series30seconds':
        return timeSeries(genCharta); // * must return to instigate
      case 'SURFACE':
        return surfaceChart();
      default:
        break;
    }
  }, [genCharta]);

  return (
    <>
      {plotlya && plotlya.data ? (
        <>
          {/* <DimensionGet /> */}
          <Chartplota plotlya={plotlya} />
        </>
      ) : (
        ''
      )}
    </>
  );
}

function createTick(genCharta: GenerateCharta) {
  const _stockPrice = new StockPriceGenerateService();

  const TickingStock2 = new ClassTickingStock(
    _stockPrice,
    genCharta.generateParams.intervalTime
  );

  return { TickingStock2, tickerParams: genCharta.generateParams };
}
