import { Button, Card, Flex, Heading, Link, View } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import MapPage from '../../pages/others/MapPage';
import { Geoa, GeoaDoc } from '../../interfaces/Geoa';
import MapWithMarkerPopup from '../../pages/others/MapPageB';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleUp } from 'react-icons/fa';

function Landmarks() {
  const [geoa, setGeoa] = useState<Geoa>({
    title: 'Piccadilly Circus',
    latitude: 51.511732,
    longitude: -0.12327,
  } as Geoa);

  const navigate = useNavigate();

  function updateLoction(): void {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Heading level={2} id="#Engaging">
        Engaging
      </Heading>
      <Flex
        // direction="row"
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        // position={'relative'}
      >
        <MapWithMarkerPopup geoa={geoa} />
        <View
          width={'220px'}
          direction="column"
          position={'fixed'}
          // position={'absolute'}
          top={0}
          // right={5}
          // backdropFilter={'blur(10px)'}
          // filter={'blur(1.5rem)'}
          // backdrop-filter: blur(5px)
          // style={{ backdrop-filter: 'blur(5px)'}}
          className={'blurBackground'}
          // style={{right: 0}}
          // backdropFilter="blur(10px)"
          // style={{
          //   cursor: "pointer",
          //   backdrop-filter: blur(5px),
          //   alignItems: "center",
          //   marginRight: 3,
          //   marginTop: 3,
          //   backgroundColor:
          //     index === selectedBadgeIndex ? "#B8CEF9" : "",
          // }}
        >
          <View padding={10}>
            <View
              variation="primary"
              key={'mapNav'}
              onClick={() => updateLoction()}
              fontSize={30}
              textAlign={'center'}
              lineHeight={'23px'}
            >
              <FaArrowCircleUp />
            </View>
            {/* <View
              // onClick={() => setGeoa(markerDoc.markers[id])}
              padding="0.5em 1em"
              // margin={'10px 10px'}
              backgroundColor={'white'}
              // borderRadius={'0.5rem'}
              // borderStyle={'dashed'}
              // borderColor={'black'}
              // borderWidth={'medium'}
              borderRadius="0.2rem"
              // borderStyle="dashed"
              // borderColor="red"
              // borderWidth="medium"
              border="1px solid rgba(0,0,0,0.1)"
            >
              TOP
            </View> */}
          </View>

          {markerDoc.ids.map((id) => (
            <View key={id} padding={10}>
              {/* <View
                onClick={() => setGeoa(markerDoc.markers[id])}
                padding="0.5em 1em"
                // margin={'10px 10px'}
                backgroundColor={'white'}
                // backdropFilter={'blur(10.2px)'}
                // borderRadius={'0.5rem'}
                // borderStyle={'dashed'}
                // borderColor={'black'}
                // borderWidth={'medium'}
                borderRadius="0.2rem"
                // borderStyle="dashed"
                // borderColor="red"
                // borderWidth="medium"
                border="1px solid rgba(0,0,0,0.1)"
              > */}
              {/* <Heading level={6} fontWeight={'400'}>
                  {markerDoc.markers[id]?.title}
                </Heading> */}
              <Button
                // size="small"
                backgroundColor={'rgba(255, 255, 255, 0.91)'}
                minWidth={'12em'}


                onClick={() => setGeoa(markerDoc.markers[id])}
              >
                {markerDoc.markers[id]?.title}
              </Button>
              {/* </View> */}
            </View>
          ))}
        </View>
        {/* <MapPage geoa={geoa} /> */}
      </Flex>
    </>
  );
}

const markerDoc: GeoaDoc = {
  ids: ['piccadilly', 'towerLondon', 'coventGarden', 'londonBridge'],
  markers: {
    piccadilly: {
      // title: 'Piccadilly Circus',
      title: 'Canary Wharf',
      latitude: 51.511732,
      longitude: -0.12327,
    },
    towerLondon: {
      // title: 'Tower of London',
      title: 'Bank of England',
      latitude: 52.511732,
      longitude: -0.22327,
    },
    coventGarden: {
      // title: 'Covent Garden',
      title: 'Bloomberg',
      latitude: 51.511732,
      longitude: -0.1337,
    },
    londonBridge: {
      // title: 'Londo Bridge',
      title: 'The Shard',
      // latitude: 51.521732,
      latitude: 51.5044,
      longitude: 0.0865,
    },
  },
} as GeoaDoc;

export default Landmarks;
