
export interface Mena {
    route: string;
    desc: string;
}

export interface MenaDoc {
    ids: string[];
    mainIds: string[];
    notUsedIds: string[];
    demoIds: string[];
    examplaIds: string[];
    exampla2Ids: string[];
    exampla3Ids: string[];
    menas: {
        [id: string]: Mena;
    };
}

export enum MenaList {
    mainIds = 'mainIds',
    notUsedIds = 'notUsedIds',
    demoIds = 'demoIds',
    examplaIds = 'examplaIds',
    exampla2Ids = 'exampla2Ids',
    exampla3Ids = 'exampla3Ids',
    ids = 'ids',
}

export default class MenaData {

    private menaDoc: MenaDoc = {
        // ids: [
        //     '/',
        //     '/protected',
        //     '/protected2',
        //     '/note-c',
        //     '/note-d',
        //     '/note-e',
        //     '/note-d',
        //     '/chart-a',
        //     '/chart-display',
        //     '/chart-ticking',
        //     '/map',
        //     '/intersecting',
        //     '/intersect2',
        //     '/intersect3',
        // ],
        ids: [],
        // mainIds: ['/editor/office',  '/scrollControl',  '/movingText', '/editor/enviro', '/itemConfigure', '/analysis', '/rotating-display', '/scrollAnimate', '/notUsed',],
        // mainIds: ['/editor/office',  '/scrollControl',  '/movingText', '/editor/enviro', '/itemConfigure', '/analysis', '/rotating-display',],
        // mainIds: ['/editor/office', '/editor/enviro', '/itemConfigure', '/movingText', '/analysis',],
        // mainIds: ['/editor/office', '/editor/enviro', '/itemConfigure', '/movingText', '/analysis',],
        mainIds: ['/editor/office', '/editor/enviro', '/transformCtrla2', '/itemConfigure', '/movingText', '/analysis', '/surfaceText', '/demos', '/style', ],
        demoIds: [],
        notUsedIds: ['/', '/integration', '/transformCtrla2', '/threeEdit', '/style', '/rabbitText', '/demos', '/exampla', '/exampla2', '/exampla3', '/protected',],
        examplaIds: ['/', '/intersect3', '/threeGrid', '/configPolyhedron', '/transformCtrla3', '/transformTwo', '/transformThree', '/privotContrl',],
        exampla2Ids: ['/', '/imageAnalysa',],
        exampla3Ids: ['/', '/treeExample', '/sceneMenu'],
        menas: {
            '/': { route: '/', desc: 'Home' },
            '/analysis': { route: '/analysis', desc: 'Applications' },
            '/integration': { route: '/integration', desc: 'Integration' },
            '/style': { route: '/style', desc: 'Style' },
            '/protected': { route: '/protected', desc: 'protected' },
            '/protected2': { route: '/protected2', desc: 'protected2' },
            // {route: '/protected/note', desc: 'P note'},
            // {route: '/protected/note-b', desc: 'P note-b'},
            // {route: '/note', desc: 'note'},
            // {route: '/note-b', desc: 'note-b'},
            '/note-c': { route: '/note-c', desc: 'note-c' },
            '/note-d': { route: '/note-d', desc: 'note-d' },
            '/note-e': { route: '/note-e', desc: 'note-e' },
            '/note-f': { route: '/note-f', desc: 'note-f' },
            '/notUsed': { route: '/notUsed', desc: 'notUsed' },
            '/demos': { route: '/demos', desc: 'Demos' },
            '/exampla': { route: '/exampla', desc: 'exampla' },
            '/exampla2': { route: '/exampla2', desc: 'exampla2' },
            '/exampla3': { route: '/exampla3', desc: 'exampla3' },
            '/treeExample': { route: '/treeExample', desc: 'treeExample' },
            '/sceneMenu': { route: '/sceneMenu', desc: 'sceneMenu' },
            '/threeGrid': { route: '/threeGrid', desc: 'threeGrid' },
            '/privotContrl': { route: '/privotContrl', desc: 'privotContrl' },
            '/configPolyhedron': { route: '/configPolyhedron', desc: 'configPolyhedron' },
            '/rotating-display': { route: '/rotating-display', desc: 'Fancy Presentation' },
            '/transformCtrla2': { route: '/transformCtrla2', desc: 'Controls' },
            '/transformCtrla3': { route: '/transformCtrla3', desc: 'transformCtrla3' },
            '/transformTwo': { route: '/transformTwo', desc: 'transformTwo' },
            '/transformThree': { route: '/transformThree', desc: 'transformThree' },
            '/imageAnalysa': { route: '/imageAnalysa', desc: 'imageAnalysa' },
            '/imageDbDisplay': { route: '/imageDbDisplay', desc: 'imageDbDisplay' },
            '/threeEdit': { route: '/threeEdit', desc: 'threeEdit' },
            '/editor/enviro': { route: '/editor/enviro', desc: 'Research' },
            '/editor/office': { route: '/editor/office', desc: 'Home' },
            '/scrollAnimate': { route: '/scrollAnimate', desc: 'Interactive Product' },
            '/itemConfigure': { route: '/itemConfigure', desc: 'Configure Shop' },
            '/scrollControl': { route: '/scrollControl', desc: 'Style' },
            '/movingText': { route: '/movingText', desc: 'Fidget Text' },
            // '/rabbitText': { route: '/rabbitText', desc: 'Surface Text' },
            '/surfaceText': { route: '/surfaceText', desc: 'Surface Text' },
            '/chart-a': { route: '/chart-a', desc: 'chart-a' },
            '/chart-b': { route: '/chart-b', desc: 'chart-b' },
            '/chart-display': { route: '/chart-display', desc: 'chart-display' },
            // {route: '/chartauta', desc: 'chartauta'},
            '/chart-ticking': { route: '/chart-ticking', desc: 'chart-ticking' },
            // {route: '/game', desc: 'game'},
            '/map': { route: '/map', desc: 'map' },
            '/intersecting': { route: '/intersecting', desc: 'intersecting' },
            '/intersect2': { route: '/intersect2', desc: 'intersect2' },
            '/intersect3': { route: '/intersect3', desc: 'intersect3' },
            '/intersect4': { route: '/intersect4', desc: 'intersect4' },
            // {route: '/post-add', desc: 'post-add'},
            // {route: '/post-update', desc: 'post-update'},
            // {route: '/post-observe', desc: 'post-observe'},
            // {route: '/images', desc: 'images'},
        },
    }

    public getMenaDoc() {

        this.menaDoc.ids = Object.keys(this.menaDoc.menas);
        return this.menaDoc;
    }

}
