import { createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/main/HomePage';

import { Protected } from './components/Protected';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { ProtectedSecond } from './components/ProtectSecond';
import ErrorPage from './pages/always/ErrorPage';
import NoteDetailPageC from './pages/others/NoteDetailPageC';
import NoteDetailPageD from './pages/others/NoteDetailPageD';
import NoteDetailPageE from './pages/others/NoteDetailPageE';
import ChartPageA from './pages/others/ChartPageA';
import ChartPageB from './pages/others/ChartPageB';
import ChartTicking from './components/charts/premade/ChartTicking';
import Intersecting from './pages/others/Intersecting';
import Intersect2 from './pages/others/intersect2';
import Intersect3 from './pages/others/intersect3';
import Intersect4 from './pages/others/intersect4';
import AnalysisPage from './pages/main/AnalysisPage';
import IntegrationPage from './pages/main/IntegrationPage';
import StylePage from './pages/main/StylePage';
import { HeadaComp } from './components/HeadaComp';
import NoteDetailPageF from './pages/others/NoteDetailPageF';
import { Exampla } from './components/three/examples/exampla';
import ThreeGrid from './components/three/examples/ThreeGrid';
import RotatingDisplay from './components/three/examples/RotatingDisplay';
import TransformCtrla2 from './components/three/examples/TransformCtrla2';
import TransformTwo from './components/three/examples/TransformTwo';
import ImageAnalysa from './components/three/widgets/ImageAnalysa';
import TransformThree from './components/three/examples/TransformThree';
import ThreeEdit from './components/three/display/ThreeEdit';
import ScrollAnimate from './components/three/examples/ScrollAnimate';
import ItemConfigure from './components/three/examples/ItemConfigure';
import ScrollControl from './components/three/examples/ScrollControl';
import { Exampla2 } from './components/three/examples/Exampla2';
import { MovingText } from './components/three/examples/MovingText';
import { RabbitText } from './components/three/examples/RabbitText';
import ConfigPolyhedron from './components/three/examples/ConfigPolyhedron';
import { Exampla3 } from './components/three/examples/Exampla3';
import { TreeExample } from './components/tree/TreeExample';
import { SceneMenu } from './components/three/data/SceneMenu';
import { NotUsed } from './components/three/examples/NotUsed';
import { Demos } from './components/three/examples/Demos';
import PrivotContrl from './components/three/PrivotContrl';


const router = createBrowserRouter([
  {
    path: '/',
    element: <HeadaComp />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <ThreeEdit /> },
      { path: 'analysis', element: <AnalysisPage /> },
      { path: 'editor', element: <ThreeEdit /> },
      { path: 'editor/:id', element: <ThreeEdit /> },
      { path: 'movingText', element: <MovingText /> },
      { path: 'rotating-display', element: <RotatingDisplay /> },
      { path: 'itemConfigure', element: <ItemConfigure /> },
      { path: 'scrollAnimate', element: <ScrollAnimate /> },
      { path: 'scrollControl', element: <ScrollControl /> },

      // = Not used for live
      // * Login
      { path: 'login', element: <Login /> },
      // * Menus
      { path: 'notUsed', element: <NotUsed /> },
      { path: 'exampla', element: <Exampla /> },
      { path: 'exampla2', element: <Exampla2 /> },
      { path: 'exampla3', element: <Exampla3 /> },
      { path: 'demos', element: <Demos /> },
      // * good - use a a later date
      { path: 'integration', element: <IntegrationPage /> },
      { path: 'style', element: <StylePage /> },
      // * others
      { path: 'note-c', element: <NoteDetailPageC /> },
      { path: 'note-d', element: <NoteDetailPageD /> },
      { path: 'note-e', element: <NoteDetailPageE /> },
      { path: 'note-f', element: <NoteDetailPageF /> },
      { path: 'sceneMenu', element: <SceneMenu /> },
      { path: 'threeGrid', element: <ThreeGrid /> },
      { path: 'privotContrl', element: <PrivotContrl /> },
      { path: 'configPolyhedron', element: <ConfigPolyhedron /> },
      { path: 'transformCtrla2', element: <TransformCtrla2 /> },
      { path: 'transformTwo', element: <TransformTwo /> },
      { path: 'transformThree', element: <TransformThree /> },
      // { path: 'experience', element: <Experience /> }, // * The fox, needs to be wrapped in canvas, see: Homepage branch: /dev/1/1/2/11
      { path: 'imageAnalysa', element: <ImageAnalysa /> },
      { path: 'threeEdit', element: <ThreeEdit /> },
      // { path: 'rabbitText', element: <RabbitText /> },
      { path: 'surfaceText', element: <RabbitText /> },
      { path: 'note-e/:id', element: <NoteDetailPageE /> },
      { path: 'note-f/:id', element: <NoteDetailPageF /> },
      { path: 'chart-a', element: <ChartPageA /> },
      { path: 'chart-b', element: <ChartPageB /> },
      { path: 'chart-ticking', element: <ChartTicking /> },
      { path: 'intersecting', element: <Intersecting /> },
      { path: 'intersect2', element: <Intersect2 /> },
      { path: 'intersect3', element: <Intersect3 /> },
      { path: 'intersect4', element: <Intersect4 /> },
      { path: 'treeExample', element: <TreeExample /> },

      {
        path: 'protected',
        element: (
          <RequireAuth>
            <Protected />
          </RequireAuth>
        ),
        children: [
          // * { path: 'treeExample', element: <TreeExample /> },
        ],
      },
      {
        path: 'protected2',
        element: (
          <RequireAuth>
            <ProtectedSecond />
          </RequireAuth>
        ),
      },
    ],
  },
]);

export default router;

// * Old Routes 

// element: <MenaComp  menaList={MenaList.mainIds}/>,

// { path: 'game', element: <GameDetailPage /> },
// { path: 'note', element: <NoteDetailPage /> },
// { path: 'note-b', element: <NoteDetailPageB /> },

// { path: 'game', element: <GameDetailPage /> },
// { path: 'note', element: <NoteDetailPage /> },
// { path: 'note-b', element: <NoteDetailPageB /> },

// { path: 'post-add', element: <PostAddPage /> },
// { path: 'post-update', element: <PostUpdatePage /> },
// { path: 'post-observe', element: <ObservePosts /> },
// { path: 'page-add', element: <PageAddPage /> },
// { path: 'images', element: <ImagePage /> },

// { path: 'map', element: <MapPage /> },

// { path: 'transformCtrla3', element: <TransformCtrla3/>},

// { path: 'chart-display', element: <ChartDisplayPageA />},
// { path: 'chartauta', element: <Chartauta />},
