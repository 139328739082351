
import * as _ from 'lodash';
import { BehaviorSubject, interval, map, startWith, switchMap, tap, timer } from 'rxjs';

// import { GenerateMarketFigures } from '../interfaces/generate-chart';
import { StockPriceGenerateService } from './stock-price-generate.service';
import { GenerateMarketFigures } from '../interfaces/generate-chart';

export class ClassTickingStock {

  setInterval: any;
  lastHigh: number | undefined;

  constructor(
    private stockPrice: StockPriceGenerateService,
    private _tickerInterval: number = 1500 // Number of milliseconds.
  ) { }

  set tickerInterval(tickerInterval) {
    this._tickerInterval = tickerInterval;
  }

  get tickerInterval() {
    return this._tickerInterval;
  }

  getTickingStock(figuresRequired: GenerateMarketFigures) {
    // * Value for this.lastHigh evolves with time.
    // * this.lastHigh = figuresRequired.oldPrice;

    let values = this.stockPrice.generateMarketFiguresFromObject(figuresRequired);
    const ticking$ = new BehaviorSubject<string[][]>(values);


    this.setInterval = setInterval(() => {
      figuresRequired.oldPrice = this.lastHigh as number;
      figuresRequired.count = 1;
      const tick = this.stockPrice.generateMarketFiguresFromObject(figuresRequired);
      values = _.concat(values, tick);
      values = _.takeRight(values, 100);

      ticking$.next(values);

    }, this.tickerInterval + (this.tickerInterval * 0.5 * Math.random()));


    return ticking$;
  }

  getTickingStock2(figuresRequired: GenerateMarketFigures) {
    // * Value for this.lastHigh evolves with time.
    // * this.lastHigh = figuresRequired.oldPrice;

    // * Add history
    let values = this.stockPrice.generateMarketFiguresFromObject(figuresRequired);
    // console.log('%c ChartComponent |  getTickingStock2(figuresRequired) | x:', 'background-color: #4477BB; color: white', values.map(x => x[0]))
    values.pop(); // * remove the one for now() as it will be added by ticker.


    const figuresRequiredOne = {...figuresRequired};
    figuresRequiredOne.count = 1;


    const valuesFunc = (figuresRequired: GenerateMarketFigures)=> {

      figuresRequired.oldPrice = (this.lastHigh)? this.lastHigh as number : figuresRequired.oldPrice;

      // * Ticking only take one
      const figuresRequiredOne = { ...figuresRequired};
      figuresRequiredOne.count = 1;

      // * Add noew value to previous list
      const tick = this.stockPrice.generateMarketFiguresFromObject(figuresRequiredOne);
      values = values.concat(tick);
      values = values.slice( -figuresRequired.count);
      return values;
    }

    const ticking$ = interval(figuresRequired.intervalTime).pipe(
      startWith(values),
      map(x => valuesFunc(figuresRequired)),
      // tap(x =>  console.log('%c ChartComponent |  getTickingStock2(figuresRequired) | x:', 'background-color: #447744; color: white', x)),
      // tap(x => {
      // }),
    )

    return ticking$;
  }

}
