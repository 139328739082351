import { Heading } from '@aws-amplify/ui-react';
import React from 'react';
import Intersect4 from '../others/intersect4';

export default function StylePage() {
  return (
    <>
      <div style={{marginTop: "6em"}}>
      <Intersect4 />
      </div>
    </>
  );
}
