import { DataStore } from 'aws-amplify';
import { useEffect, useId, useState } from 'react';
import { LazyGtext3D, Gtext3D } from '../models';
import { XyzTuple } from '../interfaces/DbGltfa';
import { Gtext3DDoc } from '../interfaces/Gtext3DDoc';
import { setTuple } from './CrudTools';

export function useObserveGtext3DsFromGimageId(gimageId: string) {
  const [gtext3Ds, setGtext3Ds] = useState<LazyGtext3D[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  // console.log(
  //   '%c useCruidGtext3D | useObserveGtext3DsFromGimageId(gimageId: string) :',
  //   'background-color: #885555; color: white',
  //   '=============='
  // );

  useEffect(() => {
    // const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));

    const sub = DataStore.observeQuery(Gtext3D, (g) =>
      g.gimageID.eq(gimageId)
    ).subscribe(({ items }) => {
      setLoading(false);
      setGtext3Ds(items);
      //& console.log(
      //&   '%c useCruidGtext3D | useObserveGtext3DData(gtext3dId: string) | items : ',
      //&   'background-color: #885555; color: aquamarine',
      //&   items
      //& );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [gimageId]);

  return { gtext3Ds, loading };
}



export const useCrudGtext3D = () => {
  // const nameId = useId()
  
  const createGtext3D = (gimageId: string) => {

    async function createRecord() {
      return await DataStore.save(
        new Gtext3D({
          text: 'Hello World!',
          gimageID: gimageId,
          gname: `${gimageId}-${Math.random().toString(36).slice(2)}`,
          font: 'helvetiker_bold.typeface.json',
          position: [0, 0, 0],
          rotation: [0, 0, 0],
          scale: [1, 1, 1],
          color: '#87CEFA',
        })
      );
    }
    return createRecord();
  };

  const updateGtext3DFromName = async (
    // name: string,
    gtext3d: Gtext3D,
    object: THREE.Object3D | undefined
  ) => {
    // const updateGltfObject = async (gltfId: string, nodeId: string, object: string | undefined) => {
  
    if (gtext3d.gname === undefined) {
      return;
    }

    if (object === undefined) {
      return;
    }
  
    const originals = await DataStore.query(Gtext3D, (c) => c.and(c => [
      c.gname.eq(gtext3d.gname)
    ]));
  
    const original = originals[0];
  
  
    console.log(
      '%c useCrudGtext3D | updateGtext3DFromName | original :',
      'background-color: #BB5555; color: white',
      original
    );
  
    if (original) {
      const updatedGtext3D = await DataStore.save(
        Gtext3D.copyOf(original, (updated) => {
          updated.text = gtext3d.text;
          updated.color = gtext3d.color;
          updated.position = setTuple(object, 'position');;
          updated.scale = setTuple(object, 'scale');
          updated.rotation = setTuple(object, 'rotation');
        })
      );
      return updatedGtext3D;
    }
  };

  return {
    createGtext3D, updateGtext3DFromName
  };
};



