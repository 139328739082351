import { Card, Heading, Image } from '@aws-amplify/ui-react';
import React, { useEffect } from 'react';

export default function Intersect2() {
  useEffect(() => {
    const numSteps = 20.0;

    let prevRatio = 0.0;
    // let increasingColor = 'rgba(40, 40, 190, ratio)';
    let decreasingColor = 'rgba(190, 40, 40, ratio)';

    function increasingColor(ratio: number) {
      return `rgba(40, 40, 190, ${ratio})`;
    }

    function rotating(ratio: number) {
      return `rgba(40, 40, 190, ${ratio})`;
    }

    const cards = document.querySelectorAll('.box');

    createObserver();

    function createObserver() {
      let options = {
        root: null,
        // rootMargin: '10px',
        // rootMargin: '850px 150px 150px 150px',
        rootMargin: '550px 150px 100px 150px',
        threshold: buildThresholdList(),
        // threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      };

      const observer = new IntersectionObserver(handleIntersect, options);
      cards.forEach((card) => {
        observer.observe(card);
      });
    }

    function buildThresholdList() {
      let thresholds = [0]; // * need a 0 so undo returns to start
      let numSteps = 100;

      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }

      //   thresholds.push(0);
      return thresholds;
    }

    function handleIntersect(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry: any, index) => {
        entry.target.style.color = `rgba(200, 40, 40, ${entry.intersectionRatio})`;
        console.log('entry.intersectionRatio:', {ration: entry.intersectionRatio, index});

        if (isNaN(entry.intersectionRatio) ||  (entry.intersectionRatio < 0.01)){
            // * required to stop flash of full size image
            entry.target.style.transform = ` scale(0.1)`; // * required to stop flash of full size image
            // entry.target.style.opacity = `0`;
            // entry.target.style.visibility = 'hidden';
            // entry.target.style.height = '0px';
            // entry.target.style.display = 'none';
            
            
        } else {
            // entry.target.style.visibility = 'visible';
            // entry.target.style.height = '60vh';
            // entry.target.style.display = 'block';
            entry.target.style.transform = ` scale(${entry.intersectionRatio}) rotate(${
                entry.intersectionRatio / 4 - 0.25
              }turn)`;
        }
        entry.target.style.opacity = `${entry.intersectionRatio}`;
        // entry.target.style.transform = ` scale(${entry.intersectionRatio})`;
      });
    }
  }, []);

  return (
    <>
      {/* <Card height={800}></Card> */}
      <Card ></Card>
      {[1, 2, 4, 5, 6, 7].map((value, index) => {
        return (
        //   <Card height={500} key={index}>
          <Card key={index} >
            <Image
              alt="Amplify logo"
              src="/iphone.jpeg"
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              maxHeight="80vh"
              height="600px"
              // width="30px"
            //   opacity="0"
            //   transform="scale(0) rotate(0.25turn)"
              onClick={() => alert('📸 Say cheese!')}
              className="box"
              
            />
            {/* <Heading width={'80px'} fontSize={30} className="box" color={'rgba(200, 40, 40, 1'}> */}
            <Heading width={'80px'} fontSize={30}>
              Heading
            </Heading>
            <div className="vertical">
              Making content look  <strong>stimulating {index}</strong>
              From UI designs to delivery
              Add a scroll e.g. https://www.apple.com/uk/home-app/
            </div>
          </Card>
        );
      })}
      <Card height={600}></Card>
    </>
  );
}
