import './Intersecting.scss';

import { useState, useRef, useEffect } from 'react';

export default function Intersecting() {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log('entry: ', entry);
          // entry.target.classList.toggle("show", !!entry)
          entry.target.classList.toggle('slide-in', entry.isIntersecting);
        });
        console.log('entries: ', entries);
        // setIsIntersecting(entries[0].isIntersecting);
      },
      //   { rootMargin: '-300px' }
      {
        threshold: 0.5,
        rootMargin: '-100px',
      }
    );
    // console.log(isIntersecting);
    // observer.observe(ref.current);
    cards.forEach((card) => {
      observer.observe(card);
    });

    return () => observer.disconnect();
    //   }, [isIntersecting]);
  }, []);


  //   useEffect(() => {
  //     if (isIntersecting) {
  //       ref.current.querySelectorAll('div').forEach((el: any) => {
  //         el.classList.add('slide-in');
  //       });
  //     } else {
  //       ref.current.querySelectorAll('div').forEach((el: any) => {
  //         el.classList.remove('slide-in');
  //       });
  //     }
  //   }, [isIntersecting]);

  return (
    <div className="App">
      <div>isIntersecting: {isIntersecting}</div>

      <header>This is the Header</header>
      <main ref={ref}>
        <div className="card one">One</div>
        <div className="card two">Two</div>
        <div className="card three">Three</div>
        <div className="card four">Four</div>
        <div className="card five">Five</div>
        <div className="card six">Six</div>
      </main>
      <div>isIntersecting: {isIntersecting}</div>
      <footer>This is the Footer</footer>
    </div>
  );
}
