import { DataStore } from 'aws-amplify';
import { useEffect, useId, useState } from 'react';
import { LazyGbox, Gbox } from '../models';
import { XyzTuple } from '../interfaces/DbGltfa';
import { GboxDoc } from '../interfaces/GboxDoc';
import { setTuple } from './CrudTools';

export function useObserveGboxsFromGimageId(gimageId: string) {
  const [gboxs, setGboxs] = useState<LazyGbox[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  // console.log(
  //   '%c useCruidGbox | useObserveGboxsFromGimageId(gimageId: string) :',
  //   'background-color: #885555; color: white',
  //   '=============='
  // );

  useEffect(() => {
    // const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));

    const sub = DataStore.observeQuery(Gbox, (g) =>
      g.gimageID.eq(gimageId)
    ).subscribe(({ items }) => {
      setLoading(false);
      setGboxs(items);
      //& console.log(
      //&   '%c useCruidGbox | useObserveGboxData(gboxId: string) | items : ',
      //&   'background-color: #885555; color: aquamarine',
      //&   items
      //& );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [gimageId]);

  return { gboxs, loading };
}

export const useCrudGbox = () => {
  // const nameId = useId()

  const createGbox = (gimageId: string) => {
    async function createRecord() {
      return await DataStore.save(
        new Gbox({
          gimageID: gimageId,
          gname: `${gimageId}-${Math.random().toString(36).slice(2)}`,
          position: [0, 0, 0],
          rotation: [0, 0, 0],
          scale: [1, 1, 1],
          color: '#87CEFA',
        })
      );
    }
    return createRecord();
  };

  const updateGboxFromName = async (
    gbox: Gbox,
    object: THREE.Object3D | undefined
  ) => {
    // const updateGltfObject = async (gltfId: string, nodeId: string, object: string | undefined) => {

    if (gbox.gname === undefined) {
      return;
    }

    if (object === undefined) {
      return;
    }


    // @ DATABASE
    const originals = await DataStore.query(Gbox, (c) =>
      c.and((c) => [c.gname.eq(gbox.gname)])
    );

    const original = originals[0];

    //& console.log(
    //&   '%c useCrudGbox | updateGboxFromName | position :',
    //&   'background-color: #BB5555; color: white',
    //&   //& original,
    //&   gbox.position
    //&   //& positionTuple
    //& );

    // @ SAVE
    if (original) {
      const updatedGbox = await DataStore.save(
        Gbox.copyOf(original, (updated) => {
          // * Note: color was set via React.
          // * Position, Scale & Rotation are updated via <TransformControls/>, which
          // * updates the <mesh>, those values have to be extracted from the <mesh>.
          updated.color = gbox.color;
          updated.position = setTuple(object, 'position');;
          updated.scale = setTuple(object, 'scale');
          updated.rotation = setTuple(object, 'rotation');
        })
      );
      return updatedGbox;
    }
  };

  return {
    createGbox,
    updateGboxFromName,
  };
};

// function setTuple(
//   object: THREE.Object3D,
//   field: 'position' | 'scale' | 'rotation'
// ): XyzTuple {
//   const tuple: XyzTuple = [0, 0, 0];
//   tuple[0] = object[field].x || 0;
//   tuple[1] = object[field].y || 0;
//   tuple[2] = object[field].z || 0;

//   return tuple;
// }
