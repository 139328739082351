import React, { useEffect } from 'react';

import { Suspense, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import {
  OrbitControls,
  TransformControls,
  ContactShadows,
  useGLTF,
  useCursor,
} from '@react-three/drei';
import { proxy, useSnapshot } from 'valtio';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
// import { BufferGeometry } from 'three';

import * as THREE from 'three';
import * as _ from 'lodash';
import { GLTFA } from '../../../interfaces/Gltfa';

// Reactive state model, using Valtio ...
const modes = ['translate', 'rotate', 'scale'];
// const state = proxy({ current: null, mode: 0 })
const state = proxy({ current: '' as string, mode: 0 });

// function Model({ name, ...props }: { name: string }) {
function Model({
  name,
  ...props
}: {
  name: string;
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: number;
}) {
  // Ties this component to the state model
  const snap = useSnapshot(state);
  const [color, setColor] = useState<any>();
  // Fetching the GLTF, nodes is a collection of all the meshes
  // It's cached/memoized, it only gets loaded and parsed once
  const hospital = useGLTF(
    './glb/hospital_operating_theater_top_floor2.glb'
  ) as GLTFA;
  const hamburger = useGLTF('/glb/hamburger.glb') as GLTFA;

  // Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  // if (nodes[name].material.hasOwnProperty('color')) {
  //   setColor(nodes[name].material['color'])
  // }
  useEffect(() => {
    // setColor(_.get(nodes, `${name}.material.color`, 'white'));
    // setColor(_.get(nodes, `topBun.material.color`, 'white'));
    // const aaa = _.cloneDeep(nodes[name].material);
    // const aaa = nodes[name].material.clone()
    // aaa.clone(nodes[name].material);
    // const aaa = new THREE.Material;
    // aaa.copy({ nodes[name].material})

    setColor(
      new THREE.MeshBasicMaterial({
        color: 0x1111cc,
        wireframe: true,
      })
    );
  }, [name, hospital.nodes]);

  return (
    <>
      <mesh
        // Click sets the mesh as the new target
        onClick={(e) => (e.stopPropagation(), (state.current = name))}
        // If a click happened but this mesh wasn't hit we null out the target,
        // This works because missed pointers fire before the actual hits
        onPointerMissed={(e) => e.type === 'click' && (state.current = '')}
        // Right click cycles through the transform modes
        onContextMenu={(e) =>
          snap.current === name &&
          (e.stopPropagation(), (state.mode = (snap.mode + 1) % modes.length))
        }
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={(e) => setHovered(false)}
        name={name}
        geometry={hospital.nodes[name].geometry}
        // material={nodes[name].material}
        material={
          snap.current === name
            ? hamburger.nodes['cheese'].material
            : hospital.nodes[name].material
        }
        // material={(snap.current === name) ? color  : nodes[name].material }
        // material-color={snap.current === name ? '#ff6080' : color}
        {...props}
        dispose={null}
      />
    </>
  );
}

function Controls() {
  // Get notified on changes to state
  const snap = useSnapshot(state);
  const scene = useThree((state) => state.scene);
  return (
    <>
      {/* As of drei@7.13 transform-controls can refer to the target by children, or the object prop */}
      {snap.current && (
        <TransformControls
          object={scene.getObjectByName(snap.current)}
          //   mode={modes[snap.mode]}
        />
      )}
      {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
      <OrbitControls
        makeDefault
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 1.75}
      />
    </>
  );
}

export default function TransformThree() {
  return (
    <Canvas camera={{ position: [0, -10, 80], fov: 50 }} dpr={[1, 2]}>
      {/* <pointLight position={[50, 100, 100]} intensity={1.8} /> */}
      <directionalLight
        castShadow
        position={[1, 2, 3]}
        intensity={1.5}
        shadow-normalBias={0.04}
      />

      <hemisphereLight
        color="#FFFFFF"
        groundColor="#b9b9b9"
        position={[-7, 25, 13]}
        // intensity={0.95}
        intensity={1.5}
      />
      <Suspense fallback={null}>
        <group position={[0, 10, 0]} scale={3}>
          <Model
            name="hospital737"
            position={[0, -0.95, 0]}
            rotation={[0, 0, 0]}
          />
          <Model
            name="hospital738"
            position={[1, -0.95, 0]}
            rotation={[0, 0, 0]}
          />
          <Model
            name="hospital739"
            position={[2, -0.95, 0]}
            rotation={[0, 0, 0]}
          />
          <Model
            name="hospital740"
            position={[3, -0.95, 0]}
            rotation={[0, 0, 0]}
          />
          {/* <Model name="cheese" position={[0, 0.25, 0]} rotation={[0, 0, 0]} /> */}
          {/* <Model name="meat" position={[0, 0, 0]} rotation={[0, 0, 0]} />

          <Model
            name="bottomBun"
            position={[0, -2.85, 0]}
            rotation={[0, 0, 0]}
          /> */}
          <ContactShadows
            rotation-x={Math.PI / 2}
            position={[0, -35, 0]}
            opacity={0.25}
            width={200}
            height={200}
            blur={1}
            far={50}
          />
        </group>
      </Suspense>
      <Controls />
    </Canvas>
  );
}
