import {
  GizmoHelper,
  GizmoViewport,
  OrbitControls,
  Text3D,
  TransformControls,
  useCursor,
  useGLTF,
  useHelper,
} from '@react-three/drei';
import { Vector3, useThree } from '@react-three/fiber';
import { useControls } from 'leva';
import _get from 'lodash/get';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import * as THREE from 'three';
import { proxy, useSnapshot } from 'valtio';

import { useCrudGbox } from '../../../hooks/useCrudGbox';
import { useCrudGltf } from '../../../hooks/useCrudGltf';
import { useCrudGtext3D } from '../../../hooks/useCrudGtext3D';
import { GLTFA } from '../../../interfaces/Gltfa';
import ColorForm from '../forms/ColorForm';
import { Gbox, Gtext3D, LazyGbox, LazyGtext3D } from '../../../models';
import { GboxDoc } from '../../../interfaces/GboxDoc';
import { Gtext3DDoc } from '../../../interfaces/Gtext3DDoc';
import { Flex, View } from '@aws-amplify/ui-react';
import Text3DForm from '../forms/Text3DForm';
import { GsceneDoc } from '../../../interfaces/GsceneDoc';

type GmeshType = 'Gtext3d' | 'Ggltf' | 'Gbox' | null;
type modeType = 'translate' | 'rotate' | 'scale' | null;

// * Reactive state model, using Valtio ...
export const sceneState = proxy({
  current: '' as string,
  gltfId: '' as string,
  gmeshType: null as GmeshType,
  mode: 'translate' as modeType,
  showHelper: false, // * i.e. 3D wireframe bounding box.
  allowEdit: false, // * i.e. Allow access to edit controls.
  onModeChange: (mode: modeType) => {
    switch (mode) {
      case 'translate':
        sceneState.mode = null;
        break;
      case null:
        sceneState.mode = 'rotate';
        break;
      case 'rotate':
        sceneState.mode = 'scale';
        break;
      case 'scale':
        sceneState.mode = 'translate';
        break;
      default:
        sceneState.mode = 'translate';
        break;
    }
  },
  gsceneDoc: {} as GsceneDoc,
  gboxDoc: {} as GboxDoc,
  gtext3DDoc: {} as Gtext3DDoc,
  updateSelected(current: string, gmeshType: GmeshType, gltfId?: string) {
    if (sceneState.allowEdit === true) {
      sceneState.showHelper = current === '' ? false : true;
      sceneState.current = current;
      sceneState.gmeshType = gmeshType;
      gltfId && (sceneState.gltfId = gltfId);
    }
  },
  // updateColor(current: string, gltfId?: string) {
  //   proxyState.gboxDoc.gboxs[proxyState.current] = {}

  // },
});

export function GgltfDisplay({
  name,
  ...props
}: {
  gltfId: string;
  name: string;
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale?: [number, number, number];
  fileName?: string;
}) {
  // * Ties this component to the state model
  const snap = useSnapshot(sceneState);

  // * Fetching the GLTF, nodes is a collection of all the meshes
  // * It's cached/memoized, it only gets loaded and parsed once
  // * const gltfHosp = useGLTF('/glb/hospital_operating_theater_top_floor2.glb') as GLTFA;
  const { nodes } = useGLTF(`/glb/${props.fileName}`) as GLTFA;

  const gltfBurger = useGLTF('/glb/hamburger.glb') as GLTFA;

  // * Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false);
  const meshRef = useRef<any>();

  useHelper(
    sceneState.showHelper && meshRef,
    THREE.BoxHelper,
    snap.current === name ? 'orange' : 'cyan'
  ); // * displays wireframe around Models

  // * you can pass false instead of the object ref to hide the helper
  useCursor(hovered);

  return (
    <>
      <mesh
        ref={meshRef}
        // * Click sets the mesh as the new target
        onClick={(e) => (
          e.stopPropagation(),
          sceneState.updateSelected(name, 'Ggltf', props.gltfId)
        )}
        onDoubleClick={(e) => (
          e.stopPropagation(), snap.onModeChange(snap.mode)
        )}
        // * If a click happened but this mesh wasn't hit we null out the target,
        // * This works because missed pointers fire before the actual hits
        onPointerMissed={(e) => (
          e.stopPropagation(),
          e.type === 'click' &&
            snap.current === name &&
            sceneState.updateSelected('', null)
        )}
        // * ====================
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={(e) => setHovered(false)}
        name={name}
        geometry={nodes[name].geometry}
        material={
          snap.current === name
            ? gltfBurger.nodes['cheese'].material
            : nodes[name].material
        }
        {...props}
        dispose={null}
      />
    </>
  );
}

// function updatedConsoleLog( e: THREE.MeshStandardMaterial){
//   //   console.log(
//   //   '%c TransformCtrla4 | updatedConsoleLog | snap :',
//   //   'background-color: #5555BB; color: white',
//   //   JSON.stringify(e)
//   // );
// }

export function TextWithColorChanger() {
  const { color } = useControls({
    // * This is an independant control, that create a form with the listed fields.
    // * It does not auto do something.
    // * What you choose to do with the value of color in your code is up to you.
    color: '#884444',
  });

  useEffect(() => {
    //& console.log(
    //&   '%c TransformCtrla4 | TextWithColorChanger | color :',
    //&   'background-color: #5555BB; color: white',
    //&   color
    //& );
  }, [color]);

  return (
    <Text3D
      key={'sdFsdf'}
      font={'./fonts3d/helvetiker_bold.typeface.json'}
      lineHeight={0.5}
      letterSpacing={-0.025}
      name={'asdfasdf'}
      size={5}
    >
      BBBBBBBBBBBB
      {/* <meshStandardMaterial color={color} onUpdate={(e) => updatedConsoleLog(e)}/> */}
      <meshStandardMaterial color={color} />
    </Text3D>
  );
}

export function GboxDisplay({
  // color,
  ...props
}: {
  gname: string;
  // position?: [number, number, number];
  // rotation?: [number, number, number];
  // scale?: [number, number, number];
  // color?: string;
}) {
  const snap = useSnapshot(sceneState);

  // console.log(
  //   '%c TransformCtrla4 | GboxDisplay | snap :',
  //   'background-color: #5555BB; color: white',
  //   snap
  // );

  // * Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false);
  const meshRef = useRef<any>();

  useHelper(
    sceneState.showHelper && meshRef,
    THREE.BoxHelper,
    snap.current === props.gname ? 'orange' : 'cyan'
  ); // * displays wireframe around Models

  // * you can pass false instead of the object ref to hide the helper
  useCursor(hovered);

  // if (proxyState.gboxDoc.ids) return <></>;
  // const proxyGbox = proxyState.gboxs.filter((gbox) => gbox.gname === props.gname)[0];

  if (snap.gboxDoc?.gboxs === undefined) {
    return <></>;
  }

  const snapGbox = snap.gboxDoc.gboxs[props.gname];

  const fields = ['position', 'rotation', 'scale'];

  const snapProps = {} as { [id: string]: unknown };

  fields.forEach((field) => {
    snapProps[field] = snapGbox[field as keyof LazyGbox];
  });

  return (
    <mesh
      ref={meshRef}
      name={props.gname}
      onClick={(e) => (
        e.stopPropagation(), sceneState.updateSelected(props.gname, 'Gbox')
      )}
      onDoubleClick={(e) => (e.stopPropagation(), snap.onModeChange(snap.mode))}
      onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
      onPointerOut={(e) => setHovered(false)}
      onPointerMissed={(e) => (
        e.stopPropagation(),
        e.type === 'click' &&
          snap.current === props.gname &&
          sceneState.updateSelected('', null)
      )}
      // {...props}
      {...snapProps}
      // material-color={proxyState.gboxDoc.gboxs[props.gname].color}
    >
      <meshStandardMaterial
        // color={
        //   snap.current === props.gname
        //     ? 'goldenrod'
        //     : (stateGbox['color'] as unknown as THREE.Color)
        // }
        // color={(stateGbox['color'] as unknown as THREE.Color)}
        color={snapGbox.color as unknown as THREE.Color}
      />
      <boxGeometry attach="geometry" />
    </mesh>
  );
}

export function Gtext3DDisplay({
  ...props
}: {
  gname: string;
  // text: string;
  // position?: [number, number, number];
  // rotation?: [number, number, number];
  // scale?: [number, number, number];
  // color?: string;
}) {
  const snap = useSnapshot(sceneState);

  //& console.log(
  //&   '%c TransformCtrla4 | Gtext3DDisplay | snap :',
  //&   'background-color: #5555BB; color: white',
  //&   snap
  //& );
  // * Feed hover state into useCursor, which sets document.body.style.cursor to pointer|auto
  const [hovered, setHovered] = useState(false);
  const meshRef = useRef<any>();

  useHelper(
    sceneState.showHelper && meshRef,
    THREE.BoxHelper,
    snap.current === props.gname ? 'orange' : 'cyan'
  ); // * displays wireframe around Models

  // * you can pass false instead of the object ref to hide the helper
  useCursor(hovered);

  if (snap.gtext3DDoc?.gtext3Ds === undefined) {
    return <></>;
  }

  const snapGtext3D = snap.gtext3DDoc.gtext3Ds[props.gname];

  // const {text, position, rotation, scale, color } = snapGtext3D;
  const fields = ['position', 'rotation', 'scale'];
  const snapProps = {} as { [id: string]: unknown };
  fields.forEach((field) => {
    snapProps[field] = snapGtext3D[field as keyof LazyGtext3D];
  });
  return (
    <Text3D
      ref={meshRef}
      font={'/fonts3d/helvetiker_bold.typeface.json'}
      lineHeight={0.5}
      letterSpacing={-0.025}
      name={props.gname}
      onClick={(e) => (
        e.stopPropagation(), sceneState.updateSelected(props.gname, 'Gtext3d')
      )}
      onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
      onPointerOut={(e) => setHovered(false)}
      onDoubleClick={(e) => (e.stopPropagation(), snap.onModeChange(snap.mode))}
      onPointerMissed={(e) => (
        e.stopPropagation(),
        e.type === 'click' &&
          snap.current === props.gname &&
          sceneState.updateSelected('', null)
      )}
      // position={position as unknown as Vector3}
      {...snapProps} // * Spread also smooths out issues with Types
    >
      {snapGtext3D.text}
      {/* <meshStandardMaterial color={'hotpink'} /> */}
      <meshStandardMaterial
        // color={snap.current === props.gname ? 'orange' : props.color}
        // color={color as unknown as THREE.Color}

        color={
          snap.current === props.gname && snap.mode !== null
            ? 'orange'
            : (snapGtext3D.color as unknown as THREE.Color)
        }
      />
    </Text3D>
  );
}

export function ControlList() {
  const { color } = useControls({
    // * This is an independant control, that create a form with the listed fields.
    // * What you choose to do with the value of color in your code is up to you.
    color: '#884444',
  });

  return <></>;
}

export function ControlEdit() {
  // * Get notified on changes to state
  const snap = useSnapshot(sceneState);
  const scene = useThree((state) => state.scene);

  const { updateGltfObject } = useCrudGltf();
  const { updateGtext3DFromName } = useCrudGtext3D();
  const { updateGboxFromName } = useCrudGbox();

  function updateDB() {
    if (snap.current === '') {
      return;
    }

    const mesh = scene.getObjectByName(snap.current);

    if (snap.gmeshType === 'Ggltf') {
      // * Object from Gtlf image
      updateGltfObject(snap.gltfId, snap.current, mesh);

    } else if (snap.gmeshType === 'Gtext3d') {
      const gtext3d = sceneState.gtext3DDoc.gtext3Ds[snap.current];
      updateGtext3DFromName(gtext3d, mesh);
      
    } else if (snap.gmeshType === 'Gbox') {
      const gbox = sceneState.gboxDoc.gboxs[snap.current];
      updateGboxFromName(gbox, mesh);
    }

    //& console.log(
    //&   '%c TransformCtrla4 |  Controls() | onPointerMissed | scene.getObjectByName(snap.current) :',
    //&   'background-color: #555588; color: white',
    //&   snap.current,
    //&   sceneState.gboxDoc.gboxs[snap.current],
    //&   snap.gboxDoc.gboxs[snap.current],
    //&   snap.gmeshType,
    //&   mesh
    //& );
  }

  sceneState.allowEdit = true;

  // * As of drei@7.13 transform-controls can refer to the target by children, or the object prop
  return (
    <>
      {snap.current && snap.mode && (
        <>
          {/* <ControlList /> */}
          <TransformControls
            object={scene.getObjectByName(snap.current)}
            // mode={modes[snap.mode]}
            mode={snap.mode} // * 'translate' | 'rotate' | 'scale'
            // mode={'scale'} // * 'translate' | 'rotate' | 'scale'
            // size={2.3}
            size={1.5}
            onPointerMissed={(e) => (
              e.stopPropagation(),
              e.type === 'click' &&
                (updateDB(), sceneState.updateSelected('', null))
            )}
          />
        </>
      )}
      {snap.current && snap.mode === null && (
        <>
          {/* <ControlList /> */}
          <TransformControls
            // ! not sure if going to be required
            object={scene.getObjectByName(snap.current)}
            // mode={modes[snap.mode]}
            // mode={snap.mode} // * 'translate' | 'rotate' | 'scale'
            // mode={'scale'} // * 'translate' | 'rotate' | 'scale'
            // size={2.3}
            size={1.5}
            onPointerMissed={(e) => (
              e.stopPropagation(),
              e.type === 'click' &&
                (updateDB(), sceneState.updateSelected('', null))
            )}
          />
        </>
      )}
      {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
    </>
  );
}

export function ControlOrbit() {
  return (
    <OrbitControls
      makeDefault
      minPolarAngle={0}
      maxPolarAngle={Math.PI / 1.75}
    />
  );
}

export function ControlViewport() {
  return (
    <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
      <GizmoViewport
        axisColors={['#9d4b4b', '#2f7f4f', '#3b5b9d']}
        labelColor="grey"
      />
    </GizmoHelper>
  );
}

export function ControlColorForm() {
  const snap = useSnapshot(sceneState);
  const [color, setColor] = useState('#555555');

  useEffect(() => {
    if (snap.gmeshType === 'Gbox') {
      setColor(snap.gboxDoc.gboxs[snap.current]?.color || '#999999');
    } else if (snap.gmeshType === 'Gtext3d') {
      setColor(snap.gtext3DDoc.gtext3Ds[snap.current]?.color || '#999999');
    }
  }, [snap]);

  function updateColor(color: { r: number; g: number; b: number; a: number }) {
    let color3 = new THREE.Color(
      `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
    ).getHexString();

    setColor(color3);
    // const color4 = new THREE.Color("rgb(100, 0, 0)").getHex();
    color3 = `#${color3}`;

    if (snap.gmeshType === 'Gbox') {
      const gbox = sceneState.gboxDoc.gboxs[sceneState.current];
      if (gbox.color !== color3) {
        let gboxNew = { ...gbox, color: color3 };
        sceneState.gboxDoc.gboxs[snap.current] = gboxNew;
      }
    } else if (snap.gmeshType === 'Gtext3d') {
      let gtext3D = sceneState.gtext3DDoc.gtext3Ds[sceneState.current];
      if (gtext3D?.color !== color3) {
        let gtext3DNew = { ...gtext3D, color: color3 };
        sceneState.gtext3DDoc.gtext3Ds[sceneState.current] = gtext3DNew;
      }
    }
  }

  return (
    <>
      {snap.current && snap.mode === null && (
        <View backgroundColor={'#333'} padding={'small'} borderRadius="large">
          <Flex
            direction="column"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
            <ColorForm updateColor={updateColor} gcolor={color} />
            {snap.gmeshType === 'Gtext3d' && <ControlMeshForm />}
          </Flex>
        </View>
      )}
    </>
  );
}

export function ControlMeshForm() {
  const snap = useSnapshot(sceneState);
  const [text3D, setText3D] = useState('jjj');

  useEffect(() => {
    // * Populate form on load.
    if (snap.gmeshType === 'Gtext3d') {
      const gName = 'gtext3DDoc';
      // setText3D(snap[gName].gtext3Ds[snap.current]?.text || '#999999');
      setText3D(snap.gtext3DDoc.gtext3Ds[snap.current]?.text || '#999999');
    }
  }, [snap]);

  function updateText(text: string) {
    // * Write to DB
    //& console.log(
    //&   '%c TransformCtrla4 | ControlMeshForm | text :',
    //&   'background-color: #5555BB; color: white',
    //&   text
    //& );

    setText3D(text);

    if (snap.gmeshType === 'Gtext3d') {
      let gtext3D = sceneState.gtext3DDoc.gtext3Ds[sceneState.current];
      if (gtext3D?.text !== text) {
        let gtext3DNew = { ...gtext3D, text: text };
        sceneState.gtext3DDoc.gtext3Ds[sceneState.current] = gtext3DNew;
      }
    }
  }

  return (
    <>
      {snap.current && snap.mode === null && (
        <Text3DForm gtext3D={text3D} updateText={updateText} />
      )}
    </>
  );
}
