import { useGLTF } from '@react-three/drei';
import React, { useEffect, useMemo, useState } from 'react';

import { GLTFA } from '../../../interfaces/Gltfa';
import { DataStore } from '@aws-amplify/datastore';

// import { Gltf } from "../../models";
import { Page, Gltf, LazyGltf } from '../../../models';
import {
  // useCreateGnodes,
  useCrudGltf,
  // createGltf,
  // useCreateGltf,
  useGltfDataForDb,
} from '../../../hooks/useCrudGltf';
import { Button, Flex, Text } from '@aws-amplify/ui-react';

async function createPage() {
  await DataStore.save(
    new Page({
      title: 'JLorem ipsum dolor sit amet',
      desc: 'Lorem ipsum dolor sit amet',
      image_url: 'Lorem ipsum dolor sit amet',
    })
  );
}

function ImageAnalysa() {
  const { createGltf } = useCrudGltf();
  const [gltfCreated, setGltfCreated] = useState<LazyGltf>();

  // Fetching the GLTF, nodes is a collection of all the meshes
  // It's cached/memoized, it only gets loaded and parsed once
  // const fileName = 'hospital_operating_theater_top_floor2.glb';
  // const fileName = 'city_2/office_block_2.glb'; // * Note: need to add directory
  // const fileName = 'wind_turbine.glb'; // * Note: need to add directory
  // * this is the file on the Harddrive on Mac.
  // const fileName = 'greenhouse2.glb';
  const fileName = 'tree5.glb';
  // const fileGlb = useGLTF(`./glb/${fileName}`) as GLTFA;
  const fileGlb = useGLTF(`./glb/${fileName}`) as GLTFA;
  // const fileGlb = useGLTF(fileName) as GLTFA;

  const nodeIds = Object.keys(fileGlb.nodes);
  const gltfaData = useGltfDataForDb(fileName, nodeIds);
  
  async function processGltf() {
    //@ use to save gltf record
    const gltf = await createGltf(gltfaData);
    setGltfCreated(gltf);
    //& console.log(
    //&   '%c ImageAnalysa | processGltf() | gltf : ',
    //&   'background-color: #884477; color: white',
    //&   gltf
    //& );
  }

  return (
    <>
      <h2>ImageAnalysa</h2>

      {gltfCreated && (
        <>
          <Text>Created: </Text>
          <Text variation="warning">{gltfCreated.id}</Text>
          {/* <pre>{JSON.stringify(gltfCreated, null, 2)}</pre> */}
        </>
      )}
      <Flex>
        <Text>{fileName}</Text>
        <Button onClick={() => processGltf()} variation="primary">
          Create
        </Button>
      </Flex>
      {nodeIds.map((nodeId) => (
        <div key={nodeId}>{nodeId}</div>
      ))}
      <pre>{JSON.stringify(gltfaData, null, 2)}</pre>
    </>
  );
}

export default ImageAnalysa;
