import { View } from '@aws-amplify/ui-react';
import { ContactShadows, GizmoHelper, GizmoViewport } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { useEffect } from 'react';
import { Suspense } from 'react';

import { useObserveGboxsFromGimageId } from '../../../hooks/useCrudGbox';
import {
  useCrudGltf,
  useObserveGltfsFromGimageId,
} from '../../../hooks/useCrudGltf';
import { useObserveGtext3DsFromGimageId } from '../../../hooks/useCrudGtext3D';
import { sceneState } from '../widgets/TransformCtrla4';
import ThreeEdit from '../display/ThreeEdit';
import {
  convertGboxToDoc,
  convertGsceneToDoc,
  convertGtext3DToDoc,
  createMeshTree,
} from '../services/MeshServices';
import { useObserveGscenesFromGimageId } from '../../../hooks/useCrudGscene';

export interface Props {
  gimageId: string;
}

export default function useLoadSceneState({ gimageId }: Props) {
  const { useGetGltfsDisplayNodes } = useCrudGltf();

  const { gltfs } = useObserveGltfsFromGimageId(gimageId);
  const gltfas = useGetGltfsDisplayNodes(gltfs);
  const { gscenes } = useObserveGscenesFromGimageId(gimageId);
  const { gtext3Ds } = useObserveGtext3DsFromGimageId(gimageId);
  const { gboxs } = useObserveGboxsFromGimageId(gimageId);

  useEffect(() => {
    sceneState.gsceneDoc = convertGsceneToDoc(gscenes);
  }, [gscenes]);

  useEffect(() => {
    sceneState.gboxDoc = convertGboxToDoc(gboxs);
  }, [gboxs]);

  useEffect(() => {
    sceneState.gtext3DDoc = convertGtext3DToDoc(gtext3Ds);
  }, [gtext3Ds]);

  useEffect(() => {
    const gsceneId = sceneState.gsceneDoc.nameIds[0]; // * there is only one at them moment.
    // debugger
    if (sceneState.gsceneDoc.gscenes[gsceneId]) {
      // const meshTree = JSON.parse(sceneState.gsceneDoc.gscenes[gsceneId].meshTree);

      if (gtext3Ds && gboxs && gscenes) {

        const meshTree = createMeshTree({ gtext3Ds, gboxs, gscenes });
        sceneState.gsceneDoc.gscenes[gsceneId] = {
          ...sceneState.gsceneDoc.gscenes[gsceneId],
          meshTree,
        };
      }
    }
  }, [gboxs, gscenes, gtext3Ds]);
}
