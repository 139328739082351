// components/Protected.js
// import { useAuthenticator, Heading, View, Flex } from '@aws-amplify/ui-react';
import { View, Flex } from '@aws-amplify/ui-react';
// import { Outlet } from 'react-router-dom';
import { MenaComp } from '../../MenaComp';
import { MenaList } from '../../MenaData';
import { Canvas } from '@react-three/fiber';
import Experience from '../Experience';
// import TransformTwo from './TransformTwo';
// import TransformCtrla2 from './TransformCtrla2';
// import PrivotContrl from '../PrivotContrl';
// import { PostCreateForm } from '../../../ui-components';

export function Demos() {
  // const { route } = useAuthenticator((context) => [context.route]);

  // const message =
  //   route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';
  return (
    <>
      {/* <Heading level={1}>{message}</Heading> */}
      {/* <Outlet /> */}
        <MenaComp menaList={MenaList.demoIds} />

        <Flex>
        {/* <View width={'90vw'} height={'85vh'}> */}
        <View width={'100vw'} height={'98vh'}>
          <Canvas
            shadows
            camera={{
              fov: 45,
              near: 0.1,
              far: 200,
              position: [-4, 3, 6],
            }}
          >
            <Experience />
          </Canvas>
        </View>
      </Flex>
    </>
  );
}
