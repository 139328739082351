import { Button, Flex, View, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import ImageAnalysa from '../widgets/ImageAnalysa';
// import ImageDbDisplay from '../analyse/ImageDbDisplay';
import ImageDbDisplay2 from '../widgets/ImageDbDisplay2';
import GimageEnvironment from '../examples/GimageEnvironment';
import AddToThree from '../widgets/AddToThree';
import GimageOfficeBlock from '../examples/GimageOfficeBlock';
import GimageEnviro from '../examples/GimageEnviro';
import { useParams } from 'react-router-dom';
import { MeshTree } from '../../tree/MeshTree';
import { proxy, useSnapshot } from 'valtio';

import { sceneState } from '../widgets/TransformCtrla4';
import useLoadSceneState from '../data/SceneData';

interface Props {
  gimageId: string;
}

export function ThreeEdit2({ gimageId }: Props) {
  // * Load sceneState
  useLoadSceneState({ gimageId });

  const snap = useSnapshot(sceneState);
  const [items, setItems] = useState<any>();
  const [sceneId, setSceneId] = useState<string | number>();

  // useEffect(() => {
  //   // debugger
  //   // * Getting the live data
  //   if (snap.gsceneDoc.nameIds && snap.gsceneDoc.gscenes) {
  //     const gsceneId = snap.gsceneDoc.nameIds[0]; // * there is only one at them moment.
  //     if (snap.gsceneDoc.gscenes[gsceneId]){
  //       debugger
  //       const aaa = sceneState.gsceneDoc.gscenes[gsceneId].meshTree;
  //       if (sceneState.gsceneDoc.gscenes[gsceneId].meshTree){
  //         debugger
  //         // const meshTree = JSON.parse(aaa);
  //         const meshTree = sceneState.gsceneDoc.gscenes[gsceneId].meshTree;
  //         setItems(meshTree);
  //       }
  //       // const meshTree = snap.gsceneDoc.gscenes[gsceneId].meshTree;
  //     } else {
  //       console.log(
  //         '%c ThreeEdit2 | useEffect( .., [snap stuff]) | ??? :',
  //         'background-color: #775599; color: white',
  //         "????"
  //       );

  //     }

  //   }

  //   // ! if empty live data populated it. 
  // }, [snap.gsceneDoc.gscenes, snap.gsceneDoc.nameIds]);

  useEffect(() => {
    if (snap.gsceneDoc?.nameIds) {
      const sceneId = snap.gsceneDoc.nameIds[0];
      if (sceneId){
        if (snap.gsceneDoc.gscenes[sceneId].meshTree){
          const meshTree = sceneState.gsceneDoc.gscenes[sceneId].meshTree;
          setItems(meshTree);
          setSceneId(sceneId)

        }
      }
    }

  }, [snap.gsceneDoc.gscenes, snap.gsceneDoc.nameIds])

  return (
    <>
      <h1>ThreeEdit2</h1>
      <View>
        {items && sceneId && <MeshTree items={items} sceneId={sceneId}/>}
        {/* <MeshTree items={items} /> */}
        <pre>{JSON.stringify(sceneState, null, 2)}</pre>
      </View>
    </>
  );
}

