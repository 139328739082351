import {
  Card,
  Text,
  Grid,
  Heading,
  ThemeProvider,
  Theme,
  useTheme,
  View,
  Flex,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { DataStore, SortDirection, Storage } from 'aws-amplify';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';

// import ItemCardCollection from '../ui-components/ItemCardCollection';
// import SocialPage from '../ui-components/SocialPage';
import { LazyComment, LazyPage, Page } from '../../models';
import { PageListItemCollection, PageUpdateForm } from '../../ui-components';
import { PageUpdateFormInputValues } from '../../ui-components/PageUpdateForm';
// import {
//   CommentCard,
//   CommentCardCollection,
//   CommentCreateForm,
//   EditPost,
//   JPostEdit,
//   James2,
//   JamesComponent,
//   JamesComponentCollection,
//   PostUpdateForm,
// } from '../ui-components';

const NoteDetailPageC = () => {
  const { tokens } = useTheme();

  // const [selected, setSelected] = useState<LazyPage>({
  //   title: 'x',
  //   desc: 'x',
  //   image_url: 'x',
  // } as LazyPage);
  const [selected, setSelected] = useState<LazyPage>();
  const [fields, setFields] = useState<any>();
  // const [selectedPrev, setSelectedPrev] = useState<LazyPage>();
  // const [selected, setSelected] = useState<Page>({} as Page);
  // const [comments, setComments] = useState<LazyComment[]>(); // * required as selected.comments
  // const [comments, setComments] = useState<Comment[]>(); // * required as selected.comments
  // * is not correctly mark as an array e.g. comments[].
  const [imageKeys, setImageKeys] = useState<S3ProviderListOutputItem[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [pages, setPages] = useState<LazyPage[]>();
  const [items, setItems] = React.useState(undefined);
  const [other, setOther] = useState<any>(undefined);

  // useEffect(() => {
  //   setSelectedPrev(selected);
  //   console.log('updating setSelectedPrev()');
  // }, [selected]);

  const updatedSelected = (slcted: Page) => {
    // const fieldNotNulls = ['title', 'desc', 'image_url']

    Object.keys(slcted).map((field) => {
      if (typeof slcted[field as keyof LazyPage] === 'string') {
        return slcted[field as keyof LazyPage] === undefined
          ? ''
          : slcted[field as keyof LazyPage];
      } else {
        return slcted[field as keyof LazyPage];
      }
    });

    setSelected(selected);
  };

  useEffect(() => {
    // const aaa = {...selected};
    if (!selected) return;
    try {
      const aaa = {} as any;
      Object.entries(fields).forEach(([key, value]) => {
        if (typeof key === 'string') {
          if (typeof value === 'string' && value.trim() === '') {
            aaa[key] = value;
          }
        }
      });
      setSelected({ ...selected, ...aaa });
    } catch (err) {
      // if (onError) {
      //   onError(modelFields, err.message);
      // }
    }
  }, [fields]);

  useEffect(() => {
    async function setItemsFromDataStore(items: LazyPage[]) {
      var loaded = Promise.all(
        items.map(async (item) => ({
          ...item,
          // comments: await item.Comments.toArray(),
          // image_url: await Storage.get(item.image_url!, { level: 'private' }),
        }))
      );
      // const loaded2 = loaded as any; // * Correct type
      console.log('loaded', loaded);
      return await loaded;
    }

    const subscription = DataStore.observeQuery(
      Page,
      (p) =>
        p.and((p) => [
          // p.title.beginsWith('title'),
          // p.rating.gt(10)
        ]),
      {
        sort: (s) => s.title(SortDirection.DESCENDING),
      }
    ).subscribe(async (data) => {
      const { items, isSynced } = data;

      const items2 = await setItemsFromDataStore(items);
      // ! convert to doc format

      // ! setSelected(items[selected.id])

      console.log('items2', items2);

      // setPages(data.items);
      setPages(items2);
      if (selected === undefined) {
        setSelected(items2[0]);
      }
      // setOther(items2);
      // setPage(data.items[2]);

      console.log(
        `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
      );
      console.log(`[Snapshot] Pages:`, data);
      console.log(`[Snapshot] Pages:`, data.items);
    });

    return () => subscription.unsubscribe();
  }, []);

  console.log(selected);
  // console.log(comments);

  const fetchImages = async () => {
    const { results } = await Storage.list('', {
      pageSize: 50,
      level: 'private',
    });
    console.log('await Storage.list( | results', results);

    setImageKeys(results);
    const s3Images = await Promise.all(
      results.map(
        async (image) => await Storage.get(image.key!, { level: 'private' })
      )
    );
    setImages(s3Images);
    console.log('s3Images', s3Images);
  };

  useEffect(() => {
    // fetchImages();
  }, []);

  // const theme: Theme = {
  //   name: 'rating-theme',
  //   tokens: {
  //     components: {
  //       rating: {
  //         filled: { color: { value: '{colors.yellow.60}' } },
  //         empty: { color: { value: '{colors.neutral.80}' } },
  //         small: { size: { value: '{fontSizes.xxs}' } },
  //         default: { size: { value: '{fontSizes.large}' } },
  //         large: { size: { value: '{fontSizes.xxxl}' } },
  //       },
  //     },
  //   },
  // };

  return (
    <>
      {/* <ThemeProvider> */}
      {/* <Card>
        {images.map((image) => (
          <div>image: {image}</div>
        ))}
      </Card> */}
      <Grid
        columnGap="0.5rem"
        rowGap="0.5rem"
        // templateAreas={{base: 'aaa bbb ccc'}}
        // templateColumns="2fr 5fr 3fr"
        // templateRows="1fr"
        templateColumns={{
          base: '1fr',
          medium: '[start] 2fr [mainStart] 5fr',
          large: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xxl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
        }}
        // templateRows={{ base: 'repeat(4, 10rem)', large: 'repeat(3, 10rem)' }}
        gap={tokens.space.small}
      >
        <View
          columnSpan={{
            base: 'start/mainStart',
            medium: 'start/mainStart',
            large: 'start/mainStart',
          }}
        >
          {/* <ItemCardCollection */}
          <PageListItemCollection
            items={pages}
            overrides={{
              ItemCardCollection: {
                gap: '0',
              },
            }}
            overrideItems={({ item, index }) => ({
              // margin: '1rem',
              backgroundColor: index % 2 === 0 ? 'blue.10' : 'blue.20',
              // image: {
              //   src: 'https://fastly.picsum.photos/id/1049/200/300.jpg?hmac=IwgFpI60m03JoXBvIB_0oA12YR8cNaSel6lUvKhQvF0'
              // },

              onClick: () => {
                // ! try using
                // ! await Storage.get('filename.txt', { validateObjectExistence: true });
                // ! by running a function not a setSelected() here
                // ? maybe but probably note e.g. const flyToMordor = useCallback((latitude: number
                // setSelected({...item, image_url: 'https://fastly.picsum.photos/id/1049/200/300.jpg?hmac=IwgFpI60m03JoXBvIB_0oA12YR8cNaSel6lUvKhQvF0'})

                setSelected(item as Page);
                // updatedSelected(item);

                // setComments(item.comments);
              },
              // 'itemcard':
              // comments: (
              //   <div>
              //     {/* {JSON.stringify(item.comments)} */}
              //     {item.comments.map((comment: LazyComment) => (
              //       <Flex key={comment.id}>
              //         <div key={comment.id}>{comment.content}</div>
              //       </Flex>
              //     ))}
              //     comments
              //   </div>
              // ),
            })}
          />
        </View>

        {/* <hr /> */}
        {selected?.id ? (
          <Card>
            {/* <JamesComponentCollection
              items={[selected]}
              overrideItems={({ item, index }) => ({
                // backgroundColor: index % 2 === 0 ? 'blue.20' : 'blue.10',
                gap: '0.2rem',
                // overrides: {
                //   CommentCard: {
                //     width: '100%',
                //   },
                //   MyIcon29766875: {
                //     // color: 'red',
                //     backgroundColor: 'white',
                //   },
                // },
                frame424: (
                  // <div>
                  //   {item.comments.map((comment: LazyComment) => (
                  //     <Flex key={comment.id}>
                  //       <div key={comment.id}>{comment.content}</div>
                  //     </Flex>
                  //   ))}
                  // </div>
                  <CommentCardCollection
                    // items={comments}
                    items={item.comments}
                    overrides={{
                      CommentCardCollection: {
                        gap: '0',
                      },
                    }}
                    overrideItems={({ item, index }) => ({
                      backgroundColor:
                        index % 2 === 0 ? 'purple.10' : 'purple.20',
                      gap: '0.2rem',
                      overrides: {
                        CommentCard: {
                          width: '100%',
                        },
                        MyIcon29766875: {
                          // color: 'red',
                          backgroundColor: 'white',
                        },
                      },
                      onClick: () => {
                        console.log('Clicked - comment: ', item);
                      },
                    })}
                    searchNoResultsFound={
                      <Flex justifyContent="center" className="NoResults">
                        <Text color="purple.80" fontSize="1rem">
                          Nothing found, please try again
                        </Text>
                      </Flex>
                      // ''
                    }
                  />
                ),
              })}
            /> */}
            {/* <EditPage id={selected.id} /> */}

            {/* {selectedPrev && selected.id === selectedPrev.id ? ( */}
            {/* // * needed to remove undefined values and force a redraw */}
            <PageUpdateForm
              id={selected.id}
              // page={selected}
              // onChange={fields => console.log('onChange={fields => console.log(fields)}', fields)}
              // onChange={fields => setFields(fields.desc)}
              // onCancel={() => {
              //   setShowForm(false) // Hide the form
              // }}
              onError={(error) => {
                console.log('onError={(error)', error);
              }}
              onChange={(fields) => {
                // const updatedFields = {} as Page;
                // Object.keys(fields).forEach(key => {
                //   console.log('onSubmit={(fields) | fields: ', fields)
                //     if (typeof fields[key] === 'string') {
                //         updatedFields[key] = fields[key].trim()
                //     } else {
                //         updatedFields[key] = fields[key]
                //     }
                //     updatedFields[key] = fields[key];

                // })
                // setSelected(...selected, ...updatedFields)
                // console.log('onSubmit={(fields) | updatedFields: ', updatedFields)
                // return updatedFields
                // const aaa = selected;
                // selected[]
                setFields(fields);

                return fields;
              }}

              // overrides={{
              //   image_url: {
              //     value: selected.image_url || '',
              //   },
              //   title: {
              //     value: selected.title || '',
              //   },
              //   desc: {
              //     value: selected.desc || '',
              //   },
              // }}
            />
            {/* ) : (
              ''
            )} */}
            {/* {selected && selected.id ? <PageUpdateForm  /> : ''} */}
            {/* <JPageEdit
              id={selected.id}
              // overflow={'auto'}
              // width={'200px'}
              // maxWidth={'200px'}
              // overrides={{
              //   // title: { //! this creates a Chrome warning as its read only
              //   //   label: '200px', 
              //   // },
              // }}
            /> */}
          </Card>
        ) : (
          <div>No Record</div>
        )}
        {/* {selected ? (
          <Card>
            <JPageEdit
              id={selected.id}
              // width={'100%'}
              // maxWidth={'100%'}
            />
          </Card>
        ) : (
          <div>No Record</div>
        )} */}
      </Grid>
    </>
  );
};

export default NoteDetailPageC;
