import './App.scss';
import { Authenticator } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

import { RouterProvider } from 'react-router-dom';

import router from './routes';
import { StyleContext } from './context/StyleContext';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { studioTheme } from './ui-components';
import { WindowContextProvider } from './context/WindowContextProvider';

function App() {
  const [isLuxury, setIsLuxury] = useState(true);

  //& console.log(
  //&   '%c  App() | ================================================================ :',
  //&   'background-color: white; color: #FF5555',
  //& );

  return (
    <Authenticator.Provider>
      <WindowContextProvider>
        <StyleContext.Provider value={{ isLuxury, setIsLuxury }}>
          <RouterProvider router={router} />
        </StyleContext.Provider>
      </WindowContextProvider>
    </Authenticator.Provider>
  );
}

export default App;
