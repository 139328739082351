import {
  Card,
  Text,
  Grid,
  Heading,
  ThemeProvider,
  Theme,
  useTheme,
  View,
  Flex,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { DataStore, SortDirection, Storage } from 'aws-amplify';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';

// import ItemCardCollection from '../ui-components/ItemCardCollection';
// import SocialPage from '../ui-components/SocialPage';
import { LazyComment, LazyPage, Page } from '../../models';
import { PageListItemCollection, PageUpdateForm } from '../../ui-components';
import { PageUpdateFormInputValues } from '../../ui-components/PageUpdateForm';
// import {
//   CommentCard,
//   CommentCardCollection,
//   CommentCreateForm,
//   EditPost,
//   JPostEdit,
//   James2,
//   JamesComponent,
//   JamesComponentCollection,
//   PostUpdateForm,
// } from '../ui-components';

const NoteDetailPageD = () => {
  const { tokens } = useTheme();

  // const [selected, setSelected] = useState<Page>({
  //   title: 'x',
  //   desc: 'x',
  //   image_url: 'x',
  // } as Page);
  const [selected, setSelected] = useState<Page>();
  const [fields, setFields] = useState<any>();
  // const [selectedPrev, setSelectedPrev] = useState<LazyPage>();
  // const [selected, setSelected] = useState<Page>({} as Page);
  // const [comments, setComments] = useState<LazyComment[]>(); // * required as selected.comments
  // const [comments, setComments] = useState<Comment[]>(); // * required as selected.comments
  // * is not correctly mark as an array e.g. comments[].
  const [imageKeys, setImageKeys] = useState<S3ProviderListOutputItem[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [pages, setPages] = useState<LazyPage[]>();
  const [items, setItems] = React.useState(undefined);
  const [other, setOther] = useState<any>(undefined);
  const [formData, setFormData] = useState<PageUpdateFormInputValues>();

  // const updatedSelected = (slcted: Page) => {
  //   // const fieldNotNulls = ['title', 'desc', 'image_url']

  //   Object.keys(slcted).map((field) => {
  //     if (typeof slcted[field as keyof LazyPage] === 'string') {
  //       return slcted[field as keyof LazyPage] === undefined
  //         ? ''
  //         : slcted[field as keyof LazyPage];
  //     } else {
  //       return slcted[field as keyof LazyPage];
  //     }
  //   });

  //   setSelected(selected);
  // };
  // useEffect(() => {

  function pageSelected(slctd: any): Page {
    const { _deleted, _lastChangedAt, _version, ...page } = slctd;
    return page;
  }

  function updatedSelected(slcted: any) {
    const aaa = { ...slcted } as any;

    // const { _deleted, _lastChangedAt, _version, ...page } = slcted;

    Object.keys(aaa).forEach((field) => {
      if (slcted['image_url'] === null) {
        aaa['image_url'] = 'x';
      } else {
        aaa[field] = slcted[field];
      }
    });

    // const page = {...slcted};

;
    // if (page){
    //   setSelected(page as any);

    // }
    // setSelected(slcted);
    return aaa;
  }
  // });

  // useEffect(() => {
  //   // const aaa = {...selected};
  //   if (!selected) return;
  //   try {
  //     const aaa = {} as any;
  //     Object.entries(fields).forEach(([key, value]) => {
  //       if (typeof key === 'string') {
  //         if (typeof value === 'string' && value.trim() === '') {
  //           aaa[key] = value;
  //         }
  //       }
  //     });
  //     setSelected({ ...selected, ...aaa });
  //   } catch (err) {
  //     // if (onError) {
  //     //   onError(modelFields, err.message);
  //     // }
  //   }
  // }, [fields]);

  useEffect(() => {
    function setUndefinedToBlank(page: LazyPage) {
      // const checkFields = ['title', 'desc', 'image_url'];

      const pageNew = { ...page } as any;

      Object.entries(page).forEach(([key, value]) => {
        if (typeof value === 'object' && value === null) {
          // * i.e. undefined
          pageNew[key] = 'x';

        }
      });
      return pageNew as LazyPage;
    }

    async function setItemsFromDataStore(items: LazyPage[]) {
      var loaded = Promise.all(
        items.map(async (item) => {
          //  const itemNew = setUndefinedToBlank(item);

          return { ...item };
          // comments: await item.Comments.toArray(),
          // image_url: await Storage.get(item.image_url!, { level: 'private' }),
        })
      );
      // const loaded2 = loaded as any; // * Correct type
      console.log('loaded', loaded);
      return await loaded;
    }

    const subscription = DataStore.observeQuery(
      Page,
      (p) =>
        p.and((p) => {
          return [
            // p.title.beginsWith('title'),
            // p.rating.gt(10)
          ];
        }),
      {
        sort: (s) => s.title(SortDirection.DESCENDING),
      }
    )
      // .map((x) => {
      //   const itemsNew = x.items.map((item) => updatedSelected(item));

      //   // const items =

      //   const bbb = { ...x, items: itemsNew };

      //   return bbb;
      // })
      .subscribe(async (data) => {
        const { items, isSynced } = data;

;
        const items2 = items;
        // const items2 = await setItemsFromDataStore(items);
        // const items2 = await setItemsFromDataStore(itemsB);
        // ! convert to doc format

        // ! setSelected(items[selected.id])


        console.log('items2', items2);

        // setPages(data.items);
        setPages(items2);
        // if (selected === undefined) {
        //   setSelected(items2[0]);
        // }
        // setOther(items2);
        // setPage(data.items[2]);

        console.log(
          `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
        );
        console.log(`[Snapshot] Pages:`, data);
        console.log(`[Snapshot] Pages:`, data.items);
      });

    return () => subscription.unsubscribe();
  }, []);

  console.log(selected);
  // console.log(comments);

  const fetchImages = async () => {
    const { results } = await Storage.list('', {
      pageSize: 50,
      level: 'private',
    });
    console.log('await Storage.list( | results', results);

    setImageKeys(results);
    const s3Images = await Promise.all(
      results.map(
        async (image) => await Storage.get(image.key!, { level: 'private' })
      )
    );
    setImages(s3Images);
    console.log('s3Images', s3Images);
  };

  useEffect(() => {
    // fetchImages();
  }, []);

  return (
    <>
      <Grid
        columnGap="0.5rem"
        rowGap="0.5rem"
        // templateAreas={{base: 'aaa bbb ccc'}}
        // templateColumns="2fr 5fr 3fr"
        // templateRows="1fr"
        templateColumns={{
          base: '1fr',
          medium: '[start] 2fr [mainStart] 5fr',
          large: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
          xxl: '[start] 2fr [mainStart] 5fr [mainEnd] 3fr [end]',
        }}
        // templateRows={{ base: 'repeat(4, 10rem)', large: 'repeat(3, 10rem)' }}
        gap={tokens.space.small}
      >
        <View
          columnSpan={{
            base: 'start/mainStart',
            medium: 'start/mainStart',
            large: 'start/mainStart',
          }}
        >
          <PageListItemCollection
            items={pages}
            overrides={{
              ItemCardCollection: {
                gap: '0',
              },
            }}
            overrideItems={({ item, index }) => ({
              // margin: '1rem',
              backgroundColor: index % 2 === 0 ? 'blue.10' : 'blue.20',
              // image: {
              //   src: 'https://fastly.picsum.photos/id/1049/200/300.jpg?hmac=IwgFpI60m03JoXBvIB_0oA12YR8cNaSel6lUvKhQvF0'
              // },

              onClick: () => {
                // ! try using
                // ! await Storage.get('filename.txt', { validateObjectExistence: true });
                // ! by running a function not a setSelected() here
                // ? maybe but probably note e.g. const flyToMordor = useCallback((latitude: number

                // setSelected({...item, image_url: 'https://fastly.picsum.photos/id/1049/200/300.jpg?hmac=IwgFpI60m03JoXBvIB_0oA12YR8cNaSel6lUvKhQvF0'})

                const aaa = Page.copyOf(item, (updated) => {
                  updated.image_url = (item.image_url === null) ? '' : item.image_url;
                })

                // return item.copyOf(Post, (post) => {
                //   updated.postID = null;
                // })

                
                setSelected(aaa);

                // setSelected(pageSelected(item));
                // updatedSelected(item);
              },
            })}
          />
        </View>

        {/* <hr /> */}
        {selected ? (
          <Card>
            <PageUpdateForm
              // id={selected.id} // @ form does refresh with new values, but only way to save
              page={selected as any} // @ can't update DB with this but it updates the form
              // page={new Page({...selected})}
              onError={(error) => {
;
                console.log('onError={(error)', error);
              }}
              // onSubmit={(fields) => {
              //   // Example function to trim all string inputs
              //   const updatedFields = {} as any;
              //   Object.keys(fields).forEach((key) => {

              //     if (
              //       typeof fields[key as keyof PageUpdateFormInputValues] ===
              //       'string'
              //     ) {
              //       updatedFields[key] =
              //         fields[key as keyof PageUpdateFormInputValues]!.trim();
              //     } else {
              //       updatedFields[key] =
              //         fields[key as keyof PageUpdateFormInputValues];
              //     }
              //   });
              //   // return updatedFields as PageUpdateFormInputValues;
              //   return fields;
              // }}
              // onChange={(fields) => {

              //   setFields(fields);

              //   return fields;
              // }}
              onChange={(fields) => {
                setFormData(fields);
                return fields;
              }}
            />
          </Card>
        ) : (
          <div>No Record</div>
        )}
      </Grid>
    </>
  );
};

export default NoteDetailPageD;
