import { View } from '@aws-amplify/ui-react';
import { ContactShadows, GizmoHelper, GizmoViewport } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import { Suspense } from 'react';
// import { Perf } from 'r3f-perf'

import { useObserveGboxsFromGimageId } from '../../../hooks/useCrudGbox';
import {
  useCrudGltf,
  useObserveGltfsFromGimageId,
} from '../../../hooks/useCrudGltf';
import { useObserveGtext3DsFromGimageId } from '../../../hooks/useCrudGtext3D';
import ThreeGutsDisplay from '../widgets/ThreeGutsDisplay';
import {
  ControlEdit,
  ControlColorForm,
  ControlOrbit,
  ControlViewport,
  TextWithColorChanger,
  sceneState,
} from '../widgets/TransformCtrla4';
import { MovingText, Cube } from './MovingText';
import {
  convertGboxToDoc,
  convertGtext3DToDoc,
} from '../services/MeshServices';

export interface Props {
  editGimageId?: string;
}

export default function GimageOfficeBlock({ editGimageId }: Props) {
  const { useGetGltfsDisplayNodes } = useCrudGltf();
  //£ const { stubBoxs, stubGltfas, stubGtext3Ds } = useCrudStub();

  const gimageId = '2458e9c4-b8f7-41e1-8732-339082cb5bce'; // * Office

  const { gltfs } = useObserveGltfsFromGimageId(gimageId);
  const gltfas = useGetGltfsDisplayNodes(gltfs);
  const { gtext3Ds } = useObserveGtext3DsFromGimageId(gimageId);
  const { gboxs } = useObserveGboxsFromGimageId(gimageId);
  const gboxDoc = convertGboxToDoc(gboxs);
  const gtext3DDoc = convertGtext3DToDoc(gtext3Ds);

  //£ =========| Stubs |=============
  //£ const gboxs = stubBoxs() as Gbox[];
  //£ const gtext3Ds = stubGtext3Ds() as LazyGtext3D[];
  //£ const gltfas2 = stubGltfas() as unknown;
  //£ const gltfas = gltfas2 as Ggltfa[];

  // const gltfBurger = useGLTF('/glb/hamburger.glb') as GLTFA;
  // const materialBurger = gltfBurger.nodes['cheese'].material
  // if (gboxs === undefined && gtext3Ds === undefined && gltfas === undefined)
  //   return <p>No Boxes, Text or Images</p>;
  if (gboxs === undefined && gtext3Ds === undefined) return <></>;

  if (editGimageId && editGimageId !== gimageId)
    return <h1>GimageId missmatch between edit and display gimage</h1>;

  sceneState.gboxDoc = gboxDoc;
  sceneState.gtext3DDoc = gtext3DDoc;
  return (
    // <View width={'max-content'} height={'80vh'}>
    // <View width={'100%'} height={'80vh'}>
    <View width={'100%'} height={'100%'}>
      {/* <Canvas camera={{ position: [0, -10, 80], fov: 50 }} dpr={[1, 2]}> */}
      <Canvas camera={{ position: [50, 30, 70], fov: 30 }} dpr={[1, 2]}>
        {/* <pointLight position={[50, 100, 100]} intensity={1.8} /> */}
        {/* <Perf position="top-left" /> */}

        <directionalLight
          castShadow
          position={[1, 2, 3]}
          intensity={1.5}
          shadow-normalBias={0.04}
        />

        <hemisphereLight
          color="#FFFFFF"
          groundColor="#b9b9b9"
          position={[-7, 25, 13]}
          // intensity={0.95}
          intensity={1.5}
        />
        <Suspense fallback={null}>
          <group position={[0, 0, 0]} scale={8}>
            <ThreeGutsDisplay
              gboxs={gboxs}
              gtext3Ds={gtext3Ds}
              gltfas={gltfas}
            />
            <group position={[0.3, 1.5, 0.1]} scale={0.2}>
              <Cube />
            </group>

            <ContactShadows
              rotation-x={Math.PI / 2}
              position={[0, -35, 0]}
              opacity={0.25}
              width={200}
              height={200}
              blur={1}
              far={50}
            />
          </group>
        </Suspense>
        {/* <ControlEdit /> */}
        <ControlOrbit />
        <ControlViewport />
      </Canvas>
      {/* <View
        position={'fixed'}
        right={'18px'}
        top={'50px'}
        style={{ zIndex: 200 }}
      >
        {' '}
        <ControlColorForm />
      </View> */}
    </View>
  );
}
