import {
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Link,
  View,
} from '@aws-amplify/ui-react';
import React, { useContext, useEffect, useState } from 'react';
import rotateImagesIn from '../../services/animation/rotateImagesIn'; // RotateImgsOptions,
import {
  AnimateConfig,
  AnimateScroll,
} from '../../services/animation/animateScroll';
import { relative } from 'path';
import ChartThirty from '../../components/charts/premade/ChartThirty';
import { ContentDoc, intersect3data } from './intersect3data';
import { WindowContext } from '../../context/WindowContextProvider';
import UiuxAnimation from './UiuxAnimation';
import HeroLayout1 from '../../ui-components/HeroLayout1';
import TextAnimate from '../../ui-components/TextAnimate';
import HandHover from '../../ui-components/HandHover';
import AnimateFactory from '../../components/animate/AnimateFactory';

// import './Intersect3.scss';

export default function Intersect3() {
  const [contentDoc, setContentDoc] = useState<ContentDoc>(intersect3data());
  // const [contentDoc, setContentDoc] = useState<any>(intersect3data());
  const windowStats = useContext(WindowContext);
  const [widthPage, setWidthPage] = useState(1280);
  // const [widthPage, setWidthPage] = useState(920);

  // const [scaler, setScaler] = useState(1);
  const [cssScale, setCssScale] = useState(1);

  // useEffect(() => {
  //   // const widthPage = 600;
  //   // const widthPage = 992;
  //   if (clientWidth >= widthPage) {
  //     setScaler(1);
  //   } else {
  //     // const ratio = (widthPage - clientWidth) / widthPage;
  //     // const ratio = 1 - (1 - ((clientWidth / widthPage) *2));
  //     // const ratio = 1 - ((1 - (clientWidth / widthPage)) / 3);
  //     // const ratio = (clientWidth / widthPage) * 1.6;
  //     const ratio = clientWidth / widthPage;
  //     setScaler(ratio);
  //     setCssScale(1 - (1 - scaler) / 2);
  //   }
  // }, [clientWidth, widthPage]);

  // @ RotateUp
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.RotateUp');
    const config: AnimateConfig = {
      rootMargin: '850px 150px 100px 150px',
      animateStyle: 'RotateUp',
    };

    const Animate = new AnimateScroll(htmlElements, config);
    const subObserver = Animate.startObservable();

    return () => subObserver.disconnect();
  }, []);

  // @ SlideInFromLeft
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInFromLeft');
    const config: AnimateConfig = {
      rootMargin: '850px 150px 100px 150px',
      animateStyle: 'SlideInFromLeft',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ SlideInFromRight
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInFromRight');
    const config: AnimateConfig = {
      rootMargin: '850px 150px 100px 150px',
      animateStyle: 'SlideInFromRight',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ GrowFromSmall
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.GrowFromSmall');
    const config: AnimateConfig = {
      rootMargin: '10px',
      animateStyle: 'GrowFromSmall',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ Opacity
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInOpacityText');
    const config: AnimateConfig = {
      // rootMargin: '-180px 0px -430px 0px',
      rootMargin: `-${180 * windowStats.widthScaler}px 0px -${
        430 * windowStats.widthScaler
      }px 0px`,
      animateStyle: 'SlideInOpacity',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, [windowStats.widthScaler]);

  // @ Opacity
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInOpacityGraphic');
    const config: AnimateConfig = {
      rootMargin: '0px',
      animateStyle: 'SlideInOpacity',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ Opacity
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInDesignText');
    const config: AnimateConfig = {
      rootMargin: '-180px 0px -180px 0px',
      animateStyle: 'SlideInOpacity',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ Opacity
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.SlideInOpacityToggled');
    const config: AnimateConfig = {
      rootMargin: '-180px 0px -180px 0px',
      animateStyle: 'SlideInOpacityToggled',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ GrowFromSmall
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.GrowFromHand');
    const config: AnimateConfig = {
      rootMargin: '-250px',
      animateStyle: 'GrowFromSmall',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  // @ GrowFromSmall
  useEffect(() => {}, []);

  return (
    <>
      {/* <View>scaler: {windowStats.widthScaler}</View>
      <View>clientWidth: {windowStats.clientWidth}</View>
      <View>widthPage: {widthPage}</View>
      <View>scalerWidth: {widthPage * windowStats.widthScaler}</View>
      <View>scale: {1 - (1 - windowStats.widthScaler) / 1}</View>
      <View>cssScale: {cssScale}</View> */}

      {/* <View maxWidth={clientWidth}> */}
      <Flex
        // width={'100%'}
        // maxWidth={widthPage}
        // width={windowStats.clientWidth - 10}
        justifyContent="center"
        direction="column"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        // backgroundColor={'green'}
      >
        <Flex
          // width={'100%'}
          maxWidth={widthPage}
          width={windowStats.clientWidth - 10}
          justifyContent="center"
          direction="column"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          // backgroundColor={'green'}
        >
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            // alignContent="flex-start"
            alignContent="center"
            // style={{
            //   // transform: `scale(${scaler})`,
            //   transform: `scale(${cssScale})`,
            //   transformOrigin: 'top center',
            // }}
            gap={'0px'}
          >
            <Card>Engineer sites so scroll is not fast</Card>
            <Card>
              i.e. keep content concentrated to avoid user doing fast scrolls
            </Card>

            <View
              maxWidth={widthPage}
              className={'SlideInOpacityGraphic'}
              // backgroundColor={'blue'}
            >
              <UiuxAnimation />
            </View>

            <AnimateFactory
              contentIds={contentDoc.contentBIds}
              contents={contentDoc.contents}
            />

            <Flex
              transform={`scale(${windowStats.widthScaler})`}
              maxHeight={`${535 * windowStats.widthScaler}px`}
              height={`${535 * windowStats.widthScaler}px`}
            >
              <HandHover
                overrides={{
                  TextHover: {
                    children: 'captivating',
                    className: 'SlideInOpacityText',
                    fontFamily: 'default.variable',
                    color: 'neutral.80',
                    fontSize: '80px',
                  },
                  HandGive: {
                    className: 'RotateUp',
                    src: '/style/hand_give.png',
                  },
                }}
              />
            </Flex>
            <Flex
              transform={`scale(${windowStats.widthScaler})`}
              maxHeight={`${523 * windowStats.widthScaler}px`}
              height={`${523 * windowStats.widthScaler}px`}
            >
              <HandHover
                overrides={{
                  TextHover: {
                    children: 'captivating',
                    className: 'SlideInOpacityText',
                    fontFamily: 'default.variable',
                    color: 'neutral.80',
                    fontSize: '90px',
                  },
                  HandGive: {
                    className: 'RotateUp',
                    src: '/style/hand_give.png',
                  },
                }}
              />
            </Flex>

            <AnimateFactory
              contentIds={contentDoc.contentIds}
              contents={contentDoc.contents}
            />
            <View maxWidth={widthPage} className={'GrowFromSmall'}>
              {/* <video
              id="VideoPlayer"
              src={'/videos/screen_recordings/rearranging_charts_short.mp4'}
              // src={'/videos/screen_recordings/adjusting_menus_edge.mp4'}
              loop={true}
              playsInline={true}
              autoPlay={true}
              muted={true}
              width={'100%'}
            ></video> */}
            </View>
            <View
              backgroundColor={'#f5f5f7'}
              width={'100%'}
              // marginBottom={'100px'}
              paddingBottom={'100px'}
            >
              <View
                maxWidth={widthPage}
                width={'100%'}
                className={'SlideInOpacityGraphic'}
                // marginTop={'-120px'}
                marginTop={'-60px'}
                // marginTop={'-200px'}
              >
                <UiuxAnimation />
              </View>
            </View>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        // marginBottom="-rem"
        maxWidth={'100vw'}
        transform={{base: 'scale(0.6)', medium: 'scale(0.7)', large: 'scale(1)'}}

        >
        <TextAnimate
          padding={'80px'}
          marginTop="-4rem"
          transform={"scale(0.8)"}
          // transform={{base: 'scale(0.5)', large: 'scale(0.8)'}}

          overrides={{
            BackgroundRectangle: {
              // className: 'SlideInOpacity',
              className: 'SlideInDesignText boxShadow2 disc',
              backgroundColor: 'hsl(220, 85%, 85%, 0.5)',
            },
            BallsTop: {
              className: 'SlideInOpacityToggled',
            },
            BallsBottom: {
              className: 'SlideInOpacityToggled',
            },
            TextInBox: {
              children: 'Scientifically',
              fontFamily: 'default.variable',
              // className: 'SlideInOpacityText',
              className: 'insetText',
              // color: 'grey',
              backgroundColor: '#565656',
              color: 'transparent',
              // color: '#A6A6A6',
            },
          }}
        />
        <TextAnimate
          padding={'10px'}
          marginTop="-4rem"
          transform={'scale(0.7'}
          // transform={{base: 'scale(0.4)', large: 'scale(0.7)'}}
          overrides={{
            BackgroundRectangle: {
              // className: 'SlideInOpacity',
              className: 'SlideInDesignText boxShadow2 disc',
              backgroundColor: 'hsl(220, 85%, 85%, 0.5)',
            },
            BallsTop: {
              className: 'SlideInOpacityToggled',
            },
            BallsBottom: {
              className: 'SlideInOpacityToggled',
            },
            TextInBox: {
              children: 'Developed',
              fontFamily: 'default.variable',
              // className: 'SlideInOpacityText',
              className: 'insetText',
              // color: 'grey',
              backgroundColor: '#565656',
              color: 'transparent',
              // color: '#A6A6A6',
            },
          }}
        />
      </Flex>
      <View
        marginBottom={'15em'}
      > </View>

      {/* <View margin={'100px'}>
        <HeroLayout1
          overrides={{
            HeroMessage: {
              className: 'RotateUp',
            },
          }}
        />
      </View> */}
    </>
  );
}
