import { Button, Flex, View, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import ImageAnalysa from '../widgets/ImageAnalysa';
// import ImageDbDisplay from '../analyse/ImageDbDisplay';
import ImageDbDisplay2 from '../widgets/ImageDbDisplay2';
import GimageEnvironment from '../examples/GimageEnvironment';
import AddToThree from '../widgets/AddToThree';
import GimageOfficeBlock from '../examples/GimageOfficeBlock';
import GimageEnviro from '../examples/GimageEnviro';
import { useParams } from 'react-router-dom';
import { MeshTree } from '../../tree/MeshTree';

interface GimageDoc {
  gimageRefs: string[];
  gimages: {
    [ref: string]: {
      ref: string;
      gimageId: string;
    };
  };
}

const gimageDoc: GimageDoc = {
  gimageRefs: ['enviro', 'office' ],
  gimages: {
    office: {
      ref: 'office',
      gimageId: '2458e9c4-b8f7-41e1-8732-339082cb5bce',
    },
    enviro: {
      ref: 'enviro',
      gimageId: 'fa94344e-1ac3-4583-9513-ce579cfae039',
    },
  },
};

function ThreeEdit() {
  // const gimageId = '0a4a7dc7-8ef4-4ffd-b053-a46ee47f2e0e';
  // const gimageId = 'ba1d788e-761d-45a0-b399-22bbab9b5709';
  // const gimageId = '2458e9c4-b8f7-41e1-8732-339082cb5bce'; // * Office
  // const gimageId = 'fa94344e-1ac3-4583-9513-ce579cfae039'; // * Enviro
  // const [gimageId, setGimageId] = useState(
  //   // 'fa94344e-1ac3-4583-9513-ce579cfae039'
  //   '2458e9c4-b8f7-41e1-8732-339082cb5bce'
  // );

  const [gimageRef, setGimageRef] = useState<string>('office');
  const { id } = useParams<string>();

  useEffect(() => {
    if (id && gimageDoc.gimageRefs.includes(id)) {
      setGimageRef(id);
    }
  }, [id]);

  return (
    <>
      <View>
        {/* 
        //? Adds (think keep)
        <View position={'fixed'} style={{ zIndex: 100 }}>
          <View>
            <Flex direction="column" gap="0rem">
              <ImageDbDisplay2
                gimageId={gimageDoc.gimages[gimageRef].gimageId}
              />
              <AddToThree gimageId={gimageDoc.gimages[gimageRef].gimageId} />
            </Flex>
          </View>
        </View> */}
        {/* 
          //? Adds sub menu
        <Flex
          position={'fixed'}
          // right={'230px'}
          style={{ zIndex: 100, gap: 0 }}
        >
          {gimageDoc.gimageRefs.map((ref, iRef) => (
            <View
              key={iRef}
              fontSize={'medium'}
              fontFamily={'var(--leva-fonts-mono)'}
              width="80px"
              backgroundColor="black"
              gap={0}
              border="1px solid var(--amplify-colors-black)"
              onClick={() => setGimageRef(ref)}
              margin={'small'}
              >
              <Text  color={'white'} textAlign={'center'}>{ref}</Text>
            </View>
          ))}
        </Flex> */}

        <View width={'100vw'} height={'96vh'}>
          {displayGimage(gimageRef)}
        </View>
      </View>
    </>
  );

  function displayGimage(gimageRef: string): any {
    switch (gimageRef) {
      case 'enviro':
        return (
          <GimageEnviro editGimageId={gimageDoc.gimages[gimageRef].gimageId} />
        );
      case 'office':
        return (
          <GimageOfficeBlock
            editGimageId={gimageDoc.gimages[gimageRef].gimageId}
          />
        );
      default:
        return <div>some text</div>;
    }
  }
}

export default ThreeEdit;
