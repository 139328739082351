import { Flex, Heading, View, Image } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { Content, ContentDoc, intersect3data } from '../../pages/others/intersect3data';

interface Props {
    contentIds: string[];
    contents: { [id: string]: Content }
  }

function AnimateFactory({contentIds, contents }: Props) {
  const [contentDoc, setContentDoc] = useState<ContentDoc>(intersect3data());


  return (
    <>
      {contentIds.map((contentId, index) => {
        return (
          <Flex
            key={index}
            backgroundColor={contents[contentId].backgroundColor}
            direction="column"
            alignItems="center"
            gap={0}
            width={'100%'}
            // rowGap={0.00001}
            rowGap={'0'}
          >
            {contents[contentId]?.title && (
              <Heading
                // fontSize={{ 110}}
                fontSize={{ base: '70px', medium: '90px', large: '110px' }}
                textAlign={'center'}
                fontWeight={600}
                className="GrowFromSmall"
              >
                {contents[contentId]?.title}
              </Heading>
            )}

            {contents[contentId]?.desc ? (
              // <Heading fontSize={70} textAlign={'center'} className='GrowFromHand'>
              <Heading
                fontSize={70}
                // fontSize={110}
                textAlign={'center'}
                className="SlideInOpacityText"
                // marginTop={-435}
                // marginLeft={280}
                // position={'scale(0.5)'}
                // color={`hsl(170, 50%, 50%)`}
                // color={`hsl(${window.scrollY}, 50%, 50%)`}
                // color={`hsl(123, 50%, 50%)`}
                color={`neutral.80`}
                // color={'green.40'}
                // color={'blue.40'}
                style={{ zIndex: 100 }}

                // window.pageYOffset
              >
                {contents[contentId].desc}
              </Heading>
            ) : (
              ''
            )}
            {/* {contents[contentId]?.img?.marginLeft} */}
            <View
              marginLeft={contents[contentId]?.img?.marginLeft}
              marginTop={contents[contentId]?.img?.marginTop}
            >
              {contents[contentId]?.src && (
                <Image
                  alt="iPhone"
                  src={contents[contentId].src}
                  // objectFit="initial"
                  // objectPosition="50% 50%"
                  // backgroundColor="initial"
                  // maxHeight="80vh"
                  // width="90%"
                  // width={widthPage - 100}
                  // maxHeight="800px"
                  maxWidth={'90vw'}
                  maxHeight={
                    contents[contentId]?.img?.maxHeight
                      ? contents[contentId]?.img?.maxHeight
                      : '80vh'
                  }
                  // height={800 * scaler + 'px'}
                  margin={6}
                  className={`${contents[contentId].classes} hndimg`}
                  // style={'z-index: 10'}
                  // style={{color: 'red'}}
                />
              )}
            </View>

            {/* <View
              width={650}
              height={400}
              transform={'scale(0.75)'}
              className={'SlideInOpacityText'}
            >
              <ChartThirty />
            </View> */}

            {/* <div className="vertical">
                  Making content look <strong>stimulating {index}</strong>
                  From UI designs to delivery Add a scroll e.g.
                  https://www.apple.com/uk/home-app/
                </div> */}
          </Flex>
        );
      })}
    </>
  );
}

export default AnimateFactory;
