import { DataStore } from 'aws-amplify';
import { useEffect, useId, useState } from 'react';
import { LazyGscene, Gscene } from '../models';
// import { XyzTuple } from '../interfaces/DbGltfa';
// import { GsceneDoc } from '../interfaces/GsceneDoc';
import { setTuple } from './CrudTools';

// ! correct the code based off Gtext3D to observe on Gscene
// ! correct the code based off Gtext3D to observe on Gscene
// ! correct the code based off Gtext3D to observe on Gscene
// ! correct the code based off Gtext3D to observe on Gscene

// ! remember to look at the way treeStub deals with Recursion

export function useObserveGscenesFromGimageId(gimageId: string) {
  const [gscenes, setGscenes] = useState<LazyGscene[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sub = DataStore.observeQuery(Gscene, (g) =>
      g.gimageID.eq(gimageId)
    ).subscribe(({ items }) => {
      setLoading(false);
      setGscenes(items);
      console.log(
        '%c useCruidGscene | useObserveGsceneData(gsceneId: string) | items : ',
        'background-color: #885555; color: aquamarine',
        items
      );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [gimageId]);

  return { gscenes, loading };
}

export const useCrudGscene = () => {
  // const nameId = useId()

  const createGscene = (gimageId: string) => {
    async function createRecord() {
      return await DataStore.save(
        new Gscene({
          gimageID: gimageId,
          gname: `${gimageId}-${Math.random().toString(36).slice(2)}`,
          desc: 'helvetiker_bold.typeface.json',
          meshTree: '{}',
        })
      );
    }
    return createRecord();
  };

  const updateGsceneFromName = async (
    gscene: Gscene,
    object: THREE.Object3D | undefined
  ) => {

    if (gscene.gname === undefined) {
      return;
    }

    if (object === undefined) {
      return;
    }

    const originals = await DataStore.query(Gscene, (c) =>
      c.and((c) => [c.gname.eq(gscene.gname)])
    );

    const original = originals[0];

    console.log(
      '%c useCrudGscene | updateGsceneFromName | original :',
      'background-color: #BB5555; color: white',
      original
    );

    if (original) {
      const updatedGscene = await DataStore.save(
        Gscene.copyOf(original, (updated) => {
          updated.gname = gscene.gname;
          updated.desc = gscene.desc;
          updated.meshTree = gscene.meshTree;
        })
      );
      return updatedGscene;
    }
  };

  return {
    createGscene,
    updateGsceneFromName,
  };
};
