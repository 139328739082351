// components/Protected.js
import { useAuthenticator, Heading, View, Flex } from '@aws-amplify/ui-react';
import { Outlet } from 'react-router-dom';
import { MenaComp } from '../../MenaComp';
import { MenaList } from '../../MenaData';
import { Canvas } from '@react-three/fiber';
import Experience from '../Experience';
import TransformTwo from './TransformTwo';
import TransformCtrla2 from './TransformCtrla2';
import PrivotContrl from '../PrivotContrl';
import { PostCreateForm } from '../../../ui-components';

export function Exampla3() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';
  return (
    <>
      <Heading level={1}>{message}</Heading>
      {/* <Outlet /> */}
        <MenaComp menaList={MenaList.exampla3Ids} />

        <Flex>
        <View width={'40vw'} height={'40vh'}>
          <Canvas
            shadows
            camera={{
              fov: 45,
              near: 0.1,
              far: 200,
              position: [-4, 3, 6],
            }}
          >
            <Experience />
          </Canvas>
        </View>
        {/* <PostCardCollection /> */}
        {/* <PageCreateForm/> */}
        {/* <CommentCreateForm/> */}
        <View width={'47vw'} height={'80vh'}>
          <PrivotContrl/>
        </View>
        <View width={'47vw'} height={'80vh'}>
          <TransformTwo />
        </View>
      </Flex>
    </>
  );
}
