import React from 'react';

import { GenerateCharta } from '../../../services/charta/guts/interfaces/generate-chart';
import Chartauta from '../creation/Chartauta';
import { GenericToolsService } from '../../../services/charta/guts/general/generic-tools.service';

export default function ChartTicking() {

  const oldPrice = new GenericToolsService().getRandomInt(330, 355);

  // const genCharta: GenerateCharta  = {
  //   chrtType: 'Series30seconds',
  //   // chrtType: 'Series30days',
  //   // chrtType: 'SURFACE',
  //   generateParams:{
  //     volatility: 0.002,
  //     oldPrice: oldPrice,
  //     count: 100,
  //     originalPrice: oldPrice,
  //     intervalTime: 5000,
  //   }
  // }

  const genCharta: GenerateCharta  = {
    // chrtType: 'Series30days',
    chrtType: 'Series30seconds',
    generateParams: {
      volatility: 0.002,
      oldPrice: oldPrice,
      count: 30,
      // count: 300,
      // count: 900,
      originalPrice: oldPrice,
      // intervalTime: 1750,
      intervalTime: 1350,
      // intervalTime: 13500000,
      // intervalTime: 86400000, // * 1 day
    }
  }

  

  return (
    <Chartauta genCharta={genCharta}/>
  )
}
