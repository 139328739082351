// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Gscene, Gbox, Gtext3D, Gimage, Post, Gltf, Scene, Comment, Page } = initSchema(schema);

export {
  Gscene,
  Gbox,
  Gtext3D,
  Gimage,
  Post,
  Gltf,
  Scene,
  Comment,
  Page
};