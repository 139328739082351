import { GenerateMarketFigures } from '../interfaces/generate-chart';
import { ChartaDataService } from '../mock/charta-data.service';
import { VariablesService } from '../general/variables.service';
import { Plotlya } from '../interfaces/plotlya.model';


interface ChartStats {
  valueMax: number,
  valueMin: number,
  timeFirst: string,
  timeLast: string,
  tracesStats: {
    [id: string]: TraceStats
  },
};

interface TraceStats {
  traceLastX: string;
  traceSecondLastX: string;
  traceLastY: number;
  traceSecondLastY: number;
}

interface Trace {
  type: string,
  mode: string,
  name: string,
  x: string[],
  y: number[],
  line: { color: string }
  fill?: string,
  fillcolor?: string,
}

interface TraceColors {
  [id: number]: string
}

export class PlotlyaGutsService {

  tickerParams?: GenerateMarketFigures;

  constructor(
    private sVariables: VariablesService,
    // private sCharta: PlotlyService,
    private sChartaData: ChartaDataService,
  ) { }

  traceColors: TraceColors = {
    // 0: this.sVariables.colors()['colorAmber'],
    // 0: '##ff800a',
    1: '#ffffff',
    // 1: '#ff550a',
    // 1: this.sVariables.colors()['colorOrange'],
    2: this.sVariables.colors()['colorBlue'],
    // 2: this.sVariables.colors()['colorGreen'],
    // 2: this.sVariables.colors()['colorAmber'],
    // 2: this.sVariables.colors()['colorGrey'],
    // 2: '#adadad',
    // 2: '#8d8d8d',
    // 2: '#7d7d7d',
    // 2: '#6d6d6d',
    // 2: '#FFF',
  }

  surfaceChart(): Plotlya.Inputs {

    const dataRaw = this.sChartaData.surfaceData5();

    const data = [
      {
        z: dataRaw,
        type: 'surface',
        showscale: false,
      }
    ];

    const layout = {
      // title: 'PORTFOLIO: ADD16A, TIME, DAY PRICE FLUCTUATIONS YTD',
      // paper_bgcolor: '#1E1E1E',
      paper_bgcolor: this.sVariables.colors()['colorBackground'],
      titlefont: {
        color: '#FFFFFF'
      },
      plot_bgcolor: '#1E1E1E',
      showlegend: false,
      autosize: true,
      // height: 800,
      // width: 900,
      // width: 800,
      // height: 550,
      // margin: {
      //   l: 65,
      //   r: 50,
      //   b: 65,
      //   t: 70
      // },
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      // padding: {
      //   l: 0,
      //   r: 0,
      //   b: 30,
      //   t: 0,
      // },
      scene: {
        zaxis: {
          // by clicking the button: Produce with Plotly
          //  ..it will auto open the chart at:  https://plot.ly/create/
          // Click on JSON option and you will see the chart object and data.
          // Adjust the object such as below, to configure the chart.
          title: 'price change',
          color: '#FFFFFF'
        },
        xaxis: {
          title: 'time',
          color: '#FFFFFF'
        },
        yaxis: {
          title: 'day of month',
          color: '#FFFFFF'
        }
      }
    };

    const config = {
      responsive: true,
      // 'displaylogo': false
      // displayModeBar: false,
      displayModeBar: false,
     }


    const plotlya: Plotlya.Inputs = {
      data: data,
      layout: layout as Plotly.Layout,
      config: config as Plotly.Config,
      updateOnDataChange: false,
    }

    return plotlya;
  }

  timeSeries(dataRaw: string[][]) {

    const trace1: Trace = {
      type: "scatter",
      mode: "lines",
      name: 'High',
      // x: unpack(rows, 'Date'),
      // x: this.timeSeriesData().map(x => x[0]),
      x: dataRaw.map(x => x[0]),
      y: dataRaw.map(x => Number(x[2])),
      line: { color: this.traceColors[1] },
    }

    const trace2: Trace = {
      type: "scatter",
      mode: "lines",
      name: 'Low',
      x: dataRaw.map(x => x[0]),
      y: dataRaw.map(x => Number(x[3])),
      line: { color: this.traceColors[2] },
      fill: 'tonexty',
      // fillcolor: 'rgba(1, 250, 255, 0.1)'
      // fillcolor: 'rgba(250, 250, 255, 0.1)'
      // fillcolor: 'rgba(255, 255, 255, 0.07)'
      fillcolor: 'rgba(255, 255, 255, 0.09)'
    }

    const stats: ChartStats = {
      valueMax: Math.max(...trace1.y, ...trace2.y),
      valueMin: Math.min(...trace1.y, ...trace2.y),
      timeFirst: trace1.x[0],
      timeLast: trace1.x[trace1.x.length - 1],
      tracesStats: {
        trace1: {
          traceLastX: trace1.x[trace1.x.length - 1],
          traceSecondLastX: trace1.x[trace1.x.length - 2],
          traceLastY: trace1.y[trace1.y.length - 1],
          traceSecondLastY: trace1.y[trace1.y.length - 2],
        },
        trace2: {
          traceLastX: trace2.x[trace2.x.length - 1],
          traceSecondLastX: trace2.x[trace2.x.length - 2],
          traceLastY: trace2.y[trace2.y.length - 1],
          traceSecondLastY: trace2.y[trace2.y.length - 2],
        }
      }
    };

    const data = [trace1, trace2];

    // const trace1YLessLast = data[1].trace2.y.slice(0, trace1.y.length - 2 );
    const trace2YLessLast = Math.min(...data[1].y.slice(0, data[1].y.length - 2));
    const trace2YLast = stats.tracesStats['trace2'].traceLastY;
    const colorBckgrndNegative = (trace2YLast < trace2YLessLast) ? true : false;


    // if ((Date.parse(stats.timeLast) - Date.parse(stats.timeFirst)) > ){



    // console.log({
    //   trace2YLessLast: trace2YLessLast,
    //   trace2YLast: trace2YLast,
    //   colorBckgrndNegative: colorBckgrndNegative,
    // })




    let layout: any = {
      // title: 'Time Series with Rangeslider',
      title: '',
      xaxis: {
        autorange: true,
        // range: ['2015-02-17', '2017-02-16'],
        // rangeselector: {
        //   buttons: [
        //     {
        //       count: 1,
        //       label: '1m',
        //       step: 'month',
        //       stepmode: 'backward'
        //     },
        //     {
        //       count: 6,
        //       label: '6m',
        //       step: 'month',
        //       stepmode: 'backward'
        //     },
        //     { step: 'all' }
        //   ]
        // },
        // rangeslider: { range: [stats.timeFirst, stats.timeLast] },
        type: 'date',
        color: '#FFFFFF',
        showspikes: true, spikecolor: "green", spikesnap: "cursor", spikemode: "across"


      },
      margin: {
        // t: 80,
        // r: 80,
        // b: 40,
        // l: 20
        t: 20,
        r: 80,
        b: 40,
        l: 10
      },
      hovermode: "x",
      showlegend: false,

      yaxis: {
        autorange: true,
        // range: [86.8700008333, 138.870004167],
        type: 'linear',
        color: '#FFFFFF',
        side: 'right',
        showspikes: true, spikecolor: "green", spikesnap: "cursor", spikemode: "across"
      },
      // paper_bgcolor: '#1E1E1E',
      // paper_bgcolor: '#f33',
      // plot_bgcolor: '#1E1E1E',
      paper_bgcolor: this.sVariables.colors()['colorBackground'],
      // plot_bgcolor: (colorBckgrndNegative) ? '#a00101' : this.sVariables.colors()['colorBackground'],
      plot_bgcolor: this.sVariables.colors()['colorBackground'],
      annotations: this.annotations(stats, data),
    };

    if (this.tickerParams) {
      if (this.tickerParams?.intervalTime > 60000) {
        // * rangeSlider
        // SaveCode layout['xaxis']['rangeslider'] = { range: [stats.timeFirst, stats.timeLast] }
        //& console.log('%c PlotlyaGutsService |  timeSeries(dataRaw: string[][]) | stats:', 'background-color: #4477BB; color: white', stats)
      }
    }

    const config = {
      responsive: true,
      'displaylogo': false,
      displayModeBar: false,

     }


    const plotlya: Plotlya.Inputs = {
      data: data,
      layout: layout as Plotly.Layout,
      config: config as Plotly.Config,
      updateOnDataChange: true,
    }
    return plotlya;
  }


  annotations(stats: ChartStats, data: Trace[]) {

    const annotations = [] as {}[];
    Object.keys(stats.tracesStats).forEach((traceId, iTrace) => {
      annotations.push(
        this.annotation(stats, data, traceId, iTrace + 1)
      )
    });

    return annotations;

  }


  annotation(stats: ChartStats, data: Trace[], traceId: string, iTrace: number) {

    // const timeStamp = Date.parse(stats.tracesStats[traceId].traceLastX);
    const timeStampFirst = Date.parse(stats.timeFirst);
    const timeStampLast = Date.parse(stats.timeLast);
    // const timeOffset = (timeStampLast - timeStampFirst) * 0.05

    // const yOffset = (iTrace === 1) ? 0.045 : -0.045; // * makes annotion come out of bottom left or bottom right.
    // const yOffset = (iTrace === 1) ? 0.04 : -0.04; // * makes annotion come out of bottom left or bottom right.

    const colorNegative = (stats.tracesStats[traceId].traceLastY < stats.tracesStats[traceId].traceSecondLastY) ? true : false;

    const trace2YLessLast = Math.min(...data[iTrace - 1].y.slice(0, data[iTrace - 1].y.length - 1));
    const trace2YLast = stats.tracesStats[traceId].traceLastY;
    const colorBckgrndNegative = (trace2YLast < trace2YLessLast) ? true : false;


    const colorNegative2 = data

    let color = (iTrace === 1)? '#000000' : '#FFFFFF'
    if (colorBckgrndNegative){
      color = '#ffffff';
    }

    return {
      // x: timeStampLast + 8200,
      // x: timeStampLast + timeOffset,
      // y: stats.tracesStats[traceId].traceLastY + (stats.valueMax - stats.valueMin) * yOffset,
      y: stats.tracesStats[traceId].traceLastY,
      // xref: 'x',
      // yref: 'y',
      // text: `${stats.tracesStats[traceId].traceLastY.toFixed(2)} ${data[iTrace -1].name}`,
      text: stats.tracesStats[traceId].traceLastY.toFixed(2),
      showarrow: false,
      font: {
        family: 'Courier New, monospace',
        size: 16,
        // color: '#ffffff'
        color: color,
        // color: (colorNegative) ? "#a00101" : '#ffffff' ,
        // weight: '900',
      },
      align: 'right',
      // ax: 60,
      // ay: -30,
      // ay: 30,
      // ay: 130,
      // xanchor: 'right',
      // xshift: 100,
      ax: 100,
      ay: 100,
      borderpad: 4,
      // bgcolor: (colorBckgrndNegative)? '#a00101' : this.traceColors[iTrace],
      bgcolor: (colorBckgrndNegative) ? this.sVariables.colors()['colorRed'] : this.traceColors[iTrace],
      // bgcolor: (stats.valuePrevMin),
      // bgcolor: (colorNegative) ? this.sVariables.colors()['colorAlert']: this.traceColors[iTrace],

      captureevents: false,
      xref: 'paper',
      x: 1,
      xanchor: 'left',
      xshift: -1,
      yref: 'y',
      // y: chartInfo.yValuesHighLast,
      yanchor: 'auto',
      yshift: (iTrace === 1) ? 13 : -13,
      opacity: 1,
      visible: true,
      clicktoshow: false,
      // bordercolor: (colorNegative) ? "#ff7f0e" : this.traceColors[iTrace],
      // bordercolor: (colorNegative) ? "#ff4040" : this.traceColors[iTrace],
      // bordercolor: (colorNegative) ? "#a00101" : this.traceColors[iTrace],
      // bordercolor: this.traceColors[iTrace],
      // bordercolor: (colorNegative) ? this.sVariables.colors()['colorAlert']: this.traceColors[iTrace],
      borderwidth: 1,
      textangle: 0,
    }
  }


  timeSeriesData() {

    return [
      [
        "2023-04-28 08:08:09",
        "",
        "332.61",
        "330.28",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:14",
        "",
        "332.79",
        "331.12",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:19",
        "",
        "332.39",
        "330.06",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:24",
        "",
        "332.31",
        "329.97",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:29",
        "",
        "332.89",
        "330.56",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:34",
        "",
        "332.53",
        "330.20",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:39",
        "",
        "332.79",
        "331.12",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:44",
        "",
        "333.28",
        "330.95",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:49",
        "",
        "333.86",
        "332.19",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:54",
        "",
        "333.72",
        "332.04",
        "",
        ""
      ],
      [
        "2023-04-28 08:08:59",
        "",
        "333.69",
        "332.02",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:04",
        "",
        "334.11",
        "332.43",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:09",
        "",
        "333.49",
        "331.15",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:14",
        "",
        "333.81",
        "332.14",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:19",
        "",
        "334.20",
        "331.86",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:24",
        "",
        "334.37",
        "332.02",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:29",
        "",
        "334.47",
        "332.12",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:34",
        "",
        "334.64",
        "332.30",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:39",
        "",
        "334.08",
        "332.40",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:44",
        "",
        "333.58",
        "331.24",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:49",
        "",
        "333.99",
        "332.32",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:54",
        "",
        "334.25",
        "332.58",
        "",
        ""
      ],
      [
        "2023-04-28 08:09:59",
        "",
        "333.78",
        "332.11",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:04",
        "",
        "334.44",
        "332.76",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:09",
        "",
        "334.95",
        "332.60",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:14",
        "",
        "335.44",
        "333.09",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:19",
        "",
        "335.76",
        "334.08",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:24",
        "",
        "335.11",
        "332.76",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:29",
        "",
        "334.71",
        "333.03",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:34",
        "",
        "335.14",
        "332.79",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:39",
        "",
        "335.40",
        "333.72",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:44",
        "",
        "335.77",
        "333.42",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:49",
        "",
        "335.33",
        "332.98",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:54",
        "",
        "335.50",
        "333.82",
        "",
        ""
      ],
      [
        "2023-04-28 08:10:59",
        "",
        "335.84",
        "334.16",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:04",
        "",
        "336.37",
        "334.01",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:09",
        "",
        "335.71",
        "333.36",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:14",
        "",
        "335.83",
        "334.14",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:19",
        "",
        "336.31",
        "333.95",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:24",
        "",
        "336.95",
        "334.59",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:29",
        "",
        "336.53",
        "334.85",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:34",
        "",
        "337.20",
        "335.51",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:39",
        "",
        "336.54",
        "334.85",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:44",
        "",
        "336.40",
        "334.04",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:49",
        "",
        "337.00",
        "334.64",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:54",
        "",
        "337.31",
        "335.62",
        "",
        ""
      ],
      [
        "2023-04-28 08:11:59",
        "",
        "336.79",
        "335.10",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:04",
        "",
        "336.34",
        "333.98",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:09",
        "",
        "335.87",
        "333.52",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:14",
        "",
        "335.57",
        "333.22",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:19",
        "",
        "335.36",
        "333.68",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:24",
        "",
        "335.27",
        "332.92",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:29",
        "",
        "334.66",
        "332.31",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:34",
        "",
        "334.81",
        "332.46",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:39",
        "",
        "334.74",
        "333.06",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:44",
        "",
        "335.15",
        "333.47",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:49",
        "",
        "334.87",
        "333.19",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:54",
        "",
        "334.61",
        "332.26",
        "",
        ""
      ],
      [
        "2023-04-28 08:12:59",
        "",
        "334.74",
        "333.06",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:04",
        "",
        "334.42",
        "332.08",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:09",
        "",
        "334.75",
        "332.40",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:14",
        "",
        "334.14",
        "332.46",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:19",
        "",
        "334.19",
        "332.51",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:24",
        "",
        "333.96",
        "331.61",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:29",
        "",
        "334.29",
        "332.61",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:34",
        "",
        "333.85",
        "331.51",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:39",
        "",
        "334.40",
        "332.05",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:44",
        "",
        "334.00",
        "332.33",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:49",
        "",
        "333.82",
        "331.48",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:54",
        "",
        "333.80",
        "332.13",
        "",
        ""
      ],
      [
        "2023-04-28 08:13:59",
        "",
        "333.17",
        "331.50",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:04",
        "",
        "333.35",
        "331.01",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:09",
        "",
        "333.30",
        "330.97",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:14",
        "",
        "333.96",
        "331.62",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:19",
        "",
        "334.42",
        "332.08",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:24",
        "",
        "335.08",
        "332.72",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:29",
        "",
        "335.74",
        "333.39",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:34",
        "",
        "335.36",
        "333.67",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:39",
        "",
        "335.81",
        "334.13",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:44",
        "",
        "336.48",
        "334.12",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:49",
        "",
        "336.22",
        "334.54",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:54",
        "",
        "335.76",
        "334.07",
        "",
        ""
      ],
      [
        "2023-04-28 08:14:59",
        "",
        "335.14",
        "332.79",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:04",
        "",
        "334.85",
        "333.17",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:09",
        "",
        "335.11",
        "332.76",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:14",
        "",
        "335.17",
        "333.49",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:17",
        "",
        "335.17",
        "333.49",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:21",
        "",
        "335.54",
        "333.19",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:25",
        "",
        "335.52",
        "333.17",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:28",
        "",
        "335.60",
        "333.25",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:32",
        "",
        "335.24",
        "332.89",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:36",
        "",
        "334.80",
        "332.45",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:39",
        "",
        "334.61",
        "332.26",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:43",
        "",
        "334.88",
        "333.20",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:46",
        "",
        "334.33",
        "332.66",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:50",
        "",
        "334.76",
        "333.08",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:54",
        "",
        "334.69",
        "333.01",
        "",
        ""
      ],
      [
        "2023-04-28 08:15:57",
        "",
        "334.06",
        "331.71",
        "",
        ""
      ],
      [
        "2023-04-28 08:16:01",
        "",
        "334.02",
        "331.68",
        "",
        ""
      ],
      [
        "2023-04-28 08:16:05",
        "",
        "334.48",
        "332.13",
        "",
        ""
      ]
    ]


  }




}
