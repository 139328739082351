// import { Injectable, OnInit } from '@angular/core';
// import { PlotlyaGutsService } from './plotlya-guts.service';
// import { Plotlya } from 'src/app/models/plotlya.model';

import { PlotlyaGutsService } from "./plotlya-guts.service";
import { Plotlya } from "../interfaces/plotlya.model"


export class PlotlyaService extends PlotlyaGutsService {

  surfaceChartSpikeLines(): Plotlya.Inputs {
    const plotlya = this.surfaceChart();

    // plotlya

    return plotlya;

  }



}
