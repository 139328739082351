import { Button } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

// interface Charta {
//   data: Plotly.Data[];
// }

const ChartPageB = () => {
  //   const [pyData, setPyData] = useState<Plotly.Data[]>();
  const [dataY, setDataY] = useState<number[]>([2, 5, 3]);
  const [imgName, setImgName] = useState<string>('image');

    function updateData(name: string): void {

        // * Adjust data
        const dataYnew = [...dataY];
        dataYnew[0] = dataYnew[0] + 1;
        setDataY(dataYnew);

        // * Adjust Name
        // const dataYnew = [...dataY];
        // dataYnew[0] = dataYnew[0] + 1;
        setImgName(`image` + dataYnew[0]);



        // throw new Error('Function not implemented.');
    }
    var traces = [
        {x: [1,2,3], y: dataY, stackgroup: 'one', name: 'image 3' },
        {x: [1,2,3], y: [1,1,2], stackgroup: 'one', name: imgName},
        {x: [1,2,3], y: [3,0,2], stackgroup: 'one', name: 'image 1'}
    ];
    
  return (
    <>
      <div>ChartPageB</div>

      <Button loadingText="" onMouseOver={(e) => updateData('first')} ariaLabel="">
        Click me! {dataY}
      </Button>



      <Plot
        data={traces}
        layout={{ width: 620, height: 540, title: 'App Interaction Analysis' }}
      />
    </>
  );
};

export default ChartPageB;
