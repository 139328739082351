/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function TextAnimate(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="1054px"
      height="413px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      borderRadius="2px"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "TextAnimate")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="row"
        width="932px"
        height="150px"
        justifyContent="space-between"
        alignItems="flex-start"
        overflow="hidden"
        position="absolute"
        top="222px"
        left="61px"
        border="1px SOLID rgba(0,0,0,1)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "BallsBottom")}
      >
        <View
          width="150px"
          height="150px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="125px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,0.1)"
          {...getOverrideProps(overrides, "Rectangle 11643954561")}
        ></View>
        <View
          width="150px"
          height="150px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="125px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,0.1)"
          {...getOverrideProps(overrides, "Rectangle 11663954563")}
        ></View>
      </Flex>
      <Flex
        gap="16px"
        direction="row"
        width="932px"
        height="150px"
        justifyContent="space-between"
        alignItems="flex-start"
        overflow="hidden"
        position="absolute"
        top="72px"
        left="61px"
        border="1px SOLID rgba(0,0,0,1)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "BallsTop")}
      >
        <View
          width="150px"
          height="150px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="125px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,0.1)"
          {...getOverrideProps(overrides, "Rectangle 11643954556")}
        ></View>
        <View
          width="150px"
          height="150px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="135.5px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(217,217,217,0.1)"
          {...getOverrideProps(overrides, "Rectangle 11663954558")}
        ></View>
      </Flex>
      <View
        width="932px"
        height="300px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="calc(50% - 150px - -15.5px)"
        left="calc(50% - 466px - 0px)"
        border="1px SOLID rgba(0,0,0,0.5)"
        borderRadius="75px"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(132,196,255,0.32)"
        {...getOverrideProps(overrides, "BackgroundRectangle")}
      ></View>
      <Flex
        gap="10px"
        direction="column"
        width="929px"
        height="300px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 150px - -15.5px)"
        left="calc(50% - 464.5px - -1.5px)"
        padding="140px 253px 140px 253px"
        {...getOverrideProps(overrides, "CenteredSquare")}
      >
        <Text
          fontFamily="Inter"
          fontSize="128px"
          fontWeight="600"
          color="rgba(0,0,0,1)"
          lineHeight="154.90908813476562px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="924px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Some Text"
          {...getOverrideProps(overrides, "TextInBox")}
        ></Text>
      </Flex>
    </View>
  );
}
