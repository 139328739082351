import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Plot from 'react-plotly.js';

import { WindowContext } from '../../../context/WindowContextProvider';
import { Plotlya } from '../../../services/charta/guts/interfaces/plotlya.model';
import { Card, View } from '@aws-amplify/ui-react';
// import Plotly from 'plotly.js';

interface Props {
  plotlya: Plotlya.Inputs;
}

function Chartplota({ plotlya }: Props) {
  const ref = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [refWidth, setRefWidth] = useState<number>(0);
  const [refHeight, setRefHeight] = useState<number>(0);
  const { clientHeight, clientWidth } = useContext(WindowContext);

  useLayoutEffect(() => {
    const { height, width } = ref.current.getBoundingClientRect();
    setRefHeight(height);
    setRefWidth(width);
  }, [clientHeight, clientWidth]);

  useEffect(() => {
    // * Note:  Ploty.useResizeHandler={true}   ...does not work, it does not make the chart responsive
    plotlya.layout.width = refWidth; // * Make change (Note: Change does not trigger a redraw).
    plotlya.layout.height = refHeight; // * Make change (Note: Change does not trigger a redraw).
    plotlya.data = [...plotlya.data]; // * This triggers a redraw
  }, [plotlya, refHeight, refWidth]); // * make chart responsive, unlike [plotlya.layout.width, plotlya.layout.height, clientWidth]);

  return (
    <>
      <View
        width={'100%'}
        height={'100%'}
        maxHeight={'100%'}
        maxWidth={'100%'}
        ref={ref}
      >
        {(refWidth > 0) && (
          <Plot
            data={plotlya.data}
            layout={plotlya.layout}
            config={plotlya.config}
          />
        )}
      </View>
    </>
  );
}

export default Chartplota;
