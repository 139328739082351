import { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { Flex, Heading, Text, View, Image } from '@aws-amplify/ui-react';
import { MapView } from '@aws-amplify/ui-react-geo';

import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react-geo/styles.css';
import { Geoa } from '../../interfaces/Geoa';
import { useMap } from 'react-map-gl';

// interface Props {
//   latitude: number;
//   longitude: number;
// }
export interface Props {
  geoa: Geoa;
}

function MarkerWithPopup({ geoa }: Props) {
  const [showPopup, setShowPopup] = useState(true);

  const handleMarkerClick = ({ originalEvent }: any) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  return (
    <>
      <Marker
        latitude={geoa.latitude}
        longitude={geoa.longitude}
        onClick={handleMarkerClick}
      />
      {showPopup && (
        <Popup
          latitude={geoa.latitude}
          longitude={geoa.longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
          // className={'blurBackground'}
        >
          <Flex alignItems="center">
            <Image
              alt="Amplify logo"
              src="/iphone.jpeg"
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              height="100%"
              width="100%"
              opacity="100%"
              border={'1px solid #d9d9d9'}
              // padding={'10px'}
            />
            {/* <Image
              alt="Amplify logo"
              src="/amplify-logo.svg"
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              height="75%"
              width="75%"
              opacity="100%"
              onClick={() => alert('📸 Say cheese!')}
            /> */}
            <View
              backgroundColor={'rgba(255, 255, 255, 0.58)'}
              className={'blurBackground'}
            >
              <Heading level={3} fontWeight={'400'} lineHeight={'1.1em'} padding={'0em 0.1em'}>
                {geoa.title}
              </Heading>
              {/* <Text>Some information about a location.</Text> */}
            </View>
          </Flex>
        </Popup>
      )}
    </>
  );
}

export default function MapWithMarkerPopup({ geoa }: Props) {
  return (
    <MapView
      initialViewState={{
        // latitude: geoa.latitude,
        // longitude: geoa.longitude,
        zoom: 14,
        latitude: 52.511732,
        longitude: -0.22327,
      }}
    >
      {/* <MapView initialViewState={{ latitude: 40, longitude: -100, zoom: 3.5 }}> */}
      <MarkerWithPopup geoa={geoa} />
      <NavigateButton geoa={geoa} />
    </MapView>
  );
}

interface Props2 {
  geoa: Geoa;
}

function NavigateButton({ geoa }: Props2) {
  const { current: map } = useMap();

  useEffect(() => {
    map && map.flyTo({ center: [geoa.longitude, geoa.latitude], zoom: 16 });
  }, [geoa.latitude, geoa.longitude, map]);

  return <></>;
}
