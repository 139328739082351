import * as THREE from 'three';
import { ReactNode, Ref, RefObject, Suspense, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import {
  Preload,
  ScrollControls,
  Scroll,
  useScroll,
  Image as ImageImpl,
} from '@react-three/drei';
import { Group } from 'three';
import _set from 'lodash/set';
import { View } from '@aws-amplify/ui-react';
// import { ForwardRefComponent } from '@react-three/drei/helpers/ts-utils';

function Image({ c = new THREE.Color(), ...props }) {
  const ref = useRef();

  const [hovered, hover] = useState(false);
  useFrame(() => {
    //   @ts-ignore
    ref.current.material.color.lerp(
      c.set(hovered ? 'white' : '#ccc'),
      hovered ? 0.4 : 0.05
    );
  });
  return (
    <ImageImpl
      //   @ts-ignore
      ref={ref}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}
      {...props}
    />
  );
}

interface Obj3D extends THREE.Mesh {
  children: THREE.Mesh[];
}

function Images() {
  const { width, height } = useThree((state) => state.viewport);
  const data = useScroll();
  const group = useRef() as RefObject<Group>;
  // const group = useRef() as RefObject<THREE.Mesh>;
  // const group = useRef() as RefObject<Obj3D>;
  // const group = useRef() as RefObject<THREE.Object3D>;
  //& console.log(
  //&   '%c ScrollControl | Images() | data :',
  //&   'background-color: #5555BB; color: white',
  //&   data
  //& );
  //   const group = useRef() as RefObject<Group> | null;
  useFrame(() => {
    // if (group?.current) {
    // if (group?.current?.children as THREE.Material[]) {
    //   group.current.children[0].material.zoom = 1 + data.range(0, 1 / 3) / 3;
    _set(
      group,
      'current.children[0].material.zoom',
      1 + data.range(0, 1 / 3) / 3
    );
    // group.current.children[1].material.zoom = 1 + data.range(0, 1 / 3) / 3;
    _set(
      group,
      'current.children[1].material.zoom',
      1 + data.range(0, 1 / 3) / 3
    );
    // group.current.children[2].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 3;
    _set(
      group,
      'current.children[2].material.zoom',
      1 + data.range(1.15 / 3, 1 / 3) / 3
    );

    //   @ts-ignore
    group.current.children[3].material.zoom =
      1 + data.range(1.15 / 3, 1 / 3) / 2; 
      // * When debugging in Chrome you can seet the prototype (set Zoom) that allow you to work with Zoom on
      // * a material.  But the type definitions are not correct so Typescript displays an error. 

    // * Attempts to fix / override this issue created complex code and multiple overrides along the tree branches. 
    //// @ts-ignore
    // group.current && (group.current.children[3] as THREE.Mesh).material.zoom =
    // group.current && (group.current.children[3] as THREE.Mesh).material.zoom.set(1 + data.range(1.15 / 3, 1 / 3) / 2);
    // group.current && (group.current.children[3] as THREE.Mesh).material.setZoom(2);
    // 1 + data.range(1.15 / 3, 1 / 3) / 2;

    //   @ts-ignore
    group.current.children[4].material.zoom =
      1 + data.range(1.25 / 3, 1 / 3) / 1;
    //   @ts-ignore
    group.current.children[5].material.zoom =
      1 + data.range(1.8 / 3, 1 / 3) / 3;
    //   @ts-ignore
    group.current.children[5].material.grayscale =
      1 - data.range(1.6 / 3, 1 / 3);
    //   @ts-ignore
    group.current.children[6].material.zoom =
      1 + (1 - data.range(2 / 3, 1 / 3)) / 3;
    // }
    // }
  });
  return (
    <group ref={group}>
      {/* <Image position={[-2, 0, 0]} scale={[4, height, 1]} url="/img1.jpg" /> */}
      <Image
        position={[-2, 0, 0]}
        scale={[4, height, 1]}
        url="/ani_exple/1.jpeg"
      />
      <Image position={[2, 0, 1]} scale={3} url="/ani_exple/6.jpeg" />
      <Image
        position={[-2.3, -height, 2]}
        scale={[1, 3, 1]}
        url="/ani_exple/2.jpeg"
      />
      <Image
        position={[-0.6, -height, 3]}
        scale={[1, 2, 1]}
        url="/ani_exple/8.jpeg"
      />
      <Image
        position={[0.75, -height, 3.5]}
        scale={1.5}
        url="/ani_exple/4.jpeg"
      />
      <Image
        position={[0, -height * 1.5, 2.5]}
        scale={[1.5, 3, 1]}
        url="/ani_exple/3.jpeg"
      />
      <Image
        position={[0, -height * 2 - height / 4, 0]}
        scale={[width, height / 2, 1]}
        url="/ani_exple/7.jpeg"
      />
    </group>
  );
}

export default function ScrollControl() {
  return (
    <View height={'98vh'} backgroundColor={'#313030'}>
    {/* <View height={'90vh'} backgroundImage={"linear-gradient(to bottom, #353535, #292929)"}> */}
      <Canvas gl={{ antialias: false }} dpr={[1, 1.5]}>
        <Suspense fallback={null}>
          <ScrollControls damping={0.1} pages={3}>
            <Scroll>
              <Images />
            </Scroll>
            <Scroll html>
              {/* <h1 style={{ position: 'absolute', top: '10vh', left: '0.5em', fontSize: '36vw', width: '90vw', maxWidth: '100px' }}> */}
              <div style={{ maxWidth: '800px' }}>
                <h1
                  style={{
                    position: 'absolute',
                    top: '10vh',
                    // left: '0.4em',
                    textAlign: 'center',
                    width: '100vw',

                    // fontSize: '36vw',
                    // fontSize: 4vw + 4vh + 2vmin);
                    // fontSize: `calc(60px / ${columns.length})`,
                    // fontSize:`calc(14vw + 14vh + 2vmin)`,
                    fontSize: '36vmin',
                    textShadow:
                      '0px 0px 1px rgba(240, 240, 240, 0.9), 0px 0px 20px rgba(200, 200, 200, 0.72)',

                    // maxWidth: '1000px',
                  }}
                >
                  style
                </h1>
                <h1
                  style={{
                    position: 'absolute',
                    top: '100vh',
                    textAlign: 'center',
                    width: '100vw',
                    paddingRight: '2.5em',

                    fontSize: '14vmin',
                    textShadow:
                      '0px 0px 1px rgba(240, 240, 240, 0.9), 0px 0px 20px rgba(200, 200, 200, 0.72)',
                  }}
                >
                  adds
                </h1>
                <h1
                  style={{
                    position: 'absolute',
                    top: '145vh',
                    left: '0.8em',
                    // textAlign: 'center',
                    // width: '100%',
                    textAlign: 'center',
                    width: '100vw',
                    paddingLeft: '0.2em',

                    fontSize: '22vmin',
                    textShadow:
                      '0px 0px 1px rgba(240, 240, 240, 0.9), 0px 0px 20px rgba(200, 200, 200, 0.72)',

                    // maxWidth: '1000px',
                  }}
                >
                  value
                </h1>
                {/* <h1 style={{ position: 'absolute', top: '130vh', textAlign: 'left', fontSize: '15vw', width: '90vw' }}>
                add value
              </h1> */}
                <h1
                  style={{
                    position: 'absolute',
                    //   top: '198.5vh',
                    top: '175vh',
                    // right: '0.1vw',
                    // right: '0.1em',
                    textAlign: 'center',
                    width: '100vw',
                    paddingRight: '10px',

                    fontSize: '35vmin',
                    textShadow:
                      '0px 0px 1px rgba(240, 240, 240, 0.9), 0px 0px 20px rgba(200, 200, 200, 0.72)',
                    // backgroundColor: 'green',
                    // maxWidth: '100vw',
                  }}
                >
                  enjoy
                </h1>
              </div>
            </Scroll>
          </ScrollControls>
          <Preload />
        </Suspense>
      </Canvas>
    </View>
  );
}
