// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { Outlet } from 'react-router-dom';
import { MenaComp } from './MenaComp';
import { MenaList } from './MenaData';
export function Protected() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';
  return (
    <>
      {/* <Heading level={1}>{message}</Heading> */}
      <Outlet />
      {/* <MenaComp  menaList={MenaList.ids}/> */}
    </>
  );
}
