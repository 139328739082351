import { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  RenderTexture,
  OrbitControls,
  PerspectiveCamera,
  Text,
  ContactShadows,
} from '@react-three/drei';
import { suspend } from 'suspend-react';
import THREE, { Mesh } from 'three';
import { ForwardRefComponent } from '@react-three/drei/helpers/ts-utils';

// @ts-ignore
const inter = import('@pmndrs/assets/fonts/inter_regular.woff');

export function MovingText() {
  return (
    <div style={{ height: '90vh' }}>
      <Canvas camera={{ position: [5, 5, 5], fov: 25 }}>
        <ambientLight intensity={1} />
        <directionalLight position={[10, 10, 5]} />
        <Cube />
        {/* <Dodecahedron position={[0, 1, 0]} scale={0.2} /> */}
        <ContactShadows
          frames={1}
          position={[0, -0.5, 0]}
          blur={1}
          opacity={0.75}
        />
        <ContactShadows
          frames={1}
          position={[0, -0.5, 0]}
          blur={3}
          color="orange"
        />
        <OrbitControls minPolarAngle={0} maxPolarAngle={Math.PI / 2.1} />
      </Canvas>
    </div>
  );
}

function message(messageNumb: number) {
  switch (messageNumb) {
    case 0:
      return 'Great experiences engage people.';
    case 1:
      return 'BREAKING: James creates great 3D.';
    case 2:
      return new Date().toLocaleString();
    case 3:
      return 'Engaged users spend 458% more time browsing.';
    default:
      break;
  }
}

export function Cube() {
  const textRef = useRef<Text>();

  let aaa = 0;
  let bbb = 0;
  let cnt = 0;

  useFrame((state, delta) => {
    aaa = Math.tan(state.clock.elapsedTime / 2) * 4;
    if (aaa < bbb) {
      cnt++;
    }
    bbb = aaa;

    // @ts-ignore - need to be speedy here - no type checking if's - Three should know better
    textRef.current.position.x = aaa;
    // @ts-ignore - need to be speedy here
    textRef.current.text = message(cnt % 4);
  });
  return (
    <mesh>
      <boxGeometry args={[23, 1, 5.8]} />
      <meshStandardMaterial>
        <RenderTexture attach="map" anisotropy={16}>
          <PerspectiveCamera
            makeDefault
            manual
            aspect={23 / 1}
            position={[0, 0, 5]}
          />
          {/* <color attach="background" args={['grey']} /> */}
          {/* <color attach="background" args={['#FFF']} /> */}
          {/* <color attach="background" args={['#111']} /> */}
          <color attach="background" args={['#444']} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} />
          <Text
            font={
              // @ts-ignore
              suspend(inter).default
            }
            ref={textRef}
            fontSize={3.9}
            // color="orange"
            color="#F93"
            position={[0, 0.2, 0]}
          >
            Great experiences engage people.
          </Text>
          {/* <Dodecahedron /> */}
        </RenderTexture>
      </meshStandardMaterial>
    </mesh>
  );
}

// @ts-ignore
function Dodecahedron(props) {
  const meshRef = useRef();
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // @ts-ignore
  useFrame(() => (meshRef.current.rotation.x += 0.01));
  return (
    <group {...props}>
      <mesh
        // @ts-ignore
        ref={meshRef}
        scale={clicked ? 1.5 : 1}
        onClick={() => click(!clicked)}
        onPointerOver={() => hover(true)}
        onPointerOut={() => hover(false)}
      >
        <dodecahedronGeometry args={[0.75]} />
        <meshStandardMaterial color={hovered ? 'hotpink' : '#5de4c7'} />
      </mesh>
    </group>
  );
}
