import React from 'react';
import MapPage from '../others/MapPage';
import { Flex, View, useBreakpointValue, useTheme } from '@aws-amplify/ui-react';
import MapWithButton from '../others/MapWithButton';
import Landmarks from '../../components/geo/Landmarks';

export default function IntegrationPage() {
  const { tokens, breakpoints } = useTheme();
  const variation = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
  }) as string;

  return (
    <>
      <div>IntegrationPage</div>
      <div>variation: {variation}</div>
      <div>width: { breakpoints.values.large + 'px'}</div>
      <View
        // direction="column"
        // justifyContent="center"
        // alignItems="center"
        // alignContent="center"
        // backgroundColor={'neutral.90'}
        // backgroundColor={'overlay.70'}
        // backgroundColor={'overlay.10'}
        // backgroundColor={'blue.80'}
        maxWidth={{ base: '98vw', large: breakpoints.values.xl + 'px' }}
        // backgroundColor={'yellow'}
      >
        {/* <MapWithButton /> */}
        <Landmarks />
      </View>
    </>
  );
}
