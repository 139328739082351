import React, { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
export type WindowContextProps = {
  clientHeight: number;
  clientWidth: number;
  widthDefault: number,
  widthScaler: number,
};

const clientHeight4 = () => Math.max(
  document.documentElement.clientHeight || 0,
  window.innerHeight || 0
)

const clientWidth4 = () => Math.max(
  document.documentElement.clientWidth || 0,
  window.innerWidth || 0
)

const widthDefault = 1280;
  

const scaler = () => (clientWidth4() >= widthDefault) ? 1 :  clientWidth4() / widthDefault;


const windowDefaults = {
  clientHeight: clientHeight4(),
  clientWidth: clientWidth4(),
  widthDefault: widthDefault,
  widthScaler: scaler(),
  // widthScaler: 1,
}


export const WindowContext = createContext<WindowContextProps>(windowDefaults);

interface Props {
  children: ReactNode;
}

export function WindowContextProvider({ children }: Props) {
  
  const getVh = useCallback(() => {
    return clientHeight4();
  }, []);

  const getVw = useCallback(() => {
    return clientWidth4();
  }, []);

  const [windowStats, setWindowStats] = useState<WindowContextProps>(windowDefaults);

  useEffect(() => {

    const handleResize = () => {
      const clientWidth2 = getVw();
      
      setWindowStats({
        clientHeight: getVh(),
        clientWidth:  clientWidth2,
        widthDefault: windowDefaults.widthDefault,
        widthScaler: scaler(),
      })

    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getVh, getVw]);
  return (
    // <WindowContext.Provider value={{ clientHeight, clientWidth, widthDefault: windowDefaults.widthDefault, 1 }}>
    <WindowContext.Provider value={windowStats}>
      {/* <pre>{getVh()}</pre> */}
      {/* <pre>{windowStats.clientHeight}</pre>
      <pre>{windowStats.clientWidth}</pre>
      <pre>{windowStats.widthDefault}</pre>
      <pre>{windowStats.widthScaler}</pre> */}
      {children}
    </WindowContext.Provider>
  );
}
