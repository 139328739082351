import {
  ThemeProvider,
  defaultDarkModeOverride,
  ColorMode,
} from '@aws-amplify/ui-react';
import { createTheme, defaultTheme } from '@aws-amplify/ui';

import { ReactNode, useContext } from 'react';
// import { defaultTheme } from '@aws-amplify/ui-react';

// import React from 'react';

import { StyleContext } from '../context/StyleContext';
import { studioTheme } from '../ui-components';
// import { studioTheme } from './ui-components';

interface Props {
  children: ReactNode;
}

const Styla = ({ children }: Props) => {
  const { isLuxury } = useContext(StyleContext);

  const customTheme = createTheme(
    {
      name: 'custom-theme',
      overrides: [defaultDarkModeOverride],
      tokens: {
        components: {
          card: {
            // backgroundColor: { value: '{colors.brand.secondary[40]}' },
            // backgroundColor: {
            //   value: isLuxury
            //     ? '{colors.brand.secondary[10]}'
            //     : //   ? "{colors.brand.secondary.60.value}"
            //       '{colors.brand.primary.10.value}',
            // },
            outlined: {
              borderColor: { value: '{colors.black}' },
            },
          },
          // heading: {
          //   //   color: { value: '{colors.brand.secondary[80]}' },
          //   color: { value: '{colors.brand.primary[80]}' },
          // },
          // text: {
          //   color: { value: '{colors.brand.primary[80]}' },
          // },
        },
      },
    },
    studioTheme
  );

  const theme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride],
  };

  const theme2 = createTheme(
    {
      name: 'my-theme',
      overrides: [defaultDarkModeOverride],
    },
    customTheme
  );

  const colorMode = isLuxury ? 'light' : 'dark';

  // @ You should only have one <ThemeProvider> in app.
  // @ see: https://ui.docs.amplify.aws/react/theming/dark-mode
  //   return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
  return (
    // <ThemeProvider theme={theme2} colorMode={colorMode}>
    <ThemeProvider theme={customTheme} colorMode={colorMode}>
      {children}
    </ThemeProvider>
  );
};

export default Styla;
