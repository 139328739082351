import { DataStore } from '@aws-amplify/datastore';
import { Gltf, LazyGltf } from '../models';
import { useEffect, useRef, useState } from 'react';
import { GLTFA } from '../interfaces/Gltfa';
import {
  GltfData,
  Ggltfa,
  Gnoda,
  Gnodas,
  XyzTuple,
} from '../interfaces/DbGltfa';

// ! const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));
// ! const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));
// ! const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));
// ! const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));

export function useAsyncGltfData(gltfId: string) {
  const [result, setResult] = useState<LazyGltf | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  // const [error, setError] = useState<unknown>();

  useEffect(() => {
    async function fetchBookList() {
      try {
        setLoading(true);
        const response = await DataStore.query(Gltf, gltfId);
        setLoading(false);
        setResult(response);
      } catch (error) {
        console.log('ERROR: 202309231907 - ', error);
      }
    }
    if (gltfId !== '') {
      fetchBookList();
    }
  }, [gltfId]);

  return { result, loading };
}

export function useGltfDataForDb(fileName: string, nodeIds: string[]) {
  const gltfData: GltfData = {
    fileName: fileName,
    nodes: {} as Gnodas,
  };

  nodeIds.forEach(
    (nodeId) =>
      (gltfData.nodes[nodeId] = {
        id: nodeId,
        displayNode: false,
        position: [0, 0, 0],
        scale: [1, 1, 1],
        rotation: [0, 0, 0],
      })
  );

  console.log(
    '%c useCruidGltf | useGltfDataForDb | gltfData :',
    'background-color: #993300; color: white',
    gltfData
  );

  return gltfData;
}

// export function useCreateGnodes(nodeIds: string[]): LazyGnode[] {
//   const [gnodes, setGnodes] = useState<Gnode[]>([]);
//   const nodes = useGltfDataForDb(nodeIds);

//   useEffect(() => {
//     if (nodes) {
//       setGnodes(nodes);
//     }
//   }, [nodes]);

//   return gnodes;
// }

// export function useCreateGltf(nodeIds: string[]): LazyGltf | undefined {
//   const [gltfDb, setGltfDb] = useState<LazyGltf>();
//   const gGltfa = useGltfDataForDb(nodeIds);

//   useEffect(() => {
//     async function createGltfDbRecord() {
//       const gltfSave = await DataStore.save(
//         new Gltf({
//           nodes: JSON.stringify(gGltfa.nodes),
//         })
//       );
//       setGltfDb(gltfSave);
//     }

//     createGltfDbRecord();
//   }, [gGltfa.nodes]);

//   return gltfDb;
// }

export function useObserveGltfData(gltfId: string) {
  const [result, setResult] = useState<LazyGltf | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sub = DataStore.observeQuery(Gltf, (c) => c.id.eq(gltfId)).subscribe(
      ({ items }) => {
        setLoading(false);
        setResult(items[0]);
        //& console.log(
        //&   '%c useCruidGltf | useObserveGltfData(gltfId: string) | items[0] :',
        //&   'background-color: #773300; color: white',
        //&   items[0]
        //& );
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, [gltfId]);

  return { result, loading };
}

export function useObserveGltfsFromGimageId(gimageId: string) {
  const [gltfs, setGltfs] = useState<LazyGltf[] | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  // console.log(
  //   '%c useCruidGltf | useObserveGltfsFromGimageId(gimageId: string) :',
  //   'background-color: #993300; color: white',
  //   '=============='
  // );

  useEffect(() => {
    // const comments = await DataStore.query(Comment, c => c.post.id.eq('YOUR_POST_ID'));

    const sub = DataStore.observeQuery(Gltf, (g) =>
      g.gimageID.eq(gimageId)
    ).subscribe(({ items }) => {
      setLoading(false);
      setGltfs(items);
      //& console.log(
      //&   '%c useCruidGltf | useObserveGltfData(gltfId: string) | items :',
      //&   'background-color: #773300; color: Aquamarine',
      //&   items
      //& );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [gimageId]);

  return { gltfs, loading };
}

// export const useCrudGltf = () => {
export function useCrudGltf() {
  function convertGltfJsonToType(gltf: Gltf | undefined): Ggltfa {
    // * Convert Ggltfa.nodes JSON into formal types.
    // * gltf stores key: value node data as Text
    // * e.g. JSON.stringify(gGltfa.nodes)
    // * Why:
    // * AWS Amplify does not let you store: [id: string]: Node
    // * to fix this you either store it as an {}[]  or  AWSJSON (i.e a string)
    // * decided best to store as JSON like GCP rather than a complex array of
    // * objects that will increasly become more complicated as Gltf data
    // * increasingly gets databased. So data store as Trxi.e. AWSJSON.

    let ggltfa = {} as Ggltfa;
    if (gltf) {
      ggltfa = gltf as any;
    }

    // console.log(
    //   '%c useCruidGltf | convertGltfJsonToType | ggltfa :',
    //   'background-color: #993300; color: white',
    //   ggltfa
    // );
    return ggltfa;
  }

  // const toggleDisplayNode = async (gltfId: string, nodeId: string) => {
  async function toggleDisplayNode(gltfId: string, nodeId: string) {
    const original = await DataStore.query(Gltf, gltfId);

    if (original) {
      const updatedGltf = await DataStore.save(
        Gltf.copyOf(original, (updated) => {
          const ggltfa = convertGltfJsonToType(updated);

          ggltfa.nodes[nodeId].displayNode = !ggltfa.nodes[nodeId].displayNode;

          const gltf = {
            ...ggltfa,
            nodes: JSON.stringify(ggltfa.nodes),
          } as Gltf;
          return gltf;
        })
      );
      return updatedGltf;
    }
  }

  // const updateGltfPostion = async (gltfId: string, nodeId: string, position: number[]) => {
  // const updateGltfPostion = async (
  //   gltfId: string,
  //   nodeId: string,
  //   position: THREE.Vector3 | undefined
  // ) => {
  //   if (position === undefined) {
  //     return;
  //   }

  //   const positionTuple: XyzTuple = [position.x, position.y, position.z];
  //   const original = await DataStore.query(Gltf, gltfId);

  //   console.log(
  //     '%c useCruidGltf | updateGltfPostion | positionTuple :',
  //     'background-color: #993300; color: white',
  //     positionTuple
  //   );

  //   if (original) {
  //     const updatedGltf = await DataStore.save(
  //       Gltf.copyOf(original, (updated) => {
  //         const ggltfa = convertGltfJsonToType(updated);

  //         ggltfa.nodes[nodeId].position = positionTuple;

  //         const gltf = {
  //           ...ggltfa,
  //           nodes: JSON.stringify(ggltfa.nodes),
  //         } as Gltf;
  //         return gltf;
  //       })
  //     );
  //     return updatedGltf;
  //   }
  // };

  const updateGltfObject = async (
    gltfId: string,
    nodeId: string,
    object: THREE.Object3D | undefined
  ) => {
    // const updateGltfObject = async (gltfId: string, nodeId: string, object: string | undefined) => {

    if (object === undefined) {
      return;
    }

    const positionTuple: XyzTuple = [
      object.position.x,
      object.position.y,
      object.position.z,
    ];
    const scaleTuple: XyzTuple = [
      object.scale.x,
      object.scale.y,
      object.scale.z,
    ];
    const rotationTuple: XyzTuple = [
      object.rotation.x,
      object.rotation.y,
      object.rotation.z,
    ];

    const original = await DataStore.query(Gltf, gltfId);

    console.log(
      '%c useCruidGltf | updateGltfObject | rotationTuple :',
      'background-color: #993300; color: white',
      rotationTuple
    );

    if (original) {
      const updatedGltf = await DataStore.save(
        Gltf.copyOf(original, (updated) => {
          const ggltfa = convertGltfJsonToType(updated);

          ggltfa.nodes[nodeId].position = positionTuple;
          ggltfa.nodes[nodeId].scale = scaleTuple;
          ggltfa.nodes[nodeId].rotation = rotationTuple;

          const gltf = {
            ...ggltfa,
            nodes: JSON.stringify(ggltfa.nodes),
          } as Gltf;
          return gltf;
        })
      );
      return updatedGltf;
    }
  };

  const updateGltf = async (id: string) => {
    const original = await DataStore.query(Gltf, id);

    if (original) {
      const updatedGltf = await DataStore.save(
        Gltf.copyOf(original, (updated) => {
          // updated.nodes = [
          //   {
          //     id: 'asdfdsfdadfs',
          //     position: {
          //       x: 7,
          //       y: 2,
          //       z: 3,
          //     },
          //     scale: {
          //       x: 0,
          //       y: 0,
          //       z: 0,
          //     },
          //   },
          //   {
          //     id: 'qwerewqrwe',
          //     position: {
          //       x: 1,
          //       y: 2,
          //       z: 3,
          //     },
          //     scale: {
          //       x: 0,
          //       y: 0,
          //       z: 0,
          //     },
          //   },
          // ];
        })
      );
      return updatedGltf;
    }
  };

  // const updateGltfPostion = async (id: string, nodeId: string, position: []) => {
  //   const original = await DataStore.query(Gltf, id);
  //   const nodes =  JSON.stringify(gGltfa.nodes)

  //   if (original) {
  //     const updatedGltf = await DataStore.save(
  //       Gltf.copyOf(original, (updated) => {
  //         if (updated.nodes){
  //           updated.nodes[nodeId].position = {}
  //         }

  //         // updated.nodes = [
  //         //   {
  //         //     id: 'asdfdsfdadfs',
  //         //     position: {
  //         //       x: 7,
  //         //       y: 2,
  //         //       z: 3,
  //         //     },
  //         //     scale: {
  //         //       x: 0,
  //         //       y: 0,
  //         //       z: 0,
  //         //     },
  //         //   },
  //         //   {
  //         //     id: 'qwerewqrwe',
  //         //     position: {
  //         //       x: 1,
  //         //       y: 2,
  //         //       z: 3,
  //         //     },
  //         //     scale: {
  //         //       x: 0,
  //         //       y: 0,
  //         //       z: 0,
  //         //     },
  //         //   },
  //         // ];
  //       })
  //     );
  //     return updatedGltf;
  //   }
  // };

  const createGltf = (gltfData: GltfData) => {
    async function createRecord() {
      return await DataStore.save(
        new Gltf({
          fileName: gltfData.fileName,
          nodes: JSON.stringify(gltfData.nodes),
        })
      );
    }
    return createRecord();
  };

  const createGnodesDataFromNodeIds = (
    nodeIds: string[]
  ): Gnoda[] | undefined => {
    // const [nodes, setNodes] = useState<Gnode[]>();

    // useEffect(() => {
    const gnodes = nodeIds.map(
      (nodeId) =>
        ({
          id: nodeId,
          displayNode: false,
          position: [0, 0, 0],
          rotation: [0, 0, 0],
          scale: [1, 1, 1],
        } as Gnoda)
    );

    //   setNodes(nodes2);

    //   // setNodes(nodes)
    // }, [nodeIds]);

    console.log(
      '%c useCruidGltf | createGnodesDataFromNodeIds | gnodes :',
      'background-color: #993300; color: white',
      gnodes
    );

    return gnodes;
  };

  function getGltfDisplayNodes(gltf: LazyGltf) {
    const gltfa = convertGltfJsonToType(gltf);

    let nodeDisplayIds: string[] = [];
    if (gltfa?.nodes) {
      const nodeIds = Object.keys(gltfa.nodes);
      nodeIds.forEach((nodeId: string) => {
        if (gltfa.nodes[nodeId].displayNode) {
          nodeDisplayIds.push(nodeId);
        }
      });
    }
    return nodeDisplayIds;
  }

  function useGetGltfsDisplayNodes(gltfs: LazyGltf[] | undefined) {
    if (!gltfs) {
      return [] as Ggltfa[];
    }

    return gltfs.map((gltf: LazyGltf) => {
      const nodeIds = getGltfDisplayNodes(gltf);
      let gltfa = { ...convertGltfJsonToType(gltf) }; // * Clone

      const gnodes = {} as Gnodas;
      nodeIds.forEach((nodeId: string) => {
        gnodes[nodeId] = gltfa.nodes[nodeId];
      });

      gltfa.nodes = gnodes;
      return gltfa;
    });
  }

  // return { readGltfId, updateGltf, createGltf };
  // return { useAsyncGltfData, updateGltf, createGltf };
  return {
    createGnodesDataFromNodeIds,
    updateGltf,
    createGltf,
    convertGltfJsonToType,
    toggleDisplayNode,
    // updateGltfPostion,
    updateGltfObject,
    getGltfDisplayNodes,
    useGetGltfsDisplayNodes,
  };
}

// export default useCrudGltf;
