import { Button } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

// interface Charta {
//   data: Plotly.Data[];
// }

const ChartPageA = () => {
  //   const [pyData, setPyData] = useState<Plotly.Data[]>();
  const [dataY, setDataY] = useState<number[]>([2, 5, 3]);

    function updateData(name: string): void {
        const dataYnew = [...dataY];
        // dataYnew[0] = dataYnew[0] + 1;
        dataYnew[0]++;

        setDataY(dataYnew);
        // throw new Error('Function not implemented.');
    }

  return (
    <>
      <div>ChartPageA</div>

      <Button loadingText="" onClick={(e) => updateData('first')} ariaLabel="">
        Click me! {dataY}
      </Button>
      <Plot
        data={[
          {
            x: [1, 2, 3],
            y: [2, 6, 3],
            type: 'scatter',
            // type: 'bar',
            mode: 'lines+markers',
            marker: { color: 'red' },
          },
          { type: 'bar', x: [1, 2, 3], y: dataY },
        ]}
        layout={{ width: 320, height: 240, title: 'A Fancy Plot' }}
      />
    </>
  );
};

export default ChartPageA;
