import React, { useContext, useEffect, useState } from 'react';
import './UiuxAnimation.scss';
import { Flex, Heading, View, Text, Image } from '@aws-amplify/ui-react';
import { WindowContext } from '../../context/WindowContextProvider';

function UiuxAnimation() {
  const aaa = '150';

  

  const drwa = {
    // circles: {
    //   width: '350',
    //   height: '350',
    //   border: '2px solid grey',
    //   fontSize: '150',
    // },
    circles: {
      width: '450',
      height: '450',
      border: '2px solid grey',
      fontSize: '190',
    },
  };

  const drwa2 = ['width: 350px', 'height: 350px'];
  const windowStats = useContext(WindowContext);

  return (
    <>
      <Flex
        gap={'0'}
        className="flexCentered"
        // backgroundColor={'neutral.20'}
        // backgroundColor={'hsl(0deg 0% 96.86%)'}
        width={'100%'}
        transform={`scale(${windowStats.widthScaler})`}
        maxHeight={`${+drwa.circles.height * windowStats.widthScaler}px`}
        height={`${+drwa.circles.height  * windowStats.widthScaler}%`}
      >
        <Flex
          gap={'0'}
          // className='flexCentered'
          padding={50}
          // marginTop={'-200px'}
          style={{ zIndex: 10 }}
        >
          <View
            // backgroundColor={'red.20'}
            backgroundColor={'hsl(0, 75%, 85%, 0.5)'}
            padding={'100px'}
            borderRadius={'100%'}
            width={drwa.circles.width + 'px'}
            height={drwa.circles.width + 'px'}
            // maxWidth={'30vw'}
            // maxHeight={'30vw'}
            // maxWidth={'300px'}
            // maxHeight={'300px'}
            // style={{...drwa2}}
            className="flexCentered boxShadow disc"
            border={drwa.circles.border}
            marginRight={-20}
          >
            <Text fontSize={drwa.circles.fontSize + 'px'} className="insetText">
              UI
            </Text>
          </View>
          <View
            // backgroundColor={'blue.20.'}
            backgroundColor={'hsl(220, 85%, 85%, 0.5)'}
            padding={'100px'}
            borderRadius={'100%'}
            width={drwa.circles.width + 'px'}
            height={drwa.circles.width + 'px'}
            // maxWidth={'30vw'}
            // maxHeight={'30vw'}
            className="flexCentered boxShadow disc"
            border={drwa.circles.border}
            marginLeft={-20}
          >
            <Text fontSize={drwa.circles.fontSize + 'px'} className="insetText">
              UX
            </Text>
          </View>
        </Flex>
      </Flex>
    </>
  );
}

export default UiuxAnimation;
