// import React from 'react'

export interface ContentDoc {
  contentIds: string[];
  contentBIds: string[];
  contents: { [id: string]: Content };
}

export interface Content {
  id: string;
  title?: string;
  desc?: string;
  classes: string;
  backgroundColor: string;
  src?: string;
  img?: {
    marginTop: string;
    marginLeft: string;
    maxHeight?: string;
    src?: string;
  };
}


export function intersect3data(): ContentDoc  {
    return {
      contentIds: [
        // 'stimulating',
        // 'engaging',
        // 'bulb',
        // 'uiux',
        // 'inspiring',
        // 'beautiful',

        // 'moreText',
        // 'moreText2',
        // 'moreText3',
        'professionally',
        // 'developed',
        'uiUx',
      ],
      contentBIds: [
        'uiux',
      ],
      contents: {
        stimulating: {
          id: 'stimulating',
          title: 'stimulating',
          src: '/iphoneB.jpeg',
          classes: 'RotateUp',
          backgroundColor: '#f5f5f7',
          // img: {
          //   marginLeft: '15%',
          //   src: '/style/hand_give.png',
          // },
        },
        engaging: {
          id: 'engaging',
          title: 'engaging',
          src: '/iphoneB.jpeg',
          classes: 'RotateUp',
          backgroundColor: '#f5f5f7',
        },
        moreText: {
          id: 'moreText',
          title: 'interesting',
          // src: '/iphoneB.jpeg',
          classes: 'RotateUp',
          backgroundColor: '#f5f5f7',
        },
        moreText2: {
          id: 'moreText2',
          title: 'engaging',
          // src: '/iphoneB.jpeg',
          classes: 'SlideInFromLeft',
          backgroundColor: '#f5f5f7',
        },
        moreText3: {
          id: 'moreText3',
          title: 'stimulatingg',
          // src: '/iphoneB.jpeg',
          classes: 'SlideInFromRight',
          backgroundColor: '#f5f5f7',
        },
        inspiring: {
          id: 'inspiring',
          // title: 'inspiring',
          // src: '/iphoneB.jpeg',
          // src: '/style/hand2.jpg',
          // src: '/style/hand2.png',

          classes: 'RotateUp',
          backgroundColor: '##ffffff',
          // desc: 'engaging',
          desc: 'messages',

          src: '/style/hand_give.png',
          img: {
            marginTop: '0px',
            marginLeft: '-20%',
            maxHeight: '500px',
            src: '/style/hand_give.png',
          },
        },
        bulb: {
          id: 'bulb',
          // title: 'inspiring',
          // src: '/iphoneB.jpeg',
          // src: '/style/hand2.jpg',
          // src: '/style/hand2.png',

          classes: 'RotateUp',
          backgroundColor: '##ffffff',
          // desc: 'engaging',
          // desc: 'captivating',

          src: '/style/bulb.jpg',
          img: {
            marginTop: '0px',
            marginLeft: '0px',
            maxHeight: '500px',
            src: '/style/bulb.jpg',
          },
        },
        uiux: {
          id: 'uiux',
          // title: 'inspiring',
          // src: '/iphoneB.jpeg',
          // src: '/style/hand2.jpg',
          // src: '/style/hand2.png',

          classes: 'GrowFromSmall',
          backgroundColor: '##ffffff',
          // desc: 'engaging',
          src: '/style/uiux2.png',
          img: {
            marginTop: '0',
            marginLeft: '0',
            maxHeight: '200px',
            src: '/style/uiux.png',
          },
        },
        beautiful: {
          id: 'beautiful',
          title: 'beautiful',
          // src: '/iphoneB.jpeg',
          src: '/style/people1.webp',
          classes: 'SlideInFromRight',
          backgroundColor: '##ffffff',
        },
        professionally: {
          id: 'professionally',
          title: 'professionally',
          // src: '/iphoneB.jpeg',
          desc: 'engineered',
          // src: '/style/hand3.avif',
          src: '/style/hand3.png',
          classes: 'RotateUp',
          backgroundColor: '#e7edef',
          img: {
            marginTop: '-95px',
            marginLeft: '40px',
            src: '/style/hand_give.png',
          },
        },
        developed: {
          id: 'developed',
          title: 'developed',
          // src: '/iphoneB.jpeg',
          src: '/style/plants.webp',
          classes: 'GrowFromSmall',
          backgroundColor: '#ffffff',
        },
        uiUx: {
          id: 'uiUx',
          title: 'intriguing',
          desc: 'engagement',
          src: '/iphoneB.jpeg',
          // classes: 'GrowFromSmall',
          classes: 'RotateUp',
          backgroundColor: '#f5f5f7',
          img: {
            marginTop: '0',
            marginLeft: '0',
            maxHeight: '800px',
          },
        },
      },
    };
}
