import {
  StaticTreeDataProvider,
  Tree,
  TreeDataProvider,
  UncontrolledTreeEnvironment,
} from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css';
import { longTree } from './treeStub';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  items: any;
  sceneId: string | number;
}

export function MeshTree({ items, sceneId }: Props) {
  // const [data, setData] = useState({} as TreeDataProvider<any>);
  // const [data, setData] = useState(
  //   new StaticTreeDataProvider(longTree.items, (item, data) => ({
  //     ...item,
  //     data,
  //   }))
  // );

  // const [data, setData] = useState<StaticTreeDataProvider<any>>();

  const data = useMemo(
    () =>
      // new StaticTreeDataProvider(longTree.items, (item, data) => ({
      new StaticTreeDataProvider(items.items, (item, data) => ({
        ...item,
        data,
      })),
    [items.items]
  );

  useEffect(() => {
    //& console.log(
    //&   '%c MeshTree | useEffect( .., [data]) | data :',
    //&   'background-color: #775599; color: white',
    //&   data
    //& );
  }, [data]);

  // useEffect(() => {
  //   console.log(
  //     '%c MeshTree | items :',
  //     'background-color: #775599; color: white',
  //     items
  //   );
  //   debugger
  //   // setData(items);

  //   const aaa = new StaticTreeDataProvider(items, (item, data) => ({
  //     ...item,
  //     data,
  //   }))

  //   setData(aaa);
  // }, [items]);
 
  const updateDrop = (data: StaticTreeDataProvider<any>) => {

    //& console.log(
    //&   '%c MeshTree | updateDrop = (data: StaticTreeDataProvider<any>) | data :',
    //&   'background-color: #775599; color: white',
    //&   data
    //& );
  }



  return (
    <>
      <h1>Mesh Tree</h1>
      {data && (
        <UncontrolledTreeEnvironment
          dataProvider={data}
          getItemTitle={(item) => item.data}
          // onFocusItem={}
          canDragAndDrop={true}
          canDropOnFolder={true}
          canReorderItems={true}
          onDrop={(e) => updateDrop(data)}
          // onExpandItem={(e) => console.log(e)}
          viewState={{
            'tree-1': {
              expandedItems: ['Fruit'],
            },
          }}
        >
          <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" />
        </UncontrolledTreeEnvironment>
      )}
    </>
  );
}
