import React, { useEffect } from 'react';
import ChartTicking from '../../components/charts/premade/ChartTicking';
import ChartThirty from '../../components/charts/premade/ChartThirty';
import ChartSurface from '../../components/charts/premade/ChartSurface';
import {
  AlertVariations,
  Card,
  Flex,
  Grid,
  Text,
  Heading,
  View,
  useBreakpointValue,
  useTheme,
} from '@aws-amplify/ui-react';
import {
  AnimateConfig,
  AnimateScroll,
} from '../../services/animation/animateScroll';

export default function AnalysisPage() {
  // return (
  //   <>
  //     <div>AnalysisPage</div>
  //     <ChartSurface />
  //     <ChartTicking />
  //     <ChartThirty />
  //   </>
  // );
  const { tokens } = useTheme();

  const variation = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
  }) as string;

  // @ GrowFromSmall
  useEffect(() => {
    const htmlElements = document.querySelectorAll('.GrowFromSmall');
    const config: AnimateConfig = {
      rootMargin: '0px',
      animateStyle: 'GrowFromSmall',
    };
    const Animate = new AnimateScroll(htmlElements, config);

    const subObserver = Animate.startObservable();
    return () => subObserver.disconnect();
  }, []);

  return (
    <>
      {/* <Heading>Variation: {variation}</Heading> */}
      <div className="bckground-grad">
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          // className='bckground-grad'
          // backgroundColor={'neutral.90'}
          // backgroundColor={'overlay.70'}
          // backgroundColor={'rgb(2,0,36)'}
          // background: rgb(2,0,36);
          // background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(77,77,77,1) 35%, rgba(193,193,193,1) 100%);
          // backgroundColor={'overlay.10'}
          // backgroundColor={'blue.80'}
          maxWidth={'99vw'}
        >
          <Heading
            level={1}
            // fontSize={110}
            fontSize={{ base: '60px', large: '110px' }}
            // backgroundColor={{ base: 'red', large: 'green' }}
            textAlign={'center'}
            fontWeight={600}
            color={'neutral.10'}
            // color={'white'}
            // backgroundColor={'neutral.10'}
            paddingTop={30}
            paddingBottom={0}
            className="GrowFromSmall txt-shadow"
          >
            Integrated
          </Heading>
          <Heading
            // fontSize={27}
            // fontSize={39}
            // fontSize={48}
            fontSize={{ base: '30px', large: '48px' }}
            textAlign={'center'}
            fontWeight={600}
            // color={'hsl(219.27deg 100% 62.55%)'}
            // color={'rgb(56, 100, 228)'}
            // color={'blue.20'}
            // color={'blue.20'}
            // backgroundColor={'neutral.10'}
            // color={'overlay.90'}
            // color={'pink.20'}
            // color={'blue.20'}
            // color={'red.20'}
            color={'red.40'}
            paddingTop={0}
            marginTop={-15}
            paddingBottom={10}
            className="GrowFromSmall txt-shadow"
          >
            Live real-time data analysis.
          </Heading>
          {/* advanced real-time applications */}

          {/* <Card>variation: {variation}</Card> */}
          {variation === 'large' ? (
            <>
              <Flex
                gap="2px"
                alignItems="center"
                // maxWidth={'1200px'}
                // width={'100%'}
                justifyContent="center"
                // alignItems="center"
                alignContent="center"
                maxWidth={1200}
                border={'solid 1px white'}
                // borderColor={'red'}
              >
                <View
                  height={'602px'}
                  maxHeight={'80vh'}
                  width={'60vw'}
                  gap="2px"
                >
                  <ChartSurface />
                </View>
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  width={'37vw'}
                  maxHeight={'80vh'}
                  gap="2px"

                  // width={'90vw'}
                >
                  <View height={'300px'} maxHeight={'39.8vh'} width={'100%'}>
                    <ChartTicking />
                  </View>
                  <View height={'300px'} maxHeight={'39.8vh'} width={'100%'}>
                    <ChartThirty />
                  </View>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              gap="2px"
              width={'98vw'}
            >
              <View height={'800px'} maxHeight={'50vh'} gap="2px">
                <ChartSurface />
              </View>
              <View height={'300px'} gap="2px">
                <ChartTicking />
              </View>
              <View height={'300px'} gap="2px">
                <ChartThirty />
              </View>
            </Flex>
          )}

          {/* <Card
        height={'1000px'}
        >
        <ChartThirty />
      </Card> */}
          {/* public/videos/screen_recordings/rearranging_charts_short.mp4 */}
          <Heading
            level={2}
            // fontSize={110}
            fontSize={{ base: '50px', medium: '60px', large: '110px' }}
            textAlign={'center'}
            fontWeight={600}
            color={'neutral.10'}
            padding={30}
            className="GrowFromSmall"
          >
            Configurable
          </Heading>
          {/* <Heading level={2}>integrated</Heading> */}
          {/* <Flex justifyContent="center"> */}
          <Flex maxWidth={1000} border={'solid 1px white'}>
            <video
              id="VideoPlayer"
              // src="'/assets/videos/screen_recordings/rearranging_charts_short.mp4'"
              src={'/videos/screen_recordings/rearranging_charts_short.mp4'}
              loop={true}
              playsInline={true}
              autoPlay={true}
              muted={true}
              width={'100%'}
            ></video>
          </Flex>
          {/* </Flex> */}

          {/* <Heading fontSize={40} textAlign={'center'} fontWeight={600} color={'neutral.100'} backgroundColor={'neutral.10'}>real-time</Heading> */}
          {/* <Heading
          fontSize={22}
          textAlign={'center'}
          fontWeight={400}
          color={'hsl(219.69deg 100% 62.35%)'}
          // backgroundColor={'neutral.10'}
          paddingBottom={0}
          marginBottom={-2}
        >
          live data
        </Heading> */}
          <Heading
            // fontSize={110}
            fontSize={{ base: '50px', medium: '60px', large: '110px' }}
            textAlign={'center'}
            fontWeight={600}
            // color={'neutral.100'}
            color={'neutral.10'}
            // backgroundColor={'neutral.10'}
            paddingTop={0}
            className={'GrowFromSmall'}
          >
            {/* <div className='txt-shadow'>Applications</div> */}
            Applications
          </Heading>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            // fontSize={45}
            textAlign={'center'}
            fontWeight={500}
            // color={'hsl(219.27deg 100% 62.55%)'}
            // backgroundColor={'neutral.10'}
            paddingTop={2}
            marginTop={0}
            paddingBottom={10}
            className="GrowFromSmall txt-shadow"
            color={'red.40'}
            fontSize={{ base: '20px', medium:'25px', large: '48px' }}
          >
            <Text paddingRight={1} paddingLeft={1} color={'red.40'}>
              Flexible.
            </Text>
            <Text paddingRight={1} paddingLeft={1} color={'red.40'}>
              Adaptable.
            </Text>
            <Text paddingRight={1} paddingLeft={1} color={'red.40'}>
              Enjoyable.
            </Text>
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            // fontSize={45}
            textAlign={'center'}
            fontWeight={500}
            // color={'hsl(219.27deg 100% 62.55%)'}
            // backgroundColor={'neutral.10'}
            paddingTop={0}
            marginTop={0}
            paddingBottom={10}
            className="GrowFromSmall"
            color={'neutral.10'}
            fontSize={{ base: '30px', large: '48px' }}
          >
            <div className="txt-shadow">Purpose built solutions.</div>
          </Flex>
          <View height={40}></View>
        </Flex>
      </div>
    </>
  );
}

// {/* <Grid
// templateColumns={{ base: '1fr', large: '1fr 1fr' }}
// templateRows={{ base: 'repeat(3, 10rem)', large: 'repeat(3, 20rem)' }}
// // templateRows={{ base: '10em 10em 10em', large: '10em 10em 10em' }}
// gap={tokens.space.small}
// >
// {/* <View
//   columnSpan={[1, 1, 1, 2]}
//   // columnSpan={{base: [2], large: [2]}}
//   // columnSpan={[2]}
//   // columnSpan={{ base: '1, 1', large: '1, 1, 1, 1' }}
//   // columnSpan={{ base: '1', large: '1' }}
//   // backgroundColor={tokens.colors.pink[10]}
//   backgroundColor={{ base: 'orange', large: 'yellow' }}
//   // templateRows={{ base: '10em', large: 'repeat(3, 40rem)' }}
// ></View> */}

// <View
//   // rowSpan={[2, 1]}
//   rowSpan={{ base: 1, large: 2 }}
//   backgroundColor={tokens.colors.green[20]}
//   // height={{ base: '400px', large: '1000px' }}
//   // maxWidth={'100%'}
//   // maxHeight={'80vh'}

//   // width={'100%'}
//   // height={'100%'}
// >
//   <ChartSurface />
//   {/* <Card height={'1000px'}> */}
//   {/* <ChartSurface /> */}
//   {/* </Card> */}
// </View>
// <View
//   // rowSpan={{ base: 1, large: 1 }}
//   backgroundColor={tokens.colors.pink[40]}
//   // height={{ base: '200px', large: '500px' }}
// >
//   {/* <ChartTicking /> */}
//   {/* <ChartThirty /> */}
// </View>
// <View
//   // rowSpan={{ base: 1, large: 1 }}
//   backgroundColor={tokens.colors.pink[60]}
//   // height={{ base: '200px', large: '500px' }}
// >
//   {/* <ChartThirty /> */}
// </View>
// </Grid> */}
