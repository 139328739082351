
export class GenericToolsService {

  constructor() { }

  getRandomLetters(
    numberOfLetters: number,
    possibleLetters = 'abcdefghjkmnopqrstuvwxyz'
  ) {
    // return Array.apply(null, Array(numberOfLetters))
    //   .map(() => {
    //     return this.[Math.floor(Math.random() * this.length)];
    //   }, possibleLetters)
    //   .join('');
    return "ABCDEFG"
  }

  getRandomCode() {
    const chars1 = this.getRandomLetters(3, 'abcdefghmnprst');
    const numbs = this.getRandomInt(10, 30);
    const chars2 = this.getRandomLetters(1, 'abc');
    return `${chars1}${numbs}${chars2}`;
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

}
