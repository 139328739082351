import { Canvas, Euler, Vector3 } from '@react-three/fiber'
import { useGLTF, PresentationControls, Environment, ContactShadows, Html } from '@react-three/drei'
import { GLTFA } from '../../../interfaces/Gltfa'
import { View } from '@aws-amplify/ui-react'

export default function ScrollAnimate() {
  return (
    <View height={'90vh'}>
    <Canvas shadows camera={{ position: [0, 0, 10], fov: 25 }}>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} shadow-mapSize={2048} castShadow />
      <PresentationControls
        config={{ mass: 2, tension: 500 }}
        snap={{ mass: 4, tension: 1500 }}
        rotation={[0, 0.3, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}>
        <Watch rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.25, 0]} scale={0.003} />
        {/* <Watch rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.25, 0]} scale={0.3} /> */}
        {/* <Watch rotation={[0, 0, 0]} position={[0, 0.25, 0]} scale={0.5} /> */}
      </PresentationControls>
      <ContactShadows position={[0, -1.4, 0]} opacity={0.75} scale={10} blur={2.5} far={4} />
      <Environment preset="city" />
    </Canvas>
    </View>
  )
}

function Watch(props: {rotation: Euler | undefined, position: Vector3, scale: number }) {

  const { nodes, materials } = useGLTF('/glb/watch-v1.glb')  as GLTFA;
  // const { nodes, materials } = useGLTF('/glb/greenhouse2.glb')  as GLTFA;
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Object005_glass_0.geometry} material={materials.glass}>
      {/* <mesh geometry={nodes.greenhouse003.geometry} material={materials.Material}> */}
        <Html scale={100} rotation={[Math.PI / 2, 0, 0]} position={[180, -350, 50]} transform occlude>
        {/* <Html scale={10} rotation={[Math.PI / 2, 0, 0]} position={[180, -350, 50]} transform occlude> */}
          <div className="annotation" style={{ marginLeft: '4.5em', marginTop: '1.4em', backgroundColor: '#99999933', padding: '0px 5px 3px 5px', lineHeight: '1em'}}>
            £5,500<span style={{ fontSize: '1.5em' }}></span>
          </div>
        </Html>
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.Object006_watch_0.geometry} material={materials.watch} />
      {/* <mesh castShadow receiveShadow geometry={nodes.greenhouse003.geometry} material={materials.Material} /> */}
    </group>
  )
}