import { Button, Flex, View, Text } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import ImageAnalysa from '../widgets/ImageAnalysa';
// import ImageDbDisplay from '../analyse/ImageDbDisplay';
import ImageDbDisplay2 from '../widgets/ImageDbDisplay2';
import GimageEnvironment from '../examples/GimageEnvironment';
import AddToThree from '../widgets/AddToThree';
import GimageOfficeBlock from '../examples/GimageOfficeBlock';
import GimageEnviro from '../examples/GimageEnviro';
import { useParams } from 'react-router-dom';
import { MeshTree } from '../../tree/MeshTree';
import SceneData from './SceneData';
import { ThreeEdit2 } from '../display/ThreeEdit2';

interface GimageDoc {
  gimageRefs: string[];
  gimages: {
    [ref: string]: {
      ref: string;
      gimageId: string;
    };
  };
}

const gimageDoc: GimageDoc = {
  gimageRefs: [ 'enviro', 'office',],
  gimages: {
    office: {
      ref: 'office',
      gimageId: '2458e9c4-b8f7-41e1-8732-339082cb5bce',
    },
    enviro: {
      ref: 'enviro',
      gimageId: 'fa94344e-1ac3-4583-9513-ce579cfae039',
    },
  },
};

export function SceneMenu() {

    const [gimageRef, setGimageRef] = useState(gimageDoc.gimageRefs[0]);

  return (
    <>
      <Flex gap={'0px'}>
        {gimageDoc.gimageRefs.map((mGimageRef) => (
          <Button key={mGimageRef} onClick={() => setGimageRef(mGimageRef)}   size="small" 
          >{mGimageRef}</Button>
        ))}
      </Flex>
      {/* <SceneData gimageId={gimageDoc.gimages[gimageRef].gimageId}/> */}
      <ThreeEdit2 gimageId={gimageDoc.gimages[gimageRef].gimageId}/>
    </>
  );
}

