import { GboxDoc } from "../../../interfaces/GboxDoc";
import { GsceneDoc } from "../../../interfaces/GsceneDoc";
import { Gtext3DDoc } from "../../../interfaces/Gtext3DDoc";
import { LazyGbox, LazyGscene, LazyGtext3D } from "../../../models";

export function convertGboxToDoc(gboxs: LazyGbox[] | undefined) {

  const gboxDoc = {
    nameIds: [],
    gboxs: {},
  } as GboxDoc;

  if (gboxs) {
    gboxs.forEach((gbox) => {
      gboxDoc.nameIds.push(gbox.gname);
      gboxDoc.gboxs[gbox.gname] = gbox;
    });
  }

  return gboxDoc;
}

export function convertGtext3DToDoc(gtext3ds: LazyGtext3D[] | undefined) {

  const gtext3dDoc = {
    nameIds: [],
    gtext3Ds: {},
  } as Gtext3DDoc;

  if (gtext3ds) {
    gtext3ds.forEach((gtext3d) => {
      gtext3dDoc.nameIds.push(gtext3d.gname);
      gtext3dDoc.gtext3Ds[gtext3d.gname] = gtext3d;
    });
  }

  return gtext3dDoc;
}

export function convertGsceneToDoc(gscenes: LazyGscene[] | undefined) {

  const gsceneDoc = {
    nameIds: [],
    gscenes: {},
  } as GsceneDoc;

  if (gscenes) {
    gscenes.forEach((gscene) => {
      if (gscene.gname) {
        gsceneDoc.nameIds.push(gscene.gname);
        gsceneDoc.gscenes[gscene.gname] = gscene;
      }
    });
  }

  return gsceneDoc;
}

const shortTreeTemplate = {
  root: {
    container: {
      item0: null,
      item1: null,
      item2: null,
      item3: {
        inner0: null,
        inner1: null,
        inner2: null,
        inner3: null
      },
      item4: null,
      item5: null
    }
  }
};

const readTemplate = (template: any, data: any = { items: {} }) => {
  for (const [key, value] of Object.entries(template)) {
    data.items[key] = {
      index: key,
      canMove: true,
      isFolder: value !== null,
      children: value !== null ? Object.keys(value as object) : undefined,
      data: key,
      canRename: true
    };

    if (value !== null) {
      readTemplate(value, data);
    }
  }
  return data;
};

interface MeshTreeProps {
  gboxs: LazyGbox[] | undefined,
  gtext3Ds: LazyGtext3D[] | undefined,
  gscenes: LazyGscene[] | undefined,
}

export function createMeshTree({ gtext3Ds, gboxs, gscenes }: MeshTreeProps) {

  const gtext3DIds = gtext3Ds?.map(gtext3D => gtext3D.id);
  const gboxsIds = gboxs?.map(gbox => gbox.id);

  let treeTemplate = {
    root: {}
  };

  if (gtext3DIds && gboxsIds) {
    treeTemplate.root = { ...meshLines(gtext3DIds), ...meshLines(gboxsIds), }
  };

  // const shortTree = readTemplate(shortTreeTemplate);
  const shortTree = readTemplate(treeTemplate);

  return shortTree;
}

function meshLines(meshIds: string[]) {

  let meshInfo = {} as any;
  meshIds.forEach(meshId => {
    meshInfo[meshId] = null;
  })

  return meshInfo;
}



