// components/Layout.js
import {
  Button,
  Flex,
  Heading,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import Styla from './Styla';
import ThemeToggle from './ThemeToggle';
import MenaData, { MenaList, MenaDoc } from './MenaData';

interface Props {
  menaList: MenaList;
}

export function MenaComp({ menaList }: Props) {
  const MenuData = new MenaData();

  const [menaDoc, setMenaDoc] = useState<MenaDoc>(MenuData.getMenaDoc());
  let location = useLocation();

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <Styla>
      {/* <Flex
        gap={'0px'}
        width={'100vw'}
        wrap={'wrap'}
      > */}
      <div className='flex flex-wrap w-full gap-0 '>
        {menaDoc[menaList].map((menaId, iMena) => {
          return (
            <Button
              size="small"
              key={iMena}
              onClick={() => (
                document.body.style.cursor = 'auto', // * makes sure trainer configure cursor unset
                navigate(menaDoc.menas[menaId].route))}
              borderRadius={'0px'}
              border={'1px solid #333'}
              fontWeight={'normal'}
              letterSpacing={'0.05em'}
              style={{ borderRight: 'none' }}
              backgroundColor={(location.pathname === menaId)? 'var(--amplify-colors-overlay-90)' : 'white'}
              color={(location.pathname === menaId)? 'white' : 'black'}
              padding={{ base: '2px 3px', medium: '5px 8px' }}

            >
              {menaDoc.menas[menaId].desc} 
            </Button>
          );
        })}
      </div>
    </Styla>
  );
}
