import './index.scss';
import '@aws-amplify/ui-react/styles.css';

// import { AmplifyProvider, ThemeProvider } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import awsconfig from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { studioTheme } from './ui-components';
//? import { Analytics } from '@aws-amplify/analytics';


Amplify.configure(awsconfig);

//? =========| Analytics |==========
//? Analytics.autoTrack('session', {
//?   // REQUIRED, turn on/off the auto tracking
//?   enable: true,
//?   // OPTIONAL, the attributes of the event, you can either pass an object or a function
//?   // which allows you to define dynamic attributes
//?   attributes: {
//?     attr: 'attr'
//?   },
//?   // when using function
//?   // attributes: () => {
//?   //    const attr = somewhere();
//?   //    return {
//?   //        myAttr: attr
//?   //    }
//?   // },
//?   // OPTIONAL, the service provider, by default is the Amazon Pinpoint
//?   provider: 'AWSPinpoint'
//? });
//? ================================


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
