export interface AnimateConfig {
    rootMargin?: string,
    animateStyle: AnimateStyle,
}

export type AnimateStyle =
    | 'SlideInFromLeft'
    | 'SlideInFromRight'
    | 'GrowFromSmall'
    | 'SlideInOpacity'
    | 'SlideInOpacityToggled'
    | 'RotateUp';


export class AnimateScroll {

    constructor(
        public htmlElements: NodeListOf<Element>,
        public config?: AnimateConfig,
    ) {

    }

    // * don't seem to be very suitable for storing observables (Note: [2023-07-11] didn't try using useCallback()). 
    // * So left it as a method / service.


    bespokeDefault: AnimateConfig = {
        rootMargin: this.config?.rootMargin || '550px 150px 100px 150px',
        animateStyle: (this.config?.animateStyle || 'SlideInFromLeft') as AnimateStyle,
    }

    public startObservable() {
        let options = {
            root: null,
            rootMargin: this.bespokeDefault.rootMargin,
            threshold: this.buildThresholdList(),
        };

        const callbackFunction = this.animateStyle(this.bespokeDefault.animateStyle);

        const observer = new IntersectionObserver(callbackFunction, options);
        this.htmlElements.forEach((htmlElement) => {
            observer.observe(htmlElement);
        });

        return observer;
    }

    private buildThresholdList() {
        let thresholds = [0]; // * need a 0 so a reverse transition returns to start
        let numSteps = 100;

        for (let i = 1.0; i <= numSteps; i++) {
            let ratio = i / numSteps;
            thresholds.push(ratio);
        }

        return thresholds;
    }

    public animateStyle(animateStyle: AnimateStyle) {

        switch (animateStyle) {
            case 'RotateUp':
                return callbackAnimateRotateUp;
            case 'GrowFromSmall':
                return callbackAnimateGrowFromSmall;
            case 'SlideInFromLeft':
                return callbackAnimateSlideInFromLeft;
            case 'SlideInFromRight':
                return callbackAnimateSlideInFromRight;
            case 'SlideInOpacity':
                return callbackAnimateSlideInOpacity;
            case 'SlideInOpacityToggled':
                return callbackAnimateSlideInOpacityToggled;
            default:
                return callbackAnimateRotateUp;
        }
    }

}

function callbackAnimateRotateUp(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        entry.target.style.color = `rgba(200, 40, 40, ${entry.intersectionRatio})`;
        // console.log('entry.intersectionRatio:', { ration: entry.intersectionRatio, index });

        if (entry.intersectionRatio < 0.01) {
            entry.target.style.transform = `scale(0.1)`; // * required to stop flash of full size image            
        } else {
            entry.target.style.transform = `scale(${entry.intersectionRatio}) rotate(${entry.intersectionRatio / 4 - 0.25}turn)`;
        }
        entry.target.style.opacity = `${entry.intersectionRatio}`;
    });
}

function callbackAnimateGrowFromSmall(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        // entry.target.style.color = `rgba(200, 40, 40, ${entry.intersectionRatio})`;
        // console.log('entry.intersectionRatio:', { ration: entry.intersectionRatio, index });

        if (entry.intersectionRatio < 0.01) {
            entry.target.style.transform = `scale(0.1)`; // * required to stop flash of full size image            
        } else {
            entry.target.style.transform = `scale(${entry.intersectionRatio})`;
        }
        entry.target.style.opacity = `${entry.intersectionRatio}`;
    });
}

function callbackAnimateSlideInFromLeft(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        entry.target.style.transform = `translateX(${-50 + (50 * entry.intersectionRatio)}vw)`;
        entry.target.style.opacity = `${entry.intersectionRatio}`;
    });
}

function callbackAnimateSlideInFromRight(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        entry.target.style.transform = `translateX(${50 + (-50 * entry.intersectionRatio)}vw)`;
        entry.target.style.opacity = `${entry.intersectionRatio}`;
    });
}

function callbackAnimateSlideInOpacity(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        // entry.target.style.transform = `rotate(${50 + (-50 * entry.intersectionRatio)}vw)`;
       console.log(entry.boundingClientRect);
        entry.target.style.opacity = `${entry.intersectionRatio}`;
    });
}

function callbackAnimateSlideInOpacityToggled(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
) {
    entries.forEach((entry: any, index) => {
        // entry.target.style.transform = `rotate(${50 + (-50 * entry.intersectionRatio)}vw)`;
       console.log('callbackAnimateSlideInOpacityToggled',entry.boundingClientRect);
        entry.target.style.opacity = `${ 1 - entry.intersectionRatio}`;
    });
}