import { Heading, View } from '@aws-amplify/ui-react';
import { DataStore, SortDirection } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { LazyScene, Scene } from '../../models';

const NoteDetailPageF = () => {
  const [scenes, setScenes] = useState<LazyScene[]>();

  useEffect(() => {
    const subscription = DataStore.observeQuery(
      Scene,
      (p) =>
        p.and((p) => {
          return [
            // p.title.beginsWith('title'),
            // p.rating.gt(10)
          ];
        }),
      {
        sort: (s) => s.name(SortDirection.DESCENDING),
      }
    ).subscribe(async (data) => {
      console.log('%c NoteDetailPageF | useEffect(() | DataStore.observeQuery | Scene | data', 'background-color: #447744; color: white', data)
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      <Heading level={3}>
        Advance styling, interactiving and Data analysis
      </Heading>
      <View></View>
      <ul className="list-group">
        {scenes?.map((scene) => (
          <li key={scene.id} className="list-group-item">
            {scene.name}
          </li>
        ))}
      </ul>
    </>
  );
};

export default NoteDetailPageF;
