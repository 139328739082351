
export interface Coloras {
  [id: string]: string
}

export class VariablesService {

  constructor() { }

  colors(): Coloras {

    return {
      colorBackground: '#282828',
      colorBlue: '#4081FF',
      colorGreen: '#73AD21',
      colorAmber: '#ad9121',
      colorGrey: '#adadad',
      colorAlert: '#ff3d3d',
      colorRed: '#c42d30',
      // colorOrange: '#fc8758',
      // colorOrange: '#c94135',
      colorOrange: '#ff800a', // * orange used in charts
      // colorHoverGrey: rgb(54, 54, 54);

      colorButton: '#565656',
    }
  }

}
