// import { GenerateMarketFigures } from '../../interfaces/generate-chart';
// import { Injectable, OnInit } from '@angular/core';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import { GenerateMarketFigures } from '../interfaces/generate-chart';

// import { TimeStats } from '../../interfaces/time-stats';

export class StockPriceGenerateService {
  prices: number[] = [];
  // count: number;
  constructor() { }

  // ngOnInit() {
  //   this.prices = [];
  // }


  generateMarketFiguresFromObject(
    figReq: GenerateMarketFigures
  ) {
    return this.generateMarketFigures(
      figReq.volatility,
      figReq.oldPrice,
      figReq.count,
      figReq.originalPrice,
      figReq.intervalTime,
    );
  }

  generateMarketFigures(
    volatility: number = 0.18,
    oldPrice: number,
    count: number,
    originalPrice: number,
    intervalTime: number = 3600
  ) {
    const prices = [] as any[];
    // Create the High values.
    const marketPrices = this.createStockPrice(
      volatility,
      oldPrice,
      count,
      originalPrice,
      prices
    );
    // Create stock ticker values e.g. High, Low etc..
    return this.convertPricesIntoMarketFigures(
      marketPrices,
      intervalTime,
      volatility
    );
  }

  getStockPrices(volatility = 0.18, oldPrice: number, count: number, originalPrice: number) {
    // todo: think this needs deleting: this.getCountFromStartTimeAndInterval();

    const prices = [] as any[];
    return this.createStockPrice(
      volatility,
      oldPrice,
      count,
      originalPrice,
      prices
    );
  }

  /**
   * Create an array of evolving prices. Prices only i.e. NOT high, low market figures.  This method is recursive.
   * It gradually migrates prices up and down in a realistic manner.
   *
   * @param {number} [volatility=0.18] How much fluctuation the stock should have over time.
   * @param {number} oldPrice The starting price.
   * @param {number} count The number of prices to be generated.
   * @param {number} originalPrice Gives prices boundaries. i.e. stops zero price or 1000% rises.
   * @param {number} prices The prices so far, passed through to itself so recursion can be done.
   * @returns
   * @memberof StockPriceGenerateService
   */
  createStockPrice(volatility = 0.18, oldPrice: any, count: any, originalPrice: any, prices: any): any {
    const rnd = Math.random() - 0.5; // generate number, 0 <= x < 1.0
    const changePercent = 2 * volatility * rnd;
    // if (changePercent > volatility) {
    //   changePercent -= (2 * volatility);
    // }
    const changeAmount = oldPrice * changePercent;
    let newPrice = oldPrice + changeAmount;

    if (newPrice < originalPrice * 0.85) {
      // Stops chart migrating towards a flat line.
      newPrice = newPrice * 1.1;
    } else if (newPrice > originalPrice * 1.2) {
      // Stops chart going too positive and making most values look like a flat line.
      newPrice = newPrice / 1.05;
    }
    prices.push(newPrice.toFixed(2));
    count--;

    if (count > 0) {
      // console.log('newPrice:', newPrice);
      return this.createStockPrice(
        volatility,
        newPrice,
        count,
        originalPrice,
        prices
      );
    } else {
      return prices;
    }
  }

  /**
   * Date format required.
   *
   * Converts a time interval into a what will be the most suitable format to display dates
   * when they are separated by this time margin. e.g. 84600 = 1 day, so display date with no time.
   *
   * @param {number} intervalTime Interval time in seconds.
   * @param {number} position The position within the array i.e. [0]
   * @param {number} pLength The number of values in the Array. i.e. array.length
   * @returns
   * @memberof StockPriceGenerateService
   */
  dateFormatRequired(intervalTime: number, position: number, pLength: number) {
    switch (intervalTime) {
      case 86400: {
        return dayjs()
          .subtract(pLength - position, 'd')
          .format('YYYY-MM-DD');
      }
      case 3600: {
        return dayjs()
          .subtract(pLength - position, 'h')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      case 15: {
        return dayjs()
          .subtract((pLength - position) * 15, 's')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      // todo:  refactor so that this intelligently sorts out format e.g. 15s and 5s should use some code
      case 5: {
        return dayjs()
          .subtract((pLength - position) * 5, 's')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      case 60: {
        return dayjs()
          .subtract((pLength - position) * 60, 's')
          .format('YYYY-MM-DD HH:mm:ss');
      }
      default: {
        return dayjs()
          .subtract(pLength - position, 'd')
          .format('YYYY-MM-DD HH:mm:ss');
      }
    }
  }

  convertPricesIntoMarketFigures(
    prices: any,
    intervalTime = 3600,
    volatility = 0.18
  ) {
    const pLength = prices.length;
    const zip = _.zip(Object.keys(prices), Object.values(prices));
    const stocks = _.chain(zip)
      .map<string[]>((preStck, i) => [
        this.creatTimeStamp(intervalTime, zip.length, i),
        '', // * Close
        _.nth(preStck, 1) as string, // * High: e.g. 103.36
        (Number(_.nth(preStck, 1) as number * 0.997) - // * Low:  e.g. always take off 0.02% and smoothed with .toFixed()
          (Math.random() / 100 +
            (_.nth(preStck, 1) as number % (_.nth(preStck, 1) as number / 50)) / 10) *
          1
        ).toFixed(2), // * low: e.g. 100.36 i.e. high value less a random amount of 2%
        '', // * Low
        '' // * Volume]))
      ])
      .value();
    return stocks.reverse();
  }

  creatTimeStamp(intervalTime: number, length: number, index: number) {
    const time = Date.now() - (intervalTime * index);
    const oneDay = 86400000;
    const dateFormat = (intervalTime < oneDay) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'

    return dayjs(time)
      .format(dateFormat);
  }

}


